import React, { useEffect, useState } from 'react';
import { getAuth, signOut } from "firebase/auth";
import ConnectToRedux from 'components/global/ConnectToRedux';
import { useSelector } from 'react-redux'

import user from '../../../../assets/icons/user.svg'
import logout from 'assets/icons/logout.svg'

const ButtonLogin = ({ removeUser, redirect }) => {

    const spanishText = ["Iniciar sesión", "Cerrar sesión"];
    const englishText = ["Login", "Logout"];

    const language = useSelector(state => state.main.Language);

    const [texts, setTexts] = useState(spanishText);

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])


    const auth = getAuth();
    const logoutAuth = () => {

        signOut(auth).then(() => {
            removeUser()
        }).catch((error) => {
            alert('Error en el servidor')
        });
    }

    return (
        <>
            {auth.currentUser ?
                <div className="pl-4" onClick={logoutAuth}>
                    <div className="flex items-center">
                        <img src={logout} className={`pr-1 w-5`} alt="Icon Item" />
                        <h3 className="text-lg font-medium flex-1 green-text">{texts[1]}</h3>
                    </div>
                </div>
                :
                <div className="pl-4" onClick={redirect}>
                    <div className="flex items-center">
                        <img src={user} className={`pr-1 w-5`} alt="Icon Item" />
                        <h3 className="text-lg font-medium flex-1 green-text">{texts[0]}</h3>
                    </div>
                </div>
            }
        </>
    )
}
export default ConnectToRedux(ButtonLogin, 'User')