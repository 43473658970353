import React, { useEffect, useState } from "react"
import meniumLogo from "assets/images/logo-menium-white.png"
import { XIcon } from "@heroicons/react/solid"
import { useHistory } from "react-router"
import ConnectToRedux from "components/global/ConnectToRedux"
import Cookies from "js-cookie"
import { numberWithCommas } from "utils/Utils"
import useGetTableById from "hooks/useGetTableById"

const PaymentFailed = ({ }) => {
    const history = useHistory()
    const [branchOffice, setBranchOffice] = useState(JSON.parse(Cookies.get("branch_office")));
    const [paymentAmount, setPaymentAmount] = useState(Cookies.get("payment_made"));
    const [tableCurrent, setTableCurrent] = useState("0.00");
    const { isLoading: loading, data: data, error: error } = useGetTableById(localStorage.getItem('tableId'));

    return (
        <div className="flex w-full h-screen flex-col justify-stretch-mode" style={{ height: window.innerHeight, width: window.innerWidth, backgroundColor: '#24146a', backgroundImage: `url(${branchOffice.backgroundImageNoLogoUrl})`, backgroundPosition: 'center', backgroundSize: 'auto 100%' }}>
            <div className="absolute right-3 top-3">
                <XIcon
                    onClick={() => {
                        history.push("/view_and_pay");
                    }}
                    height={30}
                    width={30}
                    className="text-white"
                />
            </div>

            <div className="flex flex-col mt-10 justify-center items-center">
                <div className="meniumtoplogo"><img src={meniumLogo}></img></div>

                <div className="mt-20 w-20 h-20 bg bg-aditional-menium-cancelled rounded-full">
                    <XIcon />
                </div>

                <h3 className="font-bold text-white mt-10">¡Error!</h3>

                <p className="text-white text-base text-center mt-2">
                    ¡Tu pago en la mesa: {branchOffice?.table?.tableName} no se ha procesado correctamente!
                </p>
                <div className="flex flex-col width-text-container mt-20 p-4">
                    <p className="text-white text-center mt-2 ml-5">Por favor, inténtelo de nuevo.</p>
                </div>
            </div>
            <div className="bg-menium-opacity-80 w-full p-4">
                <div
                    onClick={() => {
                        Cookies.remove("payment_made");
                        history.push("/view_and_pay");
                    }}
                    className="flex justify-center items-center flex-col h-30 text-center bg-transparent border-white border-1 rounded-xl mb-4"
                >
                    <p className="text-white font-bold">Volver a la cuenta</p>
                </div>
            </div>
        </div>
    )
}

export default ConnectToRedux(PaymentFailed, "App")