import React from "react";
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom'

const NavBar = ({ onSave, save, title }) => {
    const history = useHistory()

    return (
        <div className="flex flex-row shadow-md px-4 py-4 items-center justify-between">
            <div className="flex flex-row items-center"
                onClick={() => {
                    history.goBack()
                }}>
                <ChevronLeftIcon height={40} width={40} className="text-gray-500" />
                <div className="text-gray-500">

                </div>
            </div>
            <div className="flex">
                <div className="flex justify-center items-center">
                    <div className="text-xl font-bold" >
                        {title}
                    </div>
                </div>
            </div>
            <div>
                {save &&
                    <button className="color-menium-purple-5 text-lg font-bold"
                        onClick={() => onSave()}
                    >
                        Guardar
                    </button>
                }
            </div>
        </div>
    )
}

NavBar.defaultProps = {
    onSave: () => { },
    save: true,
    title: ''
}

export default NavBar