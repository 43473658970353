import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

import ProtectedRouter from "./ProtectedRouter"
import {
  Menu,
  Home,
  NoPhone,
  // VerifyQR,
  MyInformation,
  MyFavorite,
} from "views"
import { LectorQR } from "views/LectorQR"
import Register from "views/Register"
import Login from "views/Login"
// import VerifyQRTakeAway from "views/VerifyQRTakeAway"
import PaymentMethods from "views/PaymentMethods"
import { User } from "firebase/firebase"
import { useFirebaseApp, useFirestore } from "reactfire"
import { useEffect, useMemo } from "react"
import { onAuthStateChanged } from "@firebase/auth"
import ConnectToRedux from "components/global/ConnectToRedux"
import TechnicalSupport from "views/TechnicalSupport"
import Score from "views/Score"
import ViewCommerce from "views/ViewCommerce"
import MyAcount2 from "views/MyAcount2"
import IsPaid from "views/IsPaid"
import PaymentMade from 'views/PaymentMade'
import LegalPolicies from "views/LegalPolicies"
import Proximamente from "views/Proximamente"
import OnlyPayment from "views/OnlyPayment"
import ViewAnPay from "views/ViewAnPay"
import ViewMenuPDF from "views/ViewMenuPDF"
import VerifyQROnlyPayment from "views/VerifyQROnlyPayment"
import TableClosed from "views/TableClosed"
import PaymentFailed from "views/PaymentFailed"

const Router = ({ addUser, removeUser }) => {
  // const app = useFirebaseApp();
  // const firestore = useFirestore();
  // const userClass = useMemo(() => new User(app, firestore), [app, firestore])

  // useEffect(() => {
  //   onAuthStateChanged(userClass.initAuthApp, async (user) => {
  //     if (user) {
  //       const uid = user.uid

  //       const result = await userClass.checkoutUser(uid)

  //       if (result.user) {
  //         addUser(result.user)
  //       }
  //       else {
  //         removeUser()
  //       }
  //     }
  //     else {
  //       removeUser()
  //     }
  //   })

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addUser, userClass])

  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/"
          component={Home}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/account"
          component={MyAcount2}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/my-information"
          component={MyInformation}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/my-favorite"
          component={MyFavorite}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/view_and_pay"
          component={ViewAnPay}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/view_menu"
          component={ViewMenuPDF}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/menu"
          component={Menu}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/paid"
          component={PaymentMade}
        />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/paidko"
          component={PaymentFailed}
        />
        <ProtectedRouter
          exact
          path="/legal_policies"
          component={LegalPolicies}
        />
        {/* <ProtectedRouter
          exact
          path="/verify-mode/:idCommerce/:idBranchOffice/:idTable/:mode"
          component={VerifyQR}
        /> */}
        <ProtectedRouter
          exact
          path="/verify-mode/:idCommerce/:idBranchOffice/:idTable"
          component={VerifyQROnlyPayment}
        />
        {/* <ProtectedRouter
          exact
          path="/verify-mode/:idCommerce/:idBranchOffice"
          component={VerifyQRTakeAway}
        /> */}
        <ProtectedRouter
          exact
          verifyCrendentials={true}
          path="/only-payment"
          component={OnlyPayment}
        />
        <Route exact path="/register" component={Register} />
        <Route exact path="/qr-lector" component={LectorQR} />
        <Route exact path="/no-phone" component={NoPhone} />
        <Route exact path="/login" component={Login} />

        <Route exact path="/closed" component={TableClosed} />

        <Route exact path="/payment-methods" component={PaymentMethods} />
        <Route exact path="/score" component={Score} />

        <Route exact path="/technical-support" component={TechnicalSupport} />
        <Route exact path="/view/:id/:idMenu" component={ViewCommerce} />
        <ProtectedRouter
          verifyCrendentials={true}
          exact
          path="/Pagado"
          component={IsPaid}
        />
        <Route
          exact
          path="/error"
          component={() => <Redirect to="/account" />}
        />
        <Route
          exact
          path="**"
          component={Proximamente}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default ConnectToRedux(Router, "User")
