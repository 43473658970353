import * as t from "../types"

// SHOPPING CART
export const setShoppingCartDefaults = (shoppingCart) => (dispatch) =>
  dispatch({
    type: t.SET_SHOPPING_CART_DEFAULTS,
    payload: shoppingCart,
  })

export const addToShoppingCartDefaults = (addToShoppingCart) => (dispatch) =>
  dispatch({
    type: t.ADD_TO_SHOPPING_CART_DEFAULTS,
    payload: addToShoppingCart,
  })

export const setShoppingCartCustom = (shoppingCart) => (dispatch) =>
  dispatch({
    type: t.SET_SHOPPING_CART_CUSTOM,
    payload: shoppingCart,
  })

export const addToShoppingCartCustom = (addToShoppingCart) => (dispatch) =>
  dispatch({
    type: t.ADD_TO_SHOPPING_CART_CUSTOM,
    payload: addToShoppingCart,
  })

export const resetShoppingCart = () => (dispatch) =>
  dispatch({
    type: t.RESET_SHOPPING_CART,
  })

// USER
export const addUser = (user) => (dispatch) =>
  dispatch({
    type: t.SET_USER,
    payload: user,
  })

export const removeUser = () => (dispatch) =>
  dispatch({
    type: t.REMOVE_USER,
  })

// APP
export const setPaymentApp = (method) => (dispatch) =>
  dispatch({
    type: t.SET_APP_MODE,
    payload: method,
  })

// Commerce
export const setCommerce = (commerce) => (dispath) =>
  dispath({
    type: t.SET_COMMERCE,
    payload: commerce,
  })

export const setPaymentOrder = (payment) => (dispatch) =>
  dispatch({
    type: t.SET_PAYMENT_ORDER,
    payload: payment,
  })

//BranchOffice
export const setBranchOffice = (branchOffice) => (dispath) =>
  dispath({
    type: t.SET_BRANCH_OFFICE,
    payload: branchOffice,
  })

// Menu
export const setMode = (mode) => (dispath) =>
  dispath({
    type: t.SET_MODE,
    payload: mode,
  })

// Search
export const setSearch = (search) => (dispath) =>
  dispath({
    type: t.SET_SEARCH,
    payload: search,
  })

export const changeStateSearch = () => (dispath) =>
  dispath({
    type: t.CHANGE_STATE_SEARCH,
  })

// Modifier
export const setModifier = (tree) => (dispath) =>
  dispath({
    type: t.SET_MODIFIER,
    payload: tree
  })

export const setRefreshValidateModifier = () => (dispath) =>
  dispath({
    type: t.CHANGE_REFRESH_VALIDATE_MODIFIER
  })

export const setLanguage = (language) => (dispatch) =>
  dispatch({
    type: t.SET_LANGUAGE,
    payload: language
  })