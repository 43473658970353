import ConnectToRedux from 'components/global/ConnectToRedux';
import React, { useCallback, useEffect, useState } from 'react';
import NavBar from 'components/My-Information/NavBar';
import PersonalDataForm from 'components/My-Information/PersonalDataForm';
import IconGoogle from 'assets/images/google.svg'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom'

import { useFirestore } from 'reactfire';
import { doc, setDoc } from '@firebase/firestore';

const MyInformation = ({
    User,
    addUser
}) => {

    const history = useHistory()
    const [userData, setUserData] = useState({
        date_of_birth: '',
        name: '',
        lastName: '',
        email: '',
        uid: '1',
        tel: ''
    })
    let refDoc = doc(useFirestore(), 'Users', userData.uid)

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState({
        name: '',
        date_of_birth: '',
        lastName: '',
        tel: ''
    })

    const saveInformationUser = async () => {
        if (userData.name.trim() !== "" && userData.lastName.trim() !== "" && userData.tel.trim() !== "" && userData.date_of_birth.trim() !== "") {
            setLoading(true)
            await setDoc(refDoc, userData)
            addUser(userData)
            history.goBack()
        } else {
            validateDataUser()
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateDataUser = useCallback(() => {
        setFormError({
            name: userData.name === "" ? 'border-botom-error' : '',
            date_of_birth: userData.date_of_birth === "" ? 'border-botom-error' : '',
            lastName: userData.lastName === "" ? 'border-botom-error' : '',
            tel: userData.tel === "" ? 'border-botom-error' : ''
        })
    }, [userData.date_of_birth, userData.lastName, userData.name, userData.tel])

    const onChangeDataUser = ({ currentTarget: { value, name } }) => {
        setUserData({
            ...userData,
            [name]: value
        })
    }

    useEffect(() => {
        setUserData(data => ({ ...data, ...User }))
    }, [User])

    useEffect(() => {
        validateDataUser()
    }, [userData, validateDataUser])

    return (
        <div className="relative">
            <NavBar
                onSave={saveInformationUser}
                title="Mi informacion"
                save={true}
            ></NavBar>

            <div className="p-5">
                <PersonalDataForm
                    userData={userData}
                    onChange={onChangeDataUser}
                    error={formError}
                ></PersonalDataForm>

                <div className="pt-12">
                    <p className="italic text-gray-600" >
                        Para cambiar tu direccion de correo Electronico ponte en contacto con el
                        servicio al cliente
                    </p>
                </div>
                <div className="pt-8 pl-4">
                    <div>
                        <h2 className="font-extrabold text-2xl" >Cuentas</h2>
                    </div>
                    <div className="flex pt-4">
                        <img src={IconGoogle} alt="Google" className="pr-4 w-14	" />
                        <div>
                            <p className="text-2xl" >Google</p>
                            <p className="text-gray-500	" > Sin vincular</p>
                        </div>
                    </div>
                </div>
                <div className="separator pt-4" ></div>

                <div className="pt-4 px-8 pb-8 flex items-start">
                    <p className="text-gray-900" >
                        Enviarme noticias nuevas sobre Menium y
                        eventos relacionados
                    </p>
                    <input type="checkbox" className="mt-1" />
                </div>
            </div>
            <div className="center-view">
                <Loader
                    type="Puff"
                    color="#673AB7"
                    height={100}
                    width={100}
                    visible={loading}
                />
            </div>
        </div>
    )
}

export default ConnectToRedux(MyInformation, "User")