import React, { useEffect } from "react"
import ConnectToRedux from "components/global/ConnectToRedux"
import ToPay from "Service/ToPay";
import { useFirebaseApp, useFirestore } from "reactfire";
import { Stripe } from "Service/Stripe";
import { Deliverec } from "Service/Deliverec";
import { useHistory } from 'react-router-dom'
import loader from 'assets/icons/cargando.gif'


const IsPaid = ({ location, payment, Commerce, resetShoppingCart, setPaymentOrder, BranchOffice, User }) => {

  const toPayClass = new ToPay(useFirestore(), useFirebaseApp());
  const stripeClass = new Stripe(useFirebaseApp())
  const deliverect = new Deliverec()

  const query = new URLSearchParams(location.search);

  const router = useHistory()

  const handleNotFoundPayload = () => alert("Metodo no encontrado");

  const resetOrder = () => {
    resetShoppingCart();
    localStorage.removeItem('payload')
  }

  const pushDeliverect = async (orderId, numberOrder, payload, mode) => {

    const sendDeliverect = {
      orderId: orderId,
      orderType: payload.orderType,
      numberOrder: numberOrder,
      channelLinkId: BranchOffice.channelLinkId,
      total: payload.total,
      tip: payload.tip,
      note: payload.note,
      customer: {
        name: User.name ? User.name : "Sin Registrar",
        phoneNumber: User.phoneNumber ? User.phoneNumber : "",
        email: User.email ? User.email : "Sin registrar",
      },
      products: payload.ShoppingCart
    }

    if (mode === "TakeAway") {
      delete sendDeliverect.numberTable;
      sendDeliverect.pickupTime = payload.pickupTime;
      sendDeliverect.estimatedPickupTime = payload.estimatedPickupTime;
    }
    else {
      sendDeliverect.numberTable = BranchOffice.table.numberTable;
    }

    return await deliverect.sendToDeliverect(sendDeliverect, Commerce.deliverect)

  };

  const sendCloseAndTakeAwayOrder = async (payload, session_id, mode) => {

    let error = false;

    if (session_id) {
      error = (await stripeClass.getPayment(session_id)).error;
    }

    if (!error) {

      const { id, numberOrder } = await toPayClass.setGlobalOrder({ ...payload, channelLinkId: BranchOffice.channelLinkId });

      await pushDeliverect(id, numberOrder, payload, mode)

      setPaymentOrder(payload)

      resetOrder()

      router.push('/paid')

    }

  }

  const sendPayByProduct = async (payload, session_id) => {

    let error = false;

    if (session_id) {
      error = (await stripeClass.getPayment(session_id)).error;
    }

    if (!error) {

      const products = payload.products;
      const beforeProducts = payload.beforeProducts;
      const reference = payload.reference;

      const copyPayload = { ...payload }

      delete payload.products;
      delete payload.beforeProducts;
      delete payload.reference;
      delete payload.modeOpenOrder;

      const res = await toPayClass.byProduct(payload, products, beforeProducts, reference);

      setPaymentOrder({ ...copyPayload, ...res });

      resetOrder()

      router.push('/paid')

    }

  }

  const sendOtherMethods = async (payload) => {

    try {
      await toPayClass.otherMethod(payload)

      setPaymentOrder(payload);

      resetOrder()

      router.push('/paid')
    } catch (error) {
      alert(error)
    }

  }

  const generateCode = () => {
    let response = ""

    for (let index = 0; index < 4; index++) {
      response += Math.floor(Math.random() * (9 - 0)) + 0
    }

    return response
  }

  useEffect(() => {

    if (!User.isLoad) {
      const session_id = query.get('session_id');

      const payload = JSON.parse(localStorage.getItem('payload'));

      if (payload || payment) {

        let data = payload ? payload : payment;

        data = { ...data, code: generateCode() };

        switch (data.mode) {
          case 'Close Order':
            sendCloseAndTakeAwayOrder({ ...data, orderType: 3 }, session_id, data.mode)
            break;
          case 'TakeAway':
            sendCloseAndTakeAwayOrder({ ...data, orderType: 1 }, session_id, data.mode)
            break;
          default:
            switch (data.modeOpenOrder) {
              case 'PayByProduct':
                sendPayByProduct(data, session_id)
                break;

              default:
                sendOtherMethods(data)
                break;
            }
            break;
        }
      }
      else {
        handleNotFoundPayload();
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User])

  return (
    <div className="w-full bg-gradient-menium h-screen flex flex-col justify-center items-center">
      {/* <div className="">Session: {query.get("session_id") ?? "No existe"}</div> */}

      <img src={loader} height={50} width={50} alt="loader" />
      <div className="text-white font-bold text-3xl">Verificando Pago...</div>
    </div>
  )
}

export default ConnectToRedux(IsPaid, [
  "App",
  "ShoppingCart",
  "BranchOffice",
  "User",
  "Commerce"
])