import {
  setDoc,
  doc,
  getDoc,
  getFirestore,
  addDoc,
  collection,
  updateDoc,
  deleteDoc,
} from "@firebase/firestore"
import { getFunctions, httpsCallable } from "@firebase/functions"

export class Order {
  constructor(app, firestore) {
    this.initStoreApp = firestore
    this.initFuncApp = getFunctions(app)
  }

  saveOrderInOpenOrder = async (payload, user, ...route) => {
    try {
      if (user) {
        payload.uid = user.uid
      }
      payload.uid = null
      const completeRoute = route.join("/")
      const ref = await addDoc(
        collection(getFirestore(), completeRoute),
        payload,
      )

      return ref.id
    } catch (error) {
    }
  }

  saveIntoList = async (list, ...route) => {
    try {
      const completeRoute = route.join("/")
      list.forEach(async (el) => {
        await setDoc(doc(getFirestore(), completeRoute, el.NO_ID_FIELD), el)
      })
    } catch (error) {
    }
  }

  saveOrder = async (payload, user, ...route) => {
    try {
      if (user) {
        payload.uid = user.uid
      }
      payload.uid = null
      const completeRoute = route.join("/")
      const ref = await addDoc(
        collection(getFirestore(), completeRoute),
        payload,
      )

      return ref.id
    } catch (error) {
    }
  }

  editOrder = async (payload, user, orderId, ...route) => {
    try {
      if (user) {
        payload.uid = user.uid
      }
      payload.uid = null
      const completeRoute = route.join("/")
      await setDoc(doc(getFirestore(), completeRoute, orderId), payload)
    } catch (error) {
    }
  }

  addInfoOrderToTable = async (payload, id, ...route) => {
    try {
      const completeRoute = route.join("/")
      const ref = doc(getFirestore(), completeRoute, id)
      await updateDoc(ref, payload)
    } catch (error) {
    }
  }

  deleteList = async (list, ...route) => {
    try {
      list.forEach(async (item) => {
        await deleteDoc(doc(getFirestore(), ...route, item.NO_ID_FIELD))
      })
    } catch (error) {
    }
  }
  updateOrderList = async (list, ...route) => {
    try {
      const completeRoute = route.join("/")

      list.map(async (el) => {
        const docRef = doc(getFirestore(), completeRoute, el.NO_ID_FIELD)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          //update Quantity
          let payload = { ...docSnap.data() }
          payload.Quantity = el.Quantity + payload.Quantity
          await setDoc(
            doc(getFirestore(), completeRoute, el.NO_ID_FIELD),
            payload,
          )
        } else {
          //add document
          await addDoc(doc(getFirestore(), ...route, el.NO_ID_FIELD), el)
        }
      })
    } catch (error) {
    }
  }
  deleteDoc = async (docID, ...route) => {
    try {
      await deleteDoc(doc(getFirestore(), ...route, docID))
    } catch (error) {
    }
  }
  updateDoc = async (docID, payload, ...route) => {
    try {
      await setDoc(doc(getFirestore(), ...route, docID), payload)
    } catch (error) {
    }
  }

  sendReceipt = async (email, receiptData) => {
    try {
      const payload = {
        email: email,
        vars: {},
      }
      const sendEmail = httpsCallable(this.initFuncApp, "sendReceipt")
      const result = await sendEmail(payload, receiptData)
      return result
    } catch (error) {
    }
  }
}
