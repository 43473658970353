import React, { useState } from 'react'
import LogoMenium from "assets/images/LOGO-BLANCO.png"
import { QRScan } from 'components/global/QRScan'
import { useHistory } from 'react-router'
import { container } from 'Global'

export const LectorQR = () => {

    const router = useHistory()

    const [state, setState] = useState(true)

    const handleDetectedQR = e => {
        if (e) {
            setState(false)
            const rute = e.split('/')

            rute.splice(0, 3)
            setTimeout(() => router.push(rute.join('/')), 3000)
        }
    }

    return (
        <div className="bg-gradient-menium flex flex-col justify-center items-center text-white"
            style={container}
        >
            <div className="items-center flex flex-col">
                <img className="h-36" src={LogoMenium} alt="Logo" />
            </div>
            <div className="mb-3">
                <div className="border-solid border-4 qr">
                    {
                        state && (
                            <QRScan onFind={handleDetectedQR} />
                        )
                    }
                </div>
            </div>
            <h1 className="text-xl">Escanea tu codigo QR</h1>
        </div>
    )
}
