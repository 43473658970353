import ProductListItem from 'components/OnlyPayment/itemProductList'
import PaymentButtons from 'components/global/PaymentButtons'
import React, { useCallback, useState, useEffect } from 'react'
import { numberWithCommas } from 'utils/Utils'
import { useHistory } from "react-router"
import Tips from "../../../../views/MyAcount2/Components/Tips"
import securePaymentGray from 'assets/images/secure-payment-footer-gray.png'
import { useSelector } from 'react-redux'

const zeroTip = {
    label: "NONE",
    value: 0.0
}

const PrePayByProduct = ({ payload }) => {
    const history = useHistory()
    const spanishText = ["Pagar por producto", "Selecciona los productos que desees pagar:", "Falta pagar:", "A pagar:", "Total cuenta:", "¿Qué te ha parecido el servicio?"];
    const englishText = ["Pay by product", "Select the products you'd like to pay", "Remaining:", "Payable:", "Bill total:", "How was the service?"];

    const language = useSelector(state => state.main.Language);
    const merchantDetails = useSelector(state => state.main.BranchOffice);

    const [texts, setTexts] = useState(spanishText);

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const [itemsSelected, setItemsSelected] = useState(payload.items);

    const handleStateChange = useCallback((value, item, index) => {
        var copyObject = { ...itemsSelected };

        copyObject = Object.values(copyObject);

        copyObject.forEach((item) => {
            if (!item.quantity) {
                item.quantity = 0;
            }
        });

        if (value >= 0) {
            copyObject[index].quantity = value;
        }
        else {
            copyObject[index].quantity = 0;
        }

        setItemsSelected(copyObject);
    }, [itemsSelected])

    const [valueTip, setValueTip] = useState(zeroTip)
    const [isStaticTip,] = useState(false)
    const handleChangeTip = (el) => {
        if (!isStaticTip) {
            setValueTip(el)
        }
    }

    const handleClickPayment = (res) => {
        //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
        //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
        //Verifica si fue exitoso o no viene en {res}
        if (res.status) {
            history.push("/Pagado")
        } else {
            alert("El pago no fue exitoso")
        }
    }

    const [items, totalPay] = Object.values(structuredClone(itemsSelected)).reduce((previus, current) => {
        const payment = previus[1] + (current.quantity * current.unitPrice);
        // delete current.quantity;
        return [
            itemsSelected,
            payment
        ]
    }, [[], 0])

    const newPayload = {
        ...payload,
        products: items,
        modeOpenOrder: "PayByProduct",
        amount: totalPay,
    }


    return (
        <div className="flex flex-col space-y-1 pb-2 relative">
            <h4 className="text-xl font-bold color-title pb-2">{texts[0]}</h4>
            <div className="h-70p overflow-y-auto px-1 borders-for-product-list shadow-bottom-menu relative z-auto">
                {
                    itemsSelected.map((item, index) => (
                        <ProductListItem
                            item={item}
                            checked={itemsSelected[index]}
                            index={index}
                            onChange={handleStateChange}
                            key={`element_Defaults_ShoppingCart_${index}`}
                        />
                    ))
                }
            </div>
            <div className="flex flex-col space-y-2">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col justify-center text-center text-isNotEnable-primary-color">
                        <span className="font-title-standard">{texts[2]}</span>
                        <div className="font-bold font-title-bold text-black productPaymentSpacerBorder">
                            {numberWithCommas(payload.remainingPrice)}€
                        </div>
                    </div>
                    <div className="flex flex-col justify-center text-isNotEnable-primary-color p-3 text-center">
                        <span className="font-title-standard">{texts[3]}</span>
                        <div className="font-bold font-title-bold color-title productPaymentAmountBorder">
                            {numberWithCommas(totalPay ? totalPay : 0.00)}€
                        </div>
                    </div>
                    <div className="flex flex-col justify-center text-center text-isNotEnable-primary-color ">
                        <span className="font-title-standard">{texts[4]}</span>
                        <div className="font-bold font-title-bold text-black productPaymentSpacerBorder">
                            {numberWithCommas(payload.totalPrice)}€
                        </div>
                    </div>
                </div>
            </div>
            {!merchantDetails?.isTipDisabled ?
                <h4 className="text-lg color-title font-bold">
                    {texts[5]}
                    <Tips
                        value={valueTip.label}
                        paymentAmount={totalPay ? totalPay : 0.00}
                        onGetValue={handleChangeTip}
                    />
                </h4>
                : null
            }

            <PaymentButtons
                payload={newPayload}
                amount={totalPay ? totalPay : 0.00}
                active={totalPay > 0}
                paymentType={3}
                tipAmountCalculated={valueTip.amount}
            />
            <img className="footer-payment-image-center" src={securePaymentGray}></img>
        </div>
    )
}

export default PrePayByProduct
