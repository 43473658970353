import ConnectToRedux from "components/global/ConnectToRedux"
import React, { useEffect, useState } from "react"
import TestItem from "./TestItem"

const CategorySection = ({
  Search,
  setSearch,
  categorie,
  ShoppingCart,
  addToShoppingCartCustom,
  addToShoppingCartDefaults,
  setShoppingCartDefaults,
}) => {
  const rename = categorie.name.split(" ").join("")

  const [copyData, setCopyData] = useState(categorie.products)

  const handleChangeStateItem = (data, index, isCustom = false) => {
    if (isCustom) {
      addToShoppingCartCustom(data)
    } else {
      if (index !== null || index < 0) {
        let arrShoppingCartDeaults = [...ShoppingCart.defaults]

        if (data.quantity > 0) {
          arrShoppingCartDeaults[index] = data
        } else {
          arrShoppingCartDeaults.splice(index, 1)
        }
        setShoppingCartDefaults(arrShoppingCartDeaults)
      } else {
        addToShoppingCartDefaults(data)
      }
    }
  }

  useEffect(() => {
    const normalizeStr = (str) =>
      str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    if (Search.isOpenSearch) {
      const search = normalizeStr(Search.search.toLowerCase())

      const filter = categorie.products.filter((product) => {
        const productName = normalizeStr(product.name.toLowerCase())

        return productName.indexOf(search) >= 0
      })

      setCopyData(filter)
    } else {
      setSearch("")
      setCopyData(categorie.products)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Search.isOpenSearch, Search.search])

  return (
    <div className="sectionCategory">
      {!Search.isOpenSearch && (
        <div
          className="boxSearch flex justify-center text-lg font-title-bold font-bold text-center w-full border-b-2 border-t-2 p-1 bg-title-section"
          id={rename}
          data-section={categorie.name}
        >
          {categorie.name}
        </div>
      )}

      {copyData.map((item, i) => (
        <TestItem
          product={item}
          onChangeState={handleChangeStateItem}
          key={`Category_Item_${categorie.name}_${i}`}
        />
      ))}
    </div>
  )
}

export default ConnectToRedux(CategorySection, ["Search", "ShoppingCart"])
