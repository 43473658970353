import MeniumLogo from 'assets/images/logo-menium-white-full_smaller.png'
import { container } from "Global"
import englishicon from 'assets/icons/englishicon.png';
import spanishicon from 'assets/icons/spanishicon.png';
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from 'storage/Actions/main'
import React, { useEffect, useState } from 'react'

const TableClosed = () => {
    const language = useSelector(state => state.main.Language);
    const spanishText = ["¡Tu mesa se ha cerrado!", "Si vas a seguir consumiendo y quieres pagar la cuenta, vuelve a escanear el código QR para que podamos habilitarte la mesa"];
    const englishText = ["Your table has been closed!", "If you're going to keep ordering and want to pay the bill, simply scan the QR code again so we can reactivate your table."];
    const [texts, setTexts] = useState(spanishText);
    const languageTranslation = ["es", "en"];
    const dispatch = useDispatch();

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const handleLanguageChange = (event) => {
        const value = event.target.value;
        dispatch(setLanguage(value));
        setLanguage(event.target.value);
        if (value === 0) {
            setTexts(spanishText);
        } else if (value === 1) {
            setTexts(englishText);
        }
    }

    return (
        <div
            className="flex justify-center items-center flex-col bg-gradient-menium"
            style={container}
        >
            <img
                src={MeniumLogo}
                alt="Menium Logo"
                className="full-width-logo"
            />
            <h1 className='text-white text-2x1 mt-10'>{texts[0]}</h1>
            <h1 className='text-white text-2x1 mt-5 closed-message'>{texts[1]}</h1>
        </div>
    )
}

export default TableClosed