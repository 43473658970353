import { container } from "Global"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import backIcon from "assets/icons/back.svg"
import addCardIcon from "assets/icons/addcredit.svg"
import ConnectToRedux from "components/global/ConnectToRedux"
import { Stripe } from "Service/Stripe"
import { useFirebaseApp } from "reactfire"
import Credit from "assets/icons/credit-card.svg"
import Selected from "assets/icons/selected.svg"
import Trash from "assets/icons/trash.svg"
import { useHistory } from "react-router-dom"
import { CircularProgress } from "@mui/material"

const PaymentMethods = ({ User }) => {
  const app = useFirebaseApp();
  const stripeClass = useMemo(() => new Stripe(app), [app])
  const [methodPayments, setMethodPayments] = useState([])
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const handleClickAddCard = async () => {
    await stripeClass.AddMethodPayment(User.stripeCustomerId)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = useCallback(async () => {
    const result = await stripeClass.GetMethodPayments(User.stripeCustomerId)

    if (result.data) {
      setMethodPayments(result.data)
      setLoading(false)
    }
  }, [User.stripeCustomerId, stripeClass])

  const DeletePaymentMethod = async (idMethod) => {
    const result = await stripeClass.RemoveMethodPayment(idMethod)
    if (result.data) {
      getData()
    }
  }

  useEffect(() => {
    if (User.stripeCustomerId) {
      getData()
    }
  }, [User, getData])

  return (
    <div style={container} className="overflow-y-hidden flex flex-col">
      <nav className="flex flex-row h-20 items-center justify-center border-b-2">
        <div className="ml-10 mr-12 w-full">
          <img
            src={backIcon}
            className="w-4"
            alt="Icon back"
            onClick={() => history.push("/")}
          />
        </div>
        <div className="flex-1 font-bold text-xl absolute">
          <h2 className="text-2xl">Métodos de pago</h2>
        </div>
      </nav>
      <div className="flex flex-col h-full">
        <div className="flex-1 greyBackground p-7">
          <h3 className="text-isNotEnable-primary-color text-xl mb-2">
            Listado de metodos
          </h3>
          <div className={`${!loading ? "overflow-y-scroll" : ""}`}>
            {loading ? (
              <div className="flex pt-5 flex-row justify-center">
                <CircularProgress />
              </div>
            ) : (
              methodPayments.map((resp, index) => (
                <div
                  className="flex border-b-2 p-2"
                  key={`item-method-card-${index}`}
                >
                  <div className="justify-center flex mr-4">
                    <img src={Credit} width="35px" alt="Credit icon" />
                  </div>
                  <div className="flex-1">
                    <span className="font-light text-lg">
                      Tarjeta terminada en {resp.card.last4}
                    </span>
                  </div>
                  <div className="justify-center flex flex-col items-center">
                    <img
                      src={Selected}
                      className="selected me-1 img-fluid mb-2"
                      alt="Selected"
                    />
                    <img
                      src={Trash}
                      alt="iconTrash"
                      width="25px"
                      height="35px"
                      onClick={() => DeletePaymentMethod(resp.id)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="flex-1 p-7">
          <h2 className="text-isNotEnable-primary-color text-xl">
            Añadir método de pago
          </h2>
          <button
            className="flex items-center mt-2 h-14 w-full p-7 border-b-2"
            onClick={handleClickAddCard}
          >
            <img src={addCardIcon} alt="Add card icon" className="mr-5 w-8" />
            <p className="text-lg">Añadir nueva tarjeta</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConnectToRedux(PaymentMethods, "User")
