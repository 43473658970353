import { setCommerce } from 'storage/Actions/main'

const mapStateToProps = state => ({
    Commerce: state.main.Commerce
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        setCommerce
    }
}

export default sendInfo
