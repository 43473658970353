import ConnectToRedux from "components/global/ConnectToRedux"
import { useGetDoc } from "hooks/useGetDoc"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Cookies from "js-cookie"
import loadingQR from "assets/images/loadingQR.gif"
import MeniumLogo from 'assets/images/logo-menium-blanco-1.png'
import { container } from "Global"

const VerifyQR = ({
  setMode,
  setBranchOffice,
  setCommerce
}) => {
  const { idCommerce, idBranchOffice, idTable, mode } = useParams()

  const router = useHistory()

  const [loadingCommerce, dataCommerce] = useGetDoc(["Shops", idCommerce])
  const [loadingBranch, dataBranch] = useGetDoc([
    "Shops",
    idCommerce,
    "BranchOffices",
    idBranchOffice,
  ])
  const [loadingTable, dataTable] = useGetDoc([
    "Shops",
    idCommerce,
    "BranchOffices",
    idBranchOffice,
    "Tables",
    idTable,
  ])

  const [isNotFoundQR, setIsNotFoundQR] = useState(false)

  useEffect(() => {
    if (
      loadingCommerce === "success" &&
      loadingBranch === "success" &&
      loadingTable === "success"
    ) {
      if (dataCommerce && dataBranch && dataTable) {

        Cookies.set("commerce", JSON.stringify(dataCommerce), { expires: 1 })
        Cookies.set(
          "branch_office",
          JSON.stringify({ ...dataBranch, table: dataTable }),
          { expires: 1 },
        )
        Cookies.set('mode', mode, { expires: 1 });
        setCommerce(dataCommerce)
        setBranchOffice({
          ...dataBranch,
          table: dataTable,
        })
        setMode(mode)

        if (dataBranch.isOnlyPayment) {
          setTimeout(() => router.push("/view_and_pay"), 2000)
        }
        else {
          setTimeout(() => router.push("/"), 2000)
        }
      } else {
        setIsNotFoundQR(true)
      }
    }
  }, [loadingCommerce, loadingBranch, loadingTable, dataTable, dataBranch, dataCommerce, router, mode, setCommerce, setBranchOffice, setMode])


  return (
    <div
      className="flex justify-center items-center flex-col bg-gradient-menium"
      style={container}
    >
      {
        !isNotFoundQR ? (
          <>
            <img
              src={MeniumLogo}
              alt="Menium Logo"
              className="w-full px-5"
            />
            <p className="mt-7 text-white tracking-widest">PIDE, PAGA Y VETE</p>
            <img src={loadingQR} alt="QR" className="h-32 mt-20" />
            <h1 className="text-white text-2xl">Verificando QR</h1>
          </>
        ) : (
          <h1 className="text-white text-2xl">QR no valido</h1>
        )
      }
    </div>
  )
}

export default ConnectToRedux(VerifyQR, ["Commerce", "BranchOffice", "Mode"])
