import React, { useState, useEffect } from "react"
import { numberWithCommas } from "utils/Utils"
import ConnectToRedux from "components/global/ConnectToRedux"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'

const DetailPaymentOpenOrder = ({ payload, Commerce, BranchOffice, User }) => {

  const spanishText = ["Pagado:", "pago realizado", "pagos realizados", "Falta por pagar:", "Total:", "Registrate", "o", "Inicia sesión", "para recibir promos y descuentos"];
  const englishText = ["Paid:", "payment made", "payments made", "Pending:", "Total:", "Register", "or", "Login", "to receive discounts and promotions"];
  const [texts, setTexts] = useState(spanishText);

  const language = useSelector(state => state.main.Language);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])


  return (
    <>
      <div className="flex mt-4">
        <div className="w-50 justify-start">
          <div className="flex justify-between text-xs font-title-bold">
            <label className="text-left flex-1 font-title-bold ">{texts[0]}&nbsp;</label>
            <label className="flex-0 font-title-bold text-left green-font-color">
              {numberWithCommas(payload.totalPrice - payload.remainingPrice)}€
            </label>
          </div>
          <div className="font-title-bold flex text-base mt-4">
            <label className="text-xs text-isNotEnable-primary-color green-font-color">{payload.numberOfPayments > 0 ? (payload.numberOfPayments) : (" ")}</label>
            <label className="text-xs text-isNotEnable-primary-color ml-1">{payload.numberOfPayments > 0 ? (payload.numberOfPayments == 1 ? texts[1] : texts[2]) : ("")}</label>
          </div>
        </div>
        <div className="flex max-h-28 flex-1 justify-start" />
        <div className="w-52">
          <div className="flex justify-between text-xs font-title-bold">
            <label className="text-right flex-1 font-title-bold">{texts[3]}&nbsp;</label>
            <label className="flex-0 text-right font-title-bold red-font-color">
              {payload.remainingPrice <= 0.01 ? numberWithCommas("0.00") : numberWithCommas(payload.remainingPrice)}€
            </label>
          </div>
          <div className="flex justify-between text-xs font-title-bold mt-4">
            <label className="text-right flex-1 font-title-bold">{texts[4]}&nbsp;</label>
            <label className="flex-0 text-right font-title-bold ">
              {numberWithCommas(payload.totalPrice)}€
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center mt-3">
        <div className="flex max-h-28 space-x-2 flex-1 justify-start" />
        <div className="w-52">
          {/* <div className="text-gray-500 flex justify-between text-xs font-14px">
            <label className="text-right flex-1">Propina:</label>
            <label className="flex-05 text-right">
              {numberWithCommas(0.00)}€
            </label>
          </div> */}
        </div>
      </div>
      {!User.name && (
        <div className="text-xs text-center text-isNotEnable-primary-color mt-2">
          <Link to="/register" className="color-title">
            {texts[5]}
          </Link>{" "}
          {texts[6]}{" "}
          <Link to="/login" className="color-title">
            {texts[7]}
          </Link>{" "}
          {texts[8]}
        </div>
      )}
    </>
  )
}

export default ConnectToRedux(DetailPaymentOpenOrder, [
  "Commerce",
  "BranchOffice",
  "User",
])
