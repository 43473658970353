import React from "react"
import { Link } from "react-router-dom"
import ConnectToRedux from "components/global/ConnectToRedux"
import { numberWithCommas } from "utils/Utils"

const DetailPayment = ({ payload, User }) => {
  return (
    <>
      <div className="flex items-center mt-3">
        <div className="flex-1">
          <div className="text-gray-500 flex justify-between text-xs">
            <label className="flex-1 text-right">Propina:</label>
            <label className="flex-1 text-right">
              {numberWithCommas(payload.tip)}€
            </label>
          </div>
          <div className="font-bold flex justify-between text-base">
            <label className="flex-1 text-right">TOTAL:</label>
            <label className="flex-1 text-right">
              {numberWithCommas(payload.total + payload.tip)}€
            </label>
          </div>
        </div>
      </div>
      {!User.name && (
        <div className="text-xs text-center text-isNotEnable-primary-color mt-2">
          <Link to="/register" className="color-title">
            Regístrate
          </Link>{" "}
          o{" "}
          <Link to="/login" className="color-title">
            Inicia sesión
          </Link>{" "}
          para recibir promos y descuentos
        </div>
      )}
    </>
  )
}

export default ConnectToRedux(DetailPayment, "User")
