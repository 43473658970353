import React from "react"
import Box from "@mui/material/Box"
import ModalView from "@mui/material/Modal"
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg"

const CloseIcon = ({ className, onClick }) => (
  <Cancel className={["h-5 w-5", className].join(" ")} onClick={onClick} />
)

const Modal = ({ open, onClose, children, closeIcon, showCloseIcon }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "90%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    boxShadow: 20,
    outline: "none",
    overflowY: "scroll",
    borderRadius: 2,
    padding: 0,
  }

  return (
    <ModalView open={open} onClose={onClose}>
      <Box
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden"
        }}
        sx={{ ...style, width: 200 }}
      >

        {showCloseIcon && (
          <closeIcon.type
            {...closeIcon.props}
            className={[
              "absolute top-3 right-3",
              closeIcon.props.className,
            ].join(" ")}
            onClick={onClose}
          />
        )}

        <div className="h-full w-full overflow-y-auto">
          {children}
        </div>
      </Box>
    </ModalView>
  )
}

Modal.defaultProps = {
  closeIcon: <CloseIcon />,
  showCloseIcon: true,
}

export default Modal
