import React from 'react'
import Navbar from 'components/global/navbar'
const Menu = () => {
    return (
        <div>
            <Navbar/>
        </div>
    )
}

export default Menu