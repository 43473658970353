import { changeStateSearch, setSearch } from 'storage/Actions/main'

const mapStateToProps = state => ({
    Search: state.main.search
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        setSearch,
        changeStateSearch
    }
}

export default sendInfo