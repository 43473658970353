import { endpoint } from "Global"
import Cookies from "js-cookie"

export const OnlyPaymentService = {
    getOrder: (idTable) => fetch(`${endpoint}/Table/${idTable}`).then(resp => resp.json()),

    getTableFromQr: (tableRequest) => fetch(`${endpoint}/Table/qr`, {
        method: 'POST',
        body: JSON.stringify(tableRequest),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get("anonToken")}` },
    }).then(resp => resp.json()),

    getTableUsingId: (tableId) => fetch(`${endpoint}/Table/${tableId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get("anonToken")}` }
    }).then(resp => resp.json()),

    getAnonToken: (anonTokenRequest) => fetch(`${endpoint}/Auth/anon`, {
        method: 'POST',
        body: JSON.stringify(anonTokenRequest),
        headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.json()),

    getMerchantDetailsById: (merchantId) => fetch(`${endpoint}/Merchant/details/${merchantId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then(resp => resp.json()),

    setPaymentFeedback: (feedbackData) => fetch(`${endpoint}/Payment/feedback`, {
        method: 'POST',
        body: JSON.stringify(feedbackData),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get("anonToken")}` },
    }).then(resp => resp.json()),

    setDemoPaymentConfirmed: (paymentReference) => fetch(`${endpoint}/Payment/demo/${paymentReference}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get("anonToken")}` }
    }).then(resp => resp.json()),
}