import React from 'react'
import { numberWithCommas, nameWithFirstUpper } from "utils/Utils"

const ItemsList = ({ product }) => {
    return (
        <div className="flex border-b-2 pb-2 mt-2">
            <div className="ml-5 flex flex-col items-center justify-between space-y-1">
                <label className="h-5 text-base text-isNotEnable-secundary-color">
                    x{product.totalUnits}
                </label>
            </div>
            <div className="flex-1 text-lg">
                <div className="flex">
                    <div className="flex flex-col ml-2">
                        <h3 className="text-base text-billItems-black">
                            {nameWithFirstUpper(product.itemLineName)}
                        </h3>
                        <p className="text-sm text-isNotEnable-secundary-color box-content">
                            {nameWithFirstUpper(product.itemLineDescription)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mr-5 flex flex-col items-end justify-between space-y-1">
                <div className="flex flex-row justify-between">
                    {product.totalUnits > 1 ? (
                        <label className="text-sm h-5 w-20 text-isNotEnable-secundary-color text-billItems-unitPrice text-billItems-right">
                            {numberWithCommas(parseFloat(product.unitPrice).toFixed(2))}€
                        </label>
                    ) : (<></>)}
                    <label className="h-5 w-20 text-base text-billItems-black text-billItems-right">
                        {numberWithCommas(parseFloat(product.totalPrice).toFixed(2))}€
                    </label>
                </div>

            </div>
        </div>
    )
}

export default ItemsList