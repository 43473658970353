import { Tree } from "Class/Tree"
import ConnectToRedux from "components/global/ConnectToRedux"
import React, { useEffect, useMemo, useState } from "react"
import { numberWithCommas } from "utils/Utils"
import SectionModifier from "./SectionModifier"

const Modifier = ({
  product,
  onChangeState,
  reference,
  Modifier,
  refreshValidateModifier,
  setRefreshValidateModifier,
  onClose,
}) => {
  const [addNoteToKitchen, setAddNoteToKitchen] = useState("")
  const [quantity, setQuantity] = useState(1)
  const [total, setTotal] = useState(product.price)
  const [activeAsk, setActiveAsk] = useState(true)

  const treeClass = useMemo(() => new Tree(), [])

  const handleChangeInput = ({ target: { value } }) =>
    setAddNoteToKitchen(value)

  const incrementQuantity = () => setQuantity(quantity + 1)

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const handleAddCustomerProduct = () => {
    const verify = treeClass.verifyTree(Modifier)

    if (verify.length === 0) {
      const res = treeClass.removeGroupInSubItems(Modifier)

      onChangeState({ ...res, quantity, remark: addNoteToKitchen }, null, true)
      onClose()
    } else {
      setActiveAsk(false)
    }
  }

  const handleChange = () => {
    //const { count, inCart } = treeClass.total(Modifier)
    setTotal(treeClass.total(Modifier))
  }

  useEffect(() => {
    const verify = treeClass.verifyTree(Modifier)

    if (verify.length > 0) {
      setActiveAsk(false)
    } else {
      setActiveAsk(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Modifier])

  useEffect(() => {
    if (refreshValidateModifier) {
      const verify = treeClass.verifyTree(Modifier)

      if (verify.length > 0) {
        setActiveAsk(false)
      } else {
        setActiveAsk(true)
      }

      setRefreshValidateModifier()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshValidateModifier])

  return (
    <div className="w-full overflow-y-scroll h-full">
      <img
        src={product.picture}
        alt={product.name}
        className="w-full h-64 rounded-t-lg mb-2 object-cover"
      />

      <div className="p-5 w-full">
        <h2 className="font-bold mb-2 text-2xl">{product.name}</h2>
        <p className="text-sm mb-2 text-isNotEnable-primary-color">{product.description}</p>
      </div>
      {product?.subItems?.length > 0 &&
        product.subItems?.map((el, i) => (
          <SectionModifier
            {...el}
            onChange={handleChange}
            nodePath={[i]}
            reference={`${reference}_section_modifier_${i}`}
            key={`${reference}_section_modifier_${i}`}
          />
        ))}
      <div className="w-full p-5 pt-0">
        <h3 className="font-bold mb-2 text-lg">Nota a cocina</h3>
        <textarea
          className="w-full focus-textTarea"
          placeholder="Use este espacio para mandarle una petición concreta al chef"
          onChange={handleChangeInput}
          value={addNoteToKitchen}
        />
      </div>
      <div className="flex justify-center items-center space-x-10">
        <label
          onClick={decrementQuantity}
          className={[
            "rounded-full w-7 h-7 flex items-center justify-center text-lg font-bold",
            quantity < 2
              ? "border-2 text-isNotEnable-primary-color"
              : "bg-aditional-menium text-green-700 bg-green-200",
          ].join(" ")}
        >
          -
        </label>
        <label className="text-3xl">{quantity}</label>
        <label
          onClick={incrementQuantity}
          className="bg-aditional-menium  text-green-700 bg-green-200 rounded-full w-7 h-7 flex items-center justify-center text-lg font-bold"
        >
          +
        </label>
      </div>
      <div className="p-5">
        <button
          className={[
            "bg-gradient-menium text-white p-2 mt-2 rounded-full max-w-sm w-full",
            !activeAsk && "text-isNotEnable-primary-color",
          ].join(" ")}
          onClick={handleAddCustomerProduct}
        >
          Pedir por {numberWithCommas((total * quantity).toFixed(2))}€
        </button>
      </div>
    </div>
  )
}

Modifier.defaultProps = {
  reference: "",
}

export default ConnectToRedux(Modifier, "Modifier")
