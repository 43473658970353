import React, { useEffect, useState } from "react"
import Drawer from "components/Drawer/Drawer"
import {
  MenuIcon,
  SearchIcon,
  HeartIcon,
  ChevronLeftIcon,
} from "@heroicons/react/outline"
import Share from "assets/icons/share.png"
import ConnectToRedux from "./ConnectToRedux"
import { MenuDrawer } from "./MenuDrawer/MenuDrawer"
import { useHistory } from "react-router"
import { useGetDoc } from "hooks/useGetDoc"
import { getAuth } from "firebase/auth"
// import IconHearFill from 'assets/icons/heart-fill.svg';
import { useFirestore } from "reactfire"
import { doc, setDoc, deleteDoc } from "@firebase/firestore"
import { InfoCommerce } from "./ModalCommerce/InfoCommerce"
import Xfit from "assets/icons/XFit.svg"
import Modal from "./Modal"

const ChangeStyleIconHeard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    {...props}
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{ stopColor: "rgb(126, 87, 194)", stopOpacity: 1 }}
        />
        <stop
          offset="100%"
          style={{ stopColor: "rgb(36, 20, 106)", stopOpacity: 1 }}
        />
      </linearGradient>
    </defs>
    <path
      fillRule="evenodd"
      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
      clipRule="evenodd"
      fill="url(#grad3)"
    />
  </svg>
)

const Navbar = ({
  Commerce,
  isMycount,
  User,
  Mode,
  BranchOffice,
  changeStateSearch,
  showBackPage
}) => {
  const [draweState, setDrawerState] = useState(false)
  const [modalState, setModalState] = useState({
    favorite: false,
    commerce: false
  });
  const history = useHistory()
  const [userData, setUserData] = useState({
    date_of_birth: "",
    name: "",
    lastName: "",
    email: "",
    uid: "1",
    tel: "",
  })
  const [commerceData, setCommerceData] = useState({
    NO_ID_FIELD: "1",
    Name: "Montaditos",
    direction: "Gran Via 35",
    numberOrders: "0",
    Description: "Restaurante",
  })

  const auth = getAuth()

  let refDoc = doc(
    useFirestore(),
    "Users",
    userData.uid,
    "favorites",
    commerceData.NO_ID_FIELD,
  )
  const [, dataFavorite] = useGetDoc([
    "Users",
    userData.uid,
    "favorites",
    commerceData.NO_ID_FIELD,
  ])
  const [Schedule, setSchedule] = useState({ Hour: "20:00 - 23:00" })
  const saveShopFavorite = async () => {
    await setDoc(refDoc, {
      ...commerceData,
    })
  }

  const showHeart = () => {
    if (auth.currentUser) {
      return dataFavorite ? (
        <ChangeStyleIconHeard
          height={20}
          width={20}
          alt="Heart"
          onClick={() => deleteShopFavorite()}
        />
      ) : (
        <HeartIcon
          height={20}
          width={20}
          className="text-black z-10"
          onClick={() => saveShopFavorite()}
        />
      )
    } else {
      return (
        <HeartIcon
          height={20}
          width={20}
          className="text-black z-10"
          onClick={() => {
            setModalState({ favorite: true, commerce: modalState.commerce })
          }}
        />
      )
    }
  }

  const deleteShopFavorite = async () => {
    await deleteDoc(refDoc)
  }

  const shareCommerce = () => {
    let rute = `verify-mode/${Commerce.NO_ID_FIELD}/${BranchOffice.NO_ID_FIELD}`

    if (Mode !== "isTakeAway") {
      rute += `/${BranchOffice.table.NO_ID_FIELD}/${Mode}`
    }

    navigator.share({
      title: "Menium App",
      text: "Pide, Paga y Vete",
      url: rute,
    })
  }

  const handleCloseDrawer = () => {
    setDrawerState(false)
  }

  const handleBackPage = () => {
    if (BranchOffice.isOnlyPayment) {
      history.push("/view_and_pay")
    }
    else {
      history.push("/")
    }
  }

  useEffect(() => {
    setUserData((data) => ({ ...data, ...User }))
  }, [User])

  useEffect(() => {
    const schedules = Commerce.schedules;

    if (schedules) {
      const currentDay = new Date();

      const splitHour = hours => {

        const hourAndMinutes = hours.split(" - ");
        const split1 = hourAndMinutes[0].split(":");

        const hour1 = parseInt(split1[0]);

        const minutes1 = parseInt(split1[1]);

        const split2 = hourAndMinutes[1].split(":");

        const hour2 = parseInt(split2[0]);

        const minutes2 = parseInt(split2[1]);

        return {
          init: [hour1, minutes1],
          end: [hour2, minutes2]
        }
      }

      let testSchedule = []

      const verifyScheduleCommerce = schedules.reduce((previus, current) => {
        const day = current.dayInt;

        if (currentDay.getDay() === day) {
          const { init, end } = splitHour(current.Hour);

          testSchedule.push(current.Hour)

          const initDay = new Date(),
            endDay = new Date();

          initDay.setHours(...init, 0)
          endDay.setHours(...end, 0)

          if (initDay <= currentDay && currentDay <= endDay) {
            return [...previus, current];
          }

        }

        return previus
      }, [])

      if (verifyScheduleCommerce.length > 0) {
        setSchedule(verifyScheduleCommerce[0])
      }
      else {
        setSchedule({ Hour: testSchedule.join(", ") })
      }
    }
    setCommerceData(Commerce)
  }, [Commerce])

  return (
    <div
      className={[
        "pt-4 flex flex-col justify-center  items-center",
        isMycount ? "pb-0" : "px-10",
      ].join(" ")}
    >
      {isMycount ? (
        <>
          <div
            className={[
              "fixed pt-12 pb-2 justify-between bg-white items-start text-center w-full",
              "grid grid-cols-3 items-center justify-items-start"
            ].join(" ")}
          >
            <div
              className={
                [
                  "flex flex-row items-center",
                  "w-full"
                ].join(" ")
              }
              onClick={handleBackPage}
            >
              <ChevronLeftIcon
                height={30}
                width={30}
                className="text-gray-500"
              />
              {
                showBackPage ? "Menu" : "Ver la carta"
              }
            </div>
            <div className={["flex-1 text-center flex flex-col items-center w-full"].join(" ")}>
              <div className="flex flex-col items-center justify-center">
                <div className="flex  font-bold w-full ">Tu cuenta</div>
                {
                  Mode !== "isTakeAway" && (
                    <div className="flex space-x-1 items-center">
                      <div className="bg-aditional-menium-reverse rounded-full w-3 h-3" />
                      <p>Mesa {BranchOffice.table.numberTable}</p>
                    </div>
                  )
                }
              </div>
            </div>

            <div className={
              [
                "flex flex-row space-x-10",
                "w-full justify-end pr-7"
              ].join(" ")
            }>
              <MenuIcon
                height={20}
                width={20}
                className="text-black"
                onClick={() => setDrawerState(true)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between w-full items-center">
            <div className="flex flex-row items-center space-x-4">
              <SearchIcon
                height={20}
                width={20}
                className="text-black"
                onClick={changeStateSearch}
              />
              <img
                src={Share}
                height={30}
                width={30}
                className="text-black font-bold"
                onClick={shareCommerce}
                alt="share"
              />
            </div>
            <div
              className="font-bold text-md flex-col justify-center items-center text-center"
              onClick={() => {
                setModalState({ commerce: true, favorite: modalState.favorite })
              }}
            >
              <div>{Commerce.Name}</div>
              <div
                className="text-xs text-center"
              >
                {Schedule.Hour}
              </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
              {showHeart()}
              <MenuIcon
                height={20}
                width={20}
                className="text-black"
                onClick={() => setDrawerState(true)}
              />
            </div>
          </div>
        </>
      )}

      <Drawer isOpen={draweState} setIsOpen={handleCloseDrawer}>
        <MenuDrawer></MenuDrawer>
      </Drawer>

      <Modal
        open={modalState.commerce}
        onClose={() => setModalState({ commerce: false, favorite: modalState.favorite })}
        closeIcon={
          <div className="glassButton">
            <img src={Xfit} alt="close icon" />
          </div>
        }
      >
        <InfoCommerce commerceData={commerceData} />
      </Modal>
      <Modal
        open={modalState.favorite}
        onClose={() => setModalState({ favorite: false, commerce: modalState.commerce })}
      >
        <>
          <div className="flex flex-col py-8 px-4 space-y-4">
            <h4>
              Debes iniciar sesión para poder guardar este restaurante
              como favorito
            </h4>
            <button
              className="bg-gradient-menium text-white p-2 mt-2 rounded-full max-w-sm w-full"
              onClick={() => {
                history.push("/login")
              }}
            >
              Iniciar sesión/Registrarme
            </button>
          </div>
        </>
      </Modal>
    </div>
  )
}

Navbar.defaultProps = {
  isMycount: false,
}

export default ConnectToRedux(Navbar, [
  "Commerce",
  "User",
  "Mode",
  "BranchOffice",
  "Search",
])
