const Banner = () => {
  return (
    <html>
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script src="https://cdn.tailwindcss.com"></script>
      </head>
      <body>
        <div className="bg-purple-500 text-white underline">
          <p>Bienvenido a Menium app!</p>
          <p>Puedes recibir muchas sorpresas por parte de nosotros</p>
        </div>
      </body>
    </html>
  )
}
export default Banner
