import React, { useState } from "react"
import LogoMenium from "assets/images/LOGO-BLANCO.png"
import facebookIcon from "assets/images/facebook.svg"
import googleIcon from "assets/images/google.svg"
import { Link, useHistory } from "react-router-dom"
import { User } from "firebase/firebase"
import { useFirebaseApp, useFirestore } from "reactfire"
import ConnectToRedux from "components/global/ConnectToRedux"

const Login = ({ addUser }) => {
  const userClass = new User(useFirebaseApp(), useFirestore())
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  })

  const [responseForgot, setResponseForgot] = useState("")

  const [forgotStatus, setForgotStatus] = useState("")

  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()

    userClass
      .loginWithEmailAndPassword(userInfo.email, userInfo.password)
      .then((resp) => {

        if (resp) {

          if (resp.verify) {
            addUser(resp.user)
            history.push("/")
          }
          else {
            alert("Verifique sus credenciales")
          }
        }

      })
      .catch((err) => alert("Verifique credenciales"))
  }

  const handleChangeInputs = ({ target: { value, name } }) =>
    setUserInfo({ ...userInfo, [name]: value })

  const loginUserWithGoogle = () => {
    userClass
      .loginUserWithGoogle()
      .then((resp) => {
        if (!resp.error) {
          if (resp.isSendEmail) {
            userClass.sendEmail(resp.user.email)
          }
          addUser(resp.user)
          history.push('/')
        }
      })
      .catch((err) => console.error(err))
  }

  const loginUserWithFacebook = () => {
    userClass
      .loginUserWithFacebook()
      .then((resp) => {
        if (!resp.error) {
          if (resp.isSendEmail) {
            userClass.sendEmail(resp.user.email)
          }
          addUser(resp.user)
          history.push('/')
        }
      })
      .catch((err) => console.error(err))
  }

  const sendResetPassword = async (email) => {
    const res = userClass.sendRecovery(email)
    if (res) {
      setResponseForgot(true)
    } else {
      setResponseForgot(false)
    }
  }

  return (
    <div
      //style={container}
      className="bg-gradient-menium w-full h-screen overflow-y-hidden"
    >
      <div className="overflow-y-scroll h-full flex flex-col justify-center items-center p-7">
        <img src={LogoMenium} alt="Logo Menium" />

        {responseForgot ? (
          <p className="ml-7 mr-7 text-white text-center">Correo enviado!</p>
        ) : !forgotStatus ? (
          <p className="ml-7 mr-7 text-white text-center">
            Inicia sesión con <b className="textLogoSmall">menium</b> para pedir
            y pagar rápidamente y disfrutar de importantes descuentos
          </p>
        ) : (
          <p className="ml-7 mr-7 text-white text-center">
            Te enviaremos un correo para que puedas reestablecer tu constraseña
          </p>
        )}

        <div className="mt-4">
          <input
            type="email"
            placeholder="Email"
            className="input-register mt-5"
            onChange={handleChangeInputs}
            name="email"
            value={userInfo.email}
          />

          {!forgotStatus ? (
            <input
              type="password"
              placeholder="Contraseña"
              className="input-register mt-5"
              onChange={handleChangeInputs}
              name="password"
              value={userInfo.password}
            />
          ) : (
            <button
              onClick={() => sendResetPassword(userInfo.email)}
              className="bg-white text-black p-2 rounded-full w-full mt-4 flex justify-center items-center"
            >
              Enviar correo
            </button>
          )}

          <div className="text-right text-white italic mr-4 mt-4 mb-14">
            <p
              onClick={() => {
                setForgotStatus(!forgotStatus)
                setResponseForgot(false)
              }}
            >
              {!forgotStatus ? "He olvidado mi contraseña" : "Cerrar"}
            </p>
          </div>

          <input
            type="button"
            onClick={handleSubmit}
            className="bg-white text-black p-2 rounded-full w-full mt-4 font-bold text-lg"
            value="Inicia sesión"
          />

          <button
            onClick={loginUserWithGoogle}
            className="bg-white text-black p-2 rounded-full w-full mt-4 flex justify-center items-center"
          >
            <img src={googleIcon} alt="Logo google" className="w-7 mr-2" />
            Acceder con Google
          </button>

          <button
            onClick={loginUserWithFacebook}
            className="bg-white text-black p-2 rounded-full w-full mt-4 flex justify-center items-center"
          >
            <img src={facebookIcon} alt="Logo facebook" className="w-7 mr-2" />
            Acceder con Facebook
          </button>

          <div className="text-center text-white mt-8 mb-5">
            <p>
              ¿No tienes cuenta?{" "}
              <Link to="/register" className="font-bold">
                Crear cuenta
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectToRedux(Login, "User")
