import { useEffect, useState } from "react"
import { OnlyPaymentService } from "Service/OnlyPayment"
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie"

const useGetTableById = (tableId) => {
    const [state, setState] = useState({ isLoading: true, data: null, error: null });

    useEffect(() => {
        let isSubscribed = true;
        const getData = async () => {
            try {
                if (tableId) {
                    const resp = await OnlyPaymentService.getTableUsingId(tableId);
                    if (isSubscribed) {
                        setState({ isLoading: false, data: resp, error: null });
                    }
                }
                else {
                    if (isSubscribed) {
                        setState({ isLoading: false, data: null, error: "No TableId stored." });
                    }
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setState({ isLoading: false, data: null, error: error });
                }
            }
        }
        getData();
        return () => isSubscribed = false;
    }, [tableId])

    return state;
}

export default useGetTableById