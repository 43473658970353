import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import PayByProduct from "./PayByProduct"
import PayByExactAmount from "./PayByExactAmount"
import PayTheTotal from "./PayTheTotal"
import PayByDivision from "./PayByDivision"
import ConnectToRedux from "components/global/ConnectToRedux"
import iconForAllPay from "assets/icons/iconForAllPay.svg"
import iconForImportExact from "assets/icons/iconForImportExact.svg"
import iconForProduct from "assets/icons/iconForProduct.svg"
import iconForSplit from "assets/icons/iconForSplit.svg"
import { useSelector } from 'react-redux'

const OptionsBottomMenu = ({ payload, Commerce, BranchOffice, User, open }) => {
  const [showOption, setShowOption] = useState(0)
  const spanishText = ["Elige cómo quieres pagar", "Pagar por división", "Pagar por producto", "Pagar un importe exacto", "Pagar el total"];
  const englishText = ["Choose how you'd like to pay", "Pay by division", "Pay by product", "Pay an exact amount", "Pay the total"];

  const language = useSelector(state => state.main.Language);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const handleChangeView = (see) => setShowOption(see)

  const newPayload = {
    ...payload,
    commerce: Commerce.NO_ID_FIELD,
    branchOffice: BranchOffice.NO_ID_FIELD,
    uid: User?.uid ? User.uid : null,
    mode: "Open Order",
  }

  useEffect(() => {
    if (!open) {
      setShowOption(0)
    }
  }, [open])

  switch (showOption) {
    case 1:
      return <PayByDivision payload={newPayload} />
    case 2:
      return <PayByProduct payload={newPayload} />
    case 3:
      return <PayByExactAmount payload={newPayload} />
    case 4:
      return <PayTheTotal payload={newPayload} />
    default:
      return (
        <div className="flex flex-col items-start pb-4 px-2">
          <span
            className="text-lg color-title font-bold mb-2"
            style={{
              color: "#4527A0",
            }}
          >
            {texts[0]}
          </span>
          <button
            onClick={() => handleChangeView(1)}
            className="flex space-x-2 w-full py-2 items-center border-b-2 border-gray-300"
          >
            <img src={iconForSplit} alt="icono" className="w-12" />
            <label>{texts[1]}</label>
          </button>
          {
            payload.canPayProduct ? (
              <button
                onClick={() => handleChangeView(2)}
                className={[
                  "flex space-x-2 py-2 w-full items-center border-b-2 border-gray-300",
                  // !payload.usePayPerProducts && "text-isNotEnable-primary-color",
                ].join(" ")}
              >
                <img src={iconForProduct} alt="icono" className="w-12" />
                <label>{texts[2]}</label>
              </button>
            ) : (<></>)
          }
          <button
            onClick={() => handleChangeView(3)}
            className="flex space-x-2 py-2 w-full items-center border-b-2 border-gray-300"
          >
            <img src={iconForImportExact} alt="icono" className="w-12" />
            <label>{texts[3]}</label>
          </button>
          <button
            onClick={() => handleChangeView(4)}
            className="flex space-x-2 py-2 w-full items-center "
          >
            <img src={iconForAllPay} alt="icono" className="w-12" />
            <label>{texts[4]}</label>
          </button>
        </div>
      )
  }
}

OptionsBottomMenu.propTypes = {
  showOption: PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
}

OptionsBottomMenu.defaultProps = {
  showOption: 0,
  tip: 0,
  payload: {},
}

export default ConnectToRedux(OptionsBottomMenu, [
  "App",
  "ShoppingCart",
  "Commerce",
  "BranchOffice",
  "User",
])
