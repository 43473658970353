import { collection, getDocs, query, where } from "firebase/firestore";

export class Menu {

    constructor(rute, mode, firestore) {
        //Configuration for use FireStore;
        this.initStoreApp = firestore;

        //Prepare rute
        this.rute = rute;
        let getBase = rute.split("/");

        getBase.splice(2, getBase.length);
        this.basePath = getBase.join("/");

        this.mode = "";

        switch (mode) {
            case "isCloseOrder":
                this.mode = "Cerrado"
                break;
            case "isOpenOrder":
                this.mode = "Abierto"
                break;
            case "isTakeAway":
                this.mode = "Takeaway"
                break;
            default:
                break;
        }
    }

    getCollection = async (ref, referenceFieldName) => {
        const querySnapshot = await getDocs(ref);
        const collection = {};

        querySnapshot.forEach(el => {
            const id = el.id;
            const data = el.data();

            const verifyReference = data[referenceFieldName] ? data[referenceFieldName] : []

            collection[id] = {
                ...data,
                [referenceFieldName]: verifyReference.map(reference => reference.id)
            }
        })

        return collection
    }

    getProducts = async () => {
        const refProducts = collection(this.initStoreApp, `${this.basePath}/Products`);
        const products = await this.getCollection(refProducts, "subItems");

        return { products };
    }

    getProductGroups = async () => {
        const refProductGroups = collection(this.initStoreApp, `${this.basePath}/ProductGroups`);
        const productGroups = await this.getCollection(refProductGroups, "subItems");

        return { productGroups };
    }

    getModifiers = async () => {
        const refModifiers = collection(this.initStoreApp, `${this.basePath}/Modifiers`);
        const modifiers = await this.getCollection(refModifiers, "subItems");

        return { modifiers };
    }

    getModifierGroups = async () => {
        const refModifierGroups = collection(this.initStoreApp, `${this.basePath}/ModifierGroups`);
        const modifierGroups = await this.getCollection(refModifierGroups, "subItems");

        return { modifierGroups };
    }

    getCategories = async () => {
        const refCategories = collection(this.initStoreApp, `${this.basePath}/Categories`);
        const categories = await this.getCollection(refCategories, "products");

        return { categories };
    }


    getAllMenus = async () => {
        const refAllMenus = collection(this.initStoreApp, `${this.basePath}/Menus`);
        const allMenus = await this.getCollection(refAllMenus, "categories");

        return { allMenus };
    }

    getBranchOfficeMenu = async () => {
        const refBranchOfficeMenu = query(collection(this.initStoreApp, this.rute), where("mode", "==", this.mode))
        const branchOfficeMenu = await this.getCollection(refBranchOfficeMenu, "menus");

        return { branchOfficeMenu };
    }

    // Aca inicia el flujo

    orderPromises = resolve => resolve.reduce((previus, current) => ({ ...previus, ...current }), {});

    searchSubItemGroups = arrGroups => {
        const productGroups = this.menu["productGroups"];
        const modifierGroups = this.menu["modifierGroups"];

        return arrGroups.reduce((previus, current) => {

            const isModifierGroupsExists = modifierGroups[current];

            if (isModifierGroupsExists) {
                if (isModifierGroupsExists.isActive === true) {

                    const subItems = this.searchModifier(isModifierGroupsExists.subItems);

                    return [
                        ...previus,
                        {
                            ...isModifierGroupsExists,
                            subItems
                        }
                    ]
                }
            }

            const isProductGroupsExists = productGroups[current];

            if (isProductGroupsExists) {
                if (isProductGroupsExists.isActive === true) {

                    const subItems = this.searchProducts(isProductGroupsExists.subItems);

                    return [
                        ...previus,
                        {
                            ...isProductGroupsExists,
                            subItems
                        }
                    ]
                }
            }

            return previus

        }, [])
    }

    searchModifier = arrModifier => {
        const modifiers = this.menu["modifiers"];

        return arrModifier.reduce((previus, current) => {
            const isExists = modifiers[current];

            if (isExists) {

                if (isExists.isActive === true) {

                    let subItems = this.searchSubItemGroups(isExists.subItems);

                    return [
                        ...previus,
                        {
                            ...isExists,
                            subItems
                        }
                    ]
                }

            }

            return previus
        }, [])
    }

    searchProducts = arrProducts => {
        const products = this.menu["products"];

        return arrProducts.reduce((previus, current) => {
            const isExists = products[current];

            if (isExists) {

                if (isExists.stock > 0 && isExists.isActive === true) {

                    let subItems = this.searchSubItemGroups(isExists.subItems);

                    return [
                        ...previus,
                        {
                            ...isExists,
                            subItems
                        }
                    ]
                }

            }

            return previus
        }, [])
    }

    searchCategories = (arrCategories) => {
        const categories = this.menu["categories"];

        return arrCategories.reduce((previus, current) => {

            const isExists = categories[current];

            if (isExists) {

                const products = this.searchProducts(isExists.products)

                return [
                    ...previus,
                    {
                        ...isExists,
                        products
                    }
                ]
            }

            return previus
        }, [])
    }

    searchMenus = (arrMenus) => {
        const allMenus = this.menu["allMenus"];

        return arrMenus.map(menu => {
            const currentMenu = allMenus[menu];
            const categories = this.searchCategories(currentMenu.categories);

            return {
                ...currentMenu,
                categories
            }
        })

    }

    branchOfficeMenus = () => {
        const branchOfficeMenu = Object.values(this.menu.branchOfficeMenu);

        return branchOfficeMenu.map(branchMenu => {

            const menus = this.searchMenus(branchMenu.menus)

            return {
                ...branchMenu,
                menus
            }
        })
    }

    getCurrentMenu = (menus) => {

        //get day of the week by the app
        const currentDate = new Date();

        const document = menus.reduce((previus, current) => {
            const checkAvialabilities = current.availabilities.reduce((previus, current) => {

                if (currentDate.getDay() === current.dayOfWeek) {
                    const initialHour = new Date();
                    const endHour = new Date();

                    const splitInitialHour = current.startTime.split(":");
                    const splitEndHour = current.endTime.split(":");

                    initialHour.setHours(parseInt(splitInitialHour[0]), parseInt(splitInitialHour[1]));
                    endHour.setHours(parseInt(splitEndHour[0]), parseInt(splitEndHour[1]))


                    if (initialHour <= currentDate && endHour >= currentDate) {
                        return [...previus, current]
                    }

                    return previus;

                }

                return previus;
            }, [])

            if (checkAvialabilities.length > 0) {
                return [...previus, current]
            }

            return previus;

        }, [])

        return document[0]
    }

    convertToFormatJSON = async () => {

        const resolve = await Promise.all([
            this.getProducts(),
            this.getCategories(),
            this.getProductGroups(),
            this.getModifiers(),
            this.getModifierGroups(),
            this.getAllMenus(),
            this.getBranchOfficeMenu()
        ])

        this.menu = this.orderPromises(resolve);

        const menu = this.branchOfficeMenus();

        if (menu[0]) {
            const currentMenu = this.getCurrentMenu(menu[0].menus);
            return currentMenu;
        }

        return null;

    }

}