import { setMode } from 'storage/Actions/main'

const mapStateToProps = state => ({
    Mode: state.main.Mode
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        setMode
    }
}

export default sendInfo
