import ConnectToRedux from "components/global/ConnectToRedux"
import PaymentButtons from "components/global/PaymentButtons"
import React from "react"
import DetailPayment from "../Components/DetailPayment"
import { useHistory } from "react-router-dom"
import Terms from "../Components/Terms"

const CloseOrder = ({ payload, User, BranchOffice, Commerce, cleanCart }) => {
  const newPayload = {
    ...payload,
    commerce: Commerce.NO_ID_FIELD,
    commerceName: Commerce.Name,
    branchOffice: BranchOffice.NO_ID_FIELD,
    table: BranchOffice.table.numberPeople,
    uid: User?.uid ? User.uid : null,
    mode: "Close Order",
  }

  const history = useHistory()

  const handleClickPayment = (res) => {
    //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
    //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
    //Verifica si fue exitoso o no viene en {res}
    if (res.status) {
      cleanCart()
      history.push("/Pagado")
    } else {
      alert("El pago no fue exitoso")
    }
  }

  return (
    <div className="px-2 my-3">
      <DetailPayment payload={payload} />
      <PaymentButtons
        payload={newPayload}
        clickPayment={handleClickPayment}
        amount={payload.total + payload.tip}
        active={payload.total > 0}
      />
      <Terms />
    </div>
  )
}

export default ConnectToRedux(CloseOrder, ["User", "Commerce", "BranchOffice"])
