import ConnectToRedux from 'components/global/ConnectToRedux'
import { useGetCollection } from 'hooks/useGetCollection'
import React from 'react'
import PayByProduct from './PayByProduct'

const PrePayByProduct = ({ payload, Commerce, BranchOffice, User }) => {

    const refCollection = [
        "Shops",
        Commerce.NO_ID_FIELD,
        "BranchOffices",
        BranchOffice.NO_ID_FIELD,
        "Tables",
        BranchOffice.table.NO_ID_FIELD,
        "Order"
    ]

    const [loadingOrder, Order] = useGetCollection(refCollection);

    return (
        <>
            {
                loadingOrder === "success" && (
                    <PayByProduct payload={payload} reference={[...refCollection, Order[0] ? Order[0].NO_ID_FIELD : null]} />
                )
            }
        </>
    )
}

export default ConnectToRedux(PrePayByProduct, ['Commerce', 'BranchOffice', 'User'])
