import ConnectToRedux from "components/global/ConnectToRedux";
import React, { useEffect, useState } from "react";
import NavBar from "components/My-Information/NavBar";
import { ItemListFavorite } from "components/My-Favorite/ItemListFavorite";
import { orderBy } from "@firebase/firestore"
import { useGetCollection } from "hooks/useGetCollection";

const MyFavorite = ({
    User
}) => {
    const [userData, setUserData] = useState({
        date_of_birth: '',
        name: '',
        lastName: '',
        email: '',
        uid: '1',
        tel: ''
    })
    const [status, data] = useGetCollection(
        ["Users", userData.uid, "favorites"],
        orderBy("numberOrders", "asc")
    )

    useEffect(() => {
        setUserData(data => ({ ...data, ...User }))
    }, [User])

    return (
        <>
            <NavBar
                save={false}
                title="Locales favoritos"
            ></NavBar>

            <div className="p-5">
                {
                    (status === "success" && data) && data.map((item) => {
                        return (
                            <ItemListFavorite
                                nameShop={item.Name}
                                direction={item.direction}
                                numberOrders={item.numberOrders}
                                idCommerce={item.NO_ID_FIELD}
                                userId={userData.uid}
                                key={item.NO_ID_FIELD}
                                photoURL={item.photoURL}
                            ></ItemListFavorite>
                        )
                    })
                }

            </div>
        </>
    )
}

export default ConnectToRedux(MyFavorite, ["User"])