import React from "react";
import PhotoShopTest from 'assets/images/PhotoShopTest.jpg';
import { useGetCollection } from "hooks/useGetCollection";
import { where } from "@firebase/firestore";

export const ItemListFavorite = ({ nameShop, direction, numberOrders, photoURL, idCommerce, userId }) => {

    const [loading, orders] = useGetCollection(['orders'], where('uid', '==', userId), where('commerce', '==', idCommerce))

    return (
        <>
            <div className="flex justify-between items-center pt-2">
                <div className="flex">
                    <img src={photoURL ? photoURL : PhotoShopTest} alt="Shop" className="w-24 h-16" />
                    <div className="pl-4 flex items-center" >
                        <div>
                            <p className="font-bold	text-xl	">{nameShop}</p>
                            <p className="text-gray-500	" >{direction}</p>
                        </div>

                    </div>
                </div>
                <div>
                    <p className="font-bold" >{loading === "success" ? orders.length : numberOrders} Pedidos</p>
                </div>
            </div>
            <div className="separator pt-2" ></div>
        </>
    )
}