/*
    ------------------------------------------------------------
    DESCRIPCION DE CAMPOS

    orderId: Este es el identificador de la orden (NO_FIELD_ID)
    numberOrder: Es la cantidad de ordenes pedidas (tendras que llamar a la coleccion de ordenes y sacar cuantas hay)
    channelLinkId: Se encuentra en la informacion de la branchOffice
    numberTable: Es el numero de la mesa
    total: Es la suma con todo y propina
    tip: Es la propina
    note: Es la nota a cocina
    customer: Es un Objeto que contiene la informacion del customer (name, phoneNumber, email)

    Example without Customer
    {
        name: "Sin registrar",
        phoneNumber: "Sin registrar",
        email: "Sin registrar",
    }
    
    Example without Customer
    {
        name: "name customer",
        phoneNumber: "phone number customer",
        email: "email customer",
    }

    products: Mandarlos exactamente igual como se almacenan en ShoppingCart

    ------------------------------------------------------------
    EJEMPLO DE COMO SERIA LO DE DELIVERECT

    -----Llamas la clase
    const deliverect = new Deliverec();
    
    ----Estructura del payload
    const payload = {
        orderId: "18",
        numberOrder: "24",
        channelLinkId: BranchOffice.channelLinkId,
        numberTable: 5,
        total: 19.6,
        tip: 5,
        note: "Test note",
        customer: {
            name: "Sin registrar",
            phoneNumber: "Sin registrar",
            email: "Sin registrar",
        },
        products: ShoppingCart
    }

    ----Llamado a la funcion que hace todo el trabajo
    const result = await deliverect.sendToDeliverect(payload)

    ------------------------------------------------------------
*/

import Axios from "axios"
import config from "../config.json"

const environment = process.env.STAGE_ENVIRONMENT || "development"

const { deliverectAPI } = config[environment]

export class Deliverec {
  orderProducts = (product) => ({
    ...product,
    price: product.price * 100,
    subItems: product.subItems.map((el) => this.orderProducts(el)),
  })

  orderPayload = (
    {
      products,
      orderType,
      pickupTime,
      estimatedPickupTime,
      numberTable,
      numberOrder,
      channelLinkId,
      customer,
      tip,
      note = "",
      orderId,
      total,
    },
    channel,
  ) => {
    const defaults = products.defaults.map((el) => this.orderProducts(el))

    const customs = products.custom.map((el) => this.orderProducts(el))

    const items = [...defaults, ...customs]

    const raw = JSON.stringify({
      channelOrderId: String(orderId),
      channelOrderDisplayId: String(numberOrder), // Contador de ordenes
      channelLinkId, //Propiedad ChannelLinkId que se encuentra dentro de BranchOffice
      orderType,
      table: numberTable, //Numero de mesa
      channel,
      courier: "restaurant",
      customer,
      orderIsAlreadyPaid: true,
      payment: {
        amount: Math.round((total + tip) * 100), //Centavos
        type: 0,
      },
      tip: Math.floor(tip * 100), //Propinas
      note, //Nota a cocina
      items,
      decimalDigits: 2,
      numberOfCustomers: 1,
      deliveryCost: 0,
      serviceCharge: 0,
      discountTotal: 0,
      pickupTime,
      estimatedPickupTime,
    })

    return raw
  }

  sendOrder = async (token, payload, deliverectConfig) => {
    const convertToDeliverectPayload = this.orderPayload(
      payload,
      deliverectConfig.channelName,
    )

    const result = await Axios.post(
      `${deliverectAPI}/${deliverectConfig.channelName}/order/${payload.channelLinkId}`,
      convertToDeliverectPayload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      },
    )

    return result.data
  }

  getToken = async (clientId, clientSecret) => {
    const raw = JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      audience: "https://api.deliverect.com",
      grant_type: "client_credentials",
    })

    const result = await Axios.post(`${deliverectAPI}/oauth/token`, raw, {
      headers: {
        "Content-Type": "application/json",
      },
    })

    return result.data
  }

  sendToDeliverect = async (payload, deliverectConfig) => {
    const token = await this.getToken(
      deliverectConfig.clientId,
      deliverectConfig.clientSecret,
    )
    const result = await this.sendOrder(
      token.access_token,
      payload,
      deliverectConfig,
    )

    return result
  }
}
