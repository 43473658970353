import ConnectToRedux from "components/global/ConnectToRedux"
import PaymentButtons from "components/global/PaymentButtons"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { numberWithCommas } from "utils/Utils"
import BottomsPayByDivision from "./BottomsPayByDivision"
import Tips from "../../../../views/MyAcount2/Components/Tips"
import securePaymentGray from 'assets/images/secure-payment-footer-gray.png'
import { useSelector } from 'react-redux'

const PayByDivision = ({ payload, BranchOffice }) => {
  const history = useHistory()

  const spanishText = ["Pagar por división", "¿Entre cuántas personas quieres dividir?", "A pagar por persona:", "¿Por cuántas personas vas a pagar?", "Tú pagarás", "¿Qué te ha parecido el servicio?"];
  const englishText = ["Pay by division", "Amongs how many people do you want to split the bill?", "Payable per person", "How many people are you paying for?", "You'll pay", "How was the service?"];

  const language = useSelector(state => state.main.Language);
  const merchantDetails = useSelector(state => state.main.BranchOffice);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const zeroTip = {
    label: "NONE",
    value: 0.0
  }

  const [division, setDivision] = useState({
    toPayPerPerson: 1,
    toPay: 1,
  })

  const [payPerPerson, setPayPerPerson] = useState(0)
  const incrementToPayPerPerson = () => {
    const increment = division.toPayPerPerson + 1
    setDivision({ ...division, toPayPerPerson: increment })
  }

  const decrementToPayPerPerson = () => {
    const decrement = division.toPayPerPerson - 1

    if (decrement >= 1) {

      if (division.toPay <= decrement) {
        setDivision({ ...division, toPayPerPerson: decrement })
      }
      else {
        setDivision({ toPay: decrement, toPayPerPerson: decrement })

      }
    }

  }

  const incrementToPay = () => {
    if (division.toPay < division.toPayPerPerson) {
      const increment = division.toPay + 1
      setDivision({ ...division, toPay: increment })
    }
  }

  const decrementToPay = () => {
    const decrement = division.toPay - 1

    if (decrement >= 1) {
      setDivision({ ...division, toPay: decrement })
    }
  }

  const [valueTip, setValueTip] = useState(zeroTip)
  const [isStaticTip,] = useState(false)
  const handleChangeTip = (el) => {
    if (!isStaticTip) {
      setValueTip(el)
    }
  }
  const handleClickPayment = (res) => {
    //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
    //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
    //Verifica si fue exitoso o no viene en {res}
    if (res.status) {
      history.push("/Pagado")
    } else {
      alert("El pago no fue exitoso")
    }
  }

  useEffect(() => {
    const result = (payload.divisionRemainingPrice) / division.toPayPerPerson;
    setPayPerPerson(result);
  }, [
    division.toPayPerPerson,
    division.toPay,
    payload.divisionRemainingPrice,
    payload.remainingPrice,
  ])

  useEffect(() => {
    if (BranchOffice) {
      setDivision((data) => ({
        ...data,
        toPayPerPerson: 1,
      }))
    }
  }, [BranchOffice])

  return (
    <div className="flex flex-col space-y-4 pb-4">
      <h4 className="text-xl font-bold color-title">{texts[0]}</h4>
      <div className="flex flex-col items-center">
        <span className="text-center">{texts[1]}</span>
        {/* <span className="text-isNotEnable-primary-color">
          (Aunque no usen Menium)
        </span> */}
      </div>
      <div className="flex flex-col items-center text-sm space-y-4">
        <BottomsPayByDivision
          total={division.toPayPerPerson}
          onClickIncrement={incrementToPayPerPerson}
          onClickDecrement={decrementToPayPerPerson}
        >
        </BottomsPayByDivision>
        <span className="text-isNotEnable-primary-color">{texts[2]}</span>
        <span className="font-bold font-title-bold text-black">
          {numberWithCommas(payPerPerson)}€
        </span>
      </div>

      <div className="flex flex-col items-center">
        <span>{texts[3]}</span>
      </div>
      <div className="flex flex-col items-center text-sm space-y-4">
        <BottomsPayByDivision
          total={division.toPay}
          onClickIncrement={incrementToPay}
          onClickDecrement={decrementToPay}
        >
        </BottomsPayByDivision>
        <span className="text-isNotEnable-primary-color">{texts[4]}</span>
        <span className="color-title font-bold font-title-bold amountInputFieldActive">
          {numberWithCommas((division.toPay * payPerPerson))}€
        </span>
      </div>

      {!merchantDetails?.isTipDisabled ?
        < h4 className="text-lg color-title font-bold">
          {texts[5]}
          <Tips
            value={valueTip.label}
            paymentAmount={division.toPay * payPerPerson}
            onGetValue={handleChangeTip}
          />
        </h4>
        : null
      }

      <PaymentButtons
        payload={payload}
        amount={(division.toPay * payPerPerson)}
        active={(division.toPay * payPerPerson) > 0}
        tipAmountCalculated={valueTip.amount}
        paymentType={1}
      />
      <img className="footer-payment-image-center" src={securePaymentGray}></img>
    </div>
  )
}

export default ConnectToRedux(PayByDivision, "BranchOffice")
