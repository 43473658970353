import PaymentButtons from "components/global/PaymentButtons"
import React from "react"
import { numberWithCommas } from "utils/Utils"

const PayTheTotal = ({ payload }) => {
  const newPayload = {
    ...payload,
    paidOut: payload.total + payload.tip,
    modeOpenOrder: "PayTheTotal",
    usePayPerProducts: false,
  }

  return (
    <div className="flex flex-col space-y-4 pb-8">
      <h4 className="text-xl font-bold color-title">
        Pagar el total
      </h4>
      <div className="flex justify-between">
        <div className="flex flex-col justify-center text-center">
          <span className="text-sm text-isNotEnable-primary-color">Pagado:</span>
          <h2 className="font-bold text-black">
            {numberWithCommas(payload.paidOut)} €
          </h2>
        </div>
        <div
          style={{
            borderColor: "#4527A0",
            borderWidth: 2,
          }}
          className="flex flex-col px-4 py-2 rounded justify-center text-center"
        >
          <span className="text-sm text-isNotEnable-primary-color">A pagar:</span>
          <h2 className="color-title font-bold">
            {numberWithCommas(
              (payload.total + payload.tip - payload.paidOut),
            )}{" "}
            €
          </h2>
        </div>
        <div className="flex flex-col justify-center text-center">
          <span className="text-sm text-isNotEnable-primary-color">Total cuenta:</span>
          <h2 className="font-bold text-black">
            {numberWithCommas((payload.total + payload.tip))} €
          </h2>
        </div>
      </div>
      <h4 className="text-lg color-title font-bold">
        Con qué quieres pagar
      </h4>

      <PaymentButtons
        payload={{
          ...newPayload,
          amount: payload.total + payload.tip - payload.paidOut,
        }}
        amount={payload.total + payload.tip - payload.paidOut}
        active={payload.total > 0}
      />

      <div className="flex text-center text-xs mt-2 text-isNotEnable-primary-color">
        <p>
          Al continuar, estas aceptando los{" "}
          <strong className="color-title"> Términos y Condiciones </strong>{" "}
          y la{" "}
          <strong className="color-title">Política de Privacidad</strong> de
          Menium
        </p>
      </div>
    </div>
  )
}

export default PayTheTotal
