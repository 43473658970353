import LogoMenium from "assets/images/LOGO-BLANCO.png"
const Proximamente = () => {
    return (
        <div className="flex flex-col h-screen justify-center items-center bg-gradient-menium">
            <div className="items-center flex flex-col mb-10">
                <img src={LogoMenium} alt="Logo" />
            </div>

            <div
                className="bg-purple-500 p-8 py-14 flex flex-col items-center text-white"
                style={{ borderRadius: "20px", width: "50%" }}
            >

                <p className="text-white">PROXIMAMENTE</p>
            </div>
        </div>
    )
}

export default Proximamente