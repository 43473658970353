import { getFunctions, httpsCallable } from "@firebase/functions"

export class Stripe {
    constructor(app) {
        this.initFuncApp = getFunctions(app);
    }

    AddMethodPayment = async (stripeCustomerId) => {

        try {
            const getSessionId = httpsCallable(this.initFuncApp, "createSetupCheckoutSession");

            const result = await getSessionId({ customer: stripeCustomerId })

            const stripe = null;

            const paymentCreate = await stripe.redirectToCheckout({
                sessionId: result.data
            })

            if (paymentCreate.error) {
                return { error: paymentCreate.error }
            }

            return { paymentCreate }
        } catch (error) {
            console.error(error)
        }

    }

    GetMethodPayments = async (stripeCustomerId) => {

        const getPaymentMethods = httpsCallable(this.initFuncApp, "getPaymentMethods");

        const result = await getPaymentMethods({ customer: stripeCustomerId })

        if (result.error) {
            return { error: result.error }
        }

        return { data: result.data.data }

    }

    RemoveMethodPayment = async (idMethod) => {
        const deletePaymentMethod = httpsCallable(this.initFuncApp, "deletePaymentMethod");

        const result = await deletePaymentMethod({ payment: idMethod })

        if (result.error) {
            return { error: result.error }
        }

        return { data: result.data }
    }

    InitCardPaymentMethod = async (stripeCustomerId, restaurant, amount) => {
        const stripe = null;

        const getSession = await httpsCallable(this.initFuncApp, "getSessionId");

        let setDataToFunction = {
            payment: {
                amount: Math.round(amount * 100), //total + tip
                type: 3,
            },
            restaurantName: restaurant,
            customer: null
        }

        if (stripeCustomerId) {
            setDataToFunction = { ...setDataToFunction, customer: stripeCustomerId }
        }

        const { data } = await getSession(setDataToFunction)

        const createPayment = null;

        if (createPayment.error) {
        }

        return createPayment;
    }

    getPayment = async (sessionId) => {

        const getPayment = await httpsCallable(this.initFuncApp, "getPaymentInfo");
        const { data, error } = await getPayment({ id: sessionId })

        if (error) {
            return { status: false, error }
        }

        return { status: true, data }

    }
}