import { useEffect, useState } from "react";
import { doc, query } from "@firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";

export const useGetDoc = (rute, ...condition) => {

    const refDoc = doc(useFirestore(), ...rute);

    const [docResponse, setDocResponse] = useState({
        status: 'loading',
        data: [],
        error: null
    })

    const { status, data, error } = useFirestoreDocData(query(refDoc, ...condition));

    useEffect(() => {

        if (status === "success") {

            setDocResponse({
                data,
                status,
                error: null
            })
        }
        else if (status === "error") {
        }

    }, [status, data, error])

    return [docResponse.status, docResponse.data, docResponse.error]

}
