import React, { useEffect, useState } from "react"
import PinIcon from "assets/icons/pin.svg"
import ForkIcon from "assets/icons/fork.svg"
import DollarIcon from "assets/icons/dollar.svg"
import { Rating } from "@mui/material"

export const InfoCommerce = ({ commerceData }) => {

  const [schedulesOrder, setSchedulesOrder] = useState([])

  useEffect(() => {
    const schedules = commerceData.schedules;

    if (schedules) {
      const orderByDay = schedules.reduce((previus, current) => {

        let previusValue = []

        if (previus[current.dayInt]) {
          previusValue = [...previus[current.dayInt].Hour]
        }

        return {
          ...previus,
          [current.dayInt]: {
            ...current,
            Hour: [...previusValue, current.Hour]
          }
        }

      }, {})

      const orderSchedules = Object.values(orderByDay).sort((a, b) => {

        if (b.dayInt === 0) return -1

        return a.dayInt - b.dayInt
      });

      setSchedulesOrder(orderSchedules)
    }

  }, [commerceData.schedules])

  return (
    <>
      <img
        src={commerceData.image}
        alt="Shop"
        className="w-full h-60 object-cover"
      />
      <div className="pt-5 px-8 pb-8">
        <p className="text-2xl  title-shop">{commerceData.Name}</p>
        <p className="text-lg text-gray-500 mt-4">{commerceData.Description}</p>
        <div className="flex flex-row items-center py-2 space-x-2">
          <img src={PinIcon} alt="Pin" />
          <p className="pl-1  ">{commerceData.direction}</p>
        </div>
        <div className="flex flex-row items-center py-2 space-x-2">
          <img src={ForkIcon} alt="Pin" />
          <p className="pl-1  ">{commerceData.typeCommerce}</p>
        </div>
        <div className="flex flex-row items-center py-2 space-x-2">
          <img src={DollarIcon} alt="Pin" />
          <p className="">Precio medio: {commerceData.HalfPrice}€</p>
        </div>

        <div className="flex pt-2 items-center">
          <Rating
            value={commerceData.raiting}
            defaultChecked={true}
            precision={0.5}
            defaultValue={commerceData.raiting}
            disabled={true}
          />
          <p className="font-bold pl-2">5.00 ({commerceData.raiting}+)</p>
        </div>

        <div className="pt-4">
          <p className="font-extrabold text-lg">Horario</p>

          {schedulesOrder.map((item) => {
            return (
              <div className="flex justify-between text-lg" key={item.day}>
                <p>{item.day}</p>
                <p>{item.Hour.join(", ")}</p>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
