import { container } from 'Global'
import React, { useState } from 'react'
import iconBack from 'assets/icons/back.svg'
import { User } from 'firebase/firebase'
import { useFirebaseApp, useFirestore } from 'reactfire';
import facebookIcon from 'assets/images/facebook.svg';
import googleIcon from 'assets/images/google.svg';
import ConnectToRedux from 'components/global/ConnectToRedux';
import { useHistory } from 'react-router';

const Register = ({ addUser }) => {

    const [userInfo, setUserInfo] = useState({
        names: '',
        surnames: '',
        date_of_birth: '',
        email: '',
        password: '',
        repeat_password: ''
    })

    const userClass = new User(useFirebaseApp(), useFirestore());
    const history = useHistory()

    const handleSubmit = e => {
        e.preventDefault()

        userClass.createUser(userInfo)
            .then(resp => {
                if (resp) {
                    addUser(resp.user)
                    userClass.sendEmail(resp.user.email)
                    history.push('/')
                }
            })
            .catch(err => console.error(err))

    }

    const handleChangeInputs = ({ target: { value, name } }) => setUserInfo({ ...userInfo, [name]: value })

    const createUserWithGoogle = () => {
        userClass.loginUserWithGoogle()
            .then(resp => {
                if (!resp.error) {
                    if (resp.isSendEmail) {
                        userClass.sendEmail(resp.user.email)
                    }
                    addUser(resp.user)
                    history.push('/')
                }
            })
            .catch(err => console.error(err))
    }

    const createUserWithFacebook = () => {
        userClass.loginUserWithFacebook()
            .then(resp => {
                if (!resp.error) {
                    if (resp.isSendEmail) {
                        userClass.sendEmail(resp.user.email)
                    }
                    addUser(resp.user)
                    history.push('/')
                }
            })
            .catch(err => console.error(err))
    }

    return (
        <div
            className="flex flex-col text-center w-screen overflow-y-hidden"
            style={container}
        >
            <div className="flex-1 overflow-y-scroll m-7">
                <div className="">
                    <img src={iconBack} alt="Icon Back" className="h-8" onClick={() => history.goBack()} />
                </div>
                <form className="mt-11 w-full flex flex-col justify-center items-center" onSubmit={handleSubmit}>
                    <h1 className="font-bold text-2xl">Crea tu cuenta</h1>
                    <p className="mt-9">
                        Bienvenido a <b className="textLogoSmall">menium</b>, crea tu cuenta para pedir y pagar rápidamente y disfrutar de importantes descuentos
                    </p>

                    <input
                        type="text"
                        placeholder="Nombre"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="names"
                        value={userInfo.names}
                    />

                    <input
                        type="text"
                        placeholder="Apellidos"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="surnames"
                        value={userInfo.surnames}
                    />

                    <input
                        type="date"
                        placeholder="Fecha de nacimiento"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="date_of_birth"
                        value={userInfo.date_of_birth}
                    />

                    <input
                        type="email"
                        placeholder="Email"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="email"
                        value={userInfo.email}
                    />

                    <input
                        type="password"
                        placeholder="Contraseña"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="password"
                        value={userInfo.password}
                    />

                    <input
                        type="password"
                        placeholder="Repetir contraseña"
                        className="input-register mt-5"
                        onChange={handleChangeInputs}
                        name="repeat_password"
                        value={userInfo.repeat_password}
                    />

                    <input
                        type="submit"
                        className="bg-gradient-menium text-white p-2 rounded-full flex-1 ml-3 mr-3 w-full mt-4"
                        value="Crear cuenta"
                    />

                </form>

                <div className='w-full flex flex-col justify-center items-center mb-4'>
                    <button
                        onClick={createUserWithGoogle}
                        className="bg-gradient-menium text-white p-2 rounded-full flex-1 ml-3 mr-3 w-full mt-4 flex justify-center items-center"
                    >
                        <img src={googleIcon} alt="Logo google" className="w-7 mr-2" />
                        Acceder con Google
                    </button>

                    <button
                        onClick={createUserWithFacebook}
                        className="bg-gradient-menium text-white p-2 rounded-full flex-1 ml-3 mr-3 w-full mt-4 flex justify-center items-center"
                    >
                        <img src={facebookIcon} alt="Logo facebook" className="w-7 mr-2" />
                        Acceder con Facebook
                    </button>
                </div>

            </div>
        </div>
    )
}
export default ConnectToRedux(Register, 'User')