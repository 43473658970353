import React from 'react'
import PropTypes from 'prop-types'

export const CategoryMenuItem = ({ label, isMarked, onClick }) => {

    const rename = label.split(' ').join('')

    const handleClickItem = async () => {
        const element = document.getElementById(rename)
        await element.scrollIntoView({ behavior: 'smooth' })
        onClick(label)
    }

    return (
        <label
            className={
                [
                    "flex items-center font-bold min-w-max",
                    isMarked && "rounded-full text-white pl-2 pr-2 h-7 bg-gradient-menium"
                ].join(' ')
            }
            onClick={handleClickItem}
            id={`${rename}_item_menu`}
        >
            {label}
        </label>
    )
}

CategoryMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    isMarked: PropTypes.bool.isRequired,
    onClick: PropTypes.func
}

CategoryMenuItem.defaultProps = {
    onClick: () => { }
}
