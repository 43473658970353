import React from "react"
import { numberWithCommas } from "../../../utils/Utils"

const CloseOrder = ({ code, payload, isTakeAway }) => {
  return (
    <>
      <h2 className="color-aditional-menium text-3xl text-bold mt-3">
        {isTakeAway || payload.mode === "Close Order"
          ? `${numberWithCommas(payload.total)}€`
          : ""}
      </h2>
    </>
  )
}

CloseOrder.defaultProps = {
  isTakeAway: false,
}

export default CloseOrder
