import BottomDialog from 'components/account/bottomDialog'
import OptionsBottomMenu from 'components/OnlyPayment/OptionsBottomMenu'
import React, { useState, useEffect } from 'react'
import Terms from 'views/MyAcount2/Components/Terms'
import DetailPaymentOpenOrder from '../Components/DetailPaymentOpenOrder'
import { useSelector } from 'react-redux'

const OpenOrder = ({ payload, tip }) => {

    const [isOpenBottomDialog, setIsOpenBottomDialog] = useState(false);

    const spanishText = ["Divide y Paga"];
    const englishText = ["Split and Pay"];

    const language = useSelector(state => state.main.Language);

    const [texts, setTexts] = useState(spanishText);

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const changeStateBottomDialog = () => setIsOpenBottomDialog(!isOpenBottomDialog)

    const newPayload = {
        ...payload,
        tip
    }

    useEffect(() => {
        console.log(JSON.stringify(newPayload));
    }, [])

    return (
        <div className="px-5 my-2">
            <DetailPaymentOpenOrder payload={payload} />
            <div className="h-10 w-full flex justify-around mt-2">
                <button
                    className={
                        [
                            "bg-gradient-menium text-white p-2 rounded-full flex-1 max-w-sm",
                            payload.remainingPrice === 0 && "text-isNotEnable-secundary-color"
                        ].join(' ')
                    }
                    disabled={payload.remainingPrice === 0}
                    onClick={changeStateBottomDialog}
                >
                    {texts[0]}
                </button>
            </div>
            <Terms />
            <BottomDialog
                content={
                    <OptionsBottomMenu payload={newPayload} />
                }
                onHandle={changeStateBottomDialog}
                open={isOpenBottomDialog}
            />
        </div>
    )
}

export default OpenOrder