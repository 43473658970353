import React, { useEffect, useState } from "react"
import PaymentButtons from "components/global/PaymentButtons"
import ConnectToRedux from "components/global/ConnectToRedux"
import { useGetCollection } from "hooks/useGetCollection"
import ProductListItem from "components/account/itemProductList"
import { getTotalInShoppingCart, numberWithCommas } from "utils/Utils"

const PayByProduct = ({ payload, reference }) => {
  const [defaultProductsToPayment, setDefaultProductsToPayment] = useState({})
  const [customProductsToPayment, setCustomProductsToPayment] = useState({})
  const [productsPayment, setProductsPayment] = useState({
    defaults: [],
    custom: [],
  })

  const [loadingDefaultProducts, defaultProducs] = useGetCollection([
    ...reference,
    "defaults",
  ])

  const [loadingCustomProducts, customProducs] = useGetCollection([
    ...reference,
    "custom",
  ])

  const handleStateChangeDefaults = (value, id, index) => {
    let completeProduct = false
    if (value) {
      if (value === defaultProducs[index].quantity) {
        completeProduct = true
      }

      setDefaultProductsToPayment({
        ...defaultProductsToPayment,
        [id]: {
          ...defaultProducs[index],
          quantity: value,
          complete: completeProduct,
          index,
        },
      })
    } else {
      if (!value && defaultProductsToPayment[id]) {
        let copyObject = { ...defaultProductsToPayment }

        delete copyObject[id]

        setDefaultProductsToPayment(copyObject)
      }
    }
  }

  const handleStateChangeCustoms = (value, id, index) => {
    let completeProduct = false
    if (value) {
      if (value === customProducs[index].quantity) {
        completeProduct = true
      }

      setCustomProductsToPayment({
        ...customProductsToPayment,
        [id]: {
          ...customProducs[index],
          quantity: value,
          complete: completeProduct,
          index,
        },
      })
    } else {
      if (!value && customProductsToPayment[id]) {
        let copyObject = { ...customProductsToPayment }

        delete copyObject[id]

        setCustomProductsToPayment(copyObject)
      }
    }
  }

  const total = getTotalInShoppingCart(productsPayment)

  const newPayload = {
    ...payload,
    products: productsPayment,
    beforeProducts: { defaults: defaultProducs, custom: customProducs },
    reference,
    modeOpenOrder: "PayByProduct",
    amount: total,
  }

  const handleClickPayment = (res) => {
    //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
    //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
    //Verifica si fue exitoso o no viene en {res}
  }

  useEffect(() => {
    setProductsPayment({
      defaults: Object.values(defaultProductsToPayment),
      custom: Object.values(customProductsToPayment),
    })
  }, [customProductsToPayment, defaultProductsToPayment])

  return (
    <div className="flex flex-col space-y-4 pb-8  relative">
      <h4 className="text-xl font-bold color-title">Pagar por producto</h4>
      <span>Selecciona los platos que deseas pagar:</span>
      <div className="h-52 overflow-y-auto px-1 borders-for-product-list relative z-auto">
        {loadingDefaultProducts === "success" &&
          defaultProducs.map((element, index) => (
            <ProductListItem
              {...element}
              checked={defaultProductsToPayment[element.NO_ID_FIELD]}
              index={index}
              onChange={handleStateChangeDefaults}
              key={`element_Defaults_ShoppingCart_${index}`}
            />
          ))}

        {loadingCustomProducts === "success" &&
          customProducs.map((element, index) => (
            <ProductListItem
              {...element}
              checked={customProductsToPayment[element.NO_ID_FIELD]}
              index={index}
              onChange={handleStateChangeCustoms}
              key={`element_Defaults_ShoppingCart_${index}`}
            />
          ))}
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-between py-4">
          <div className="flex flex-col justify-center text-center text-isNotEnable-primary-color">
            <span className="text-sm">Falta por pagar</span>
            <div className="font-bold text-sm text-black">
              {numberWithCommas(
                payload.total + payload.tip - payload.paidOut - total,
              )}{" "}
              €
            </div>
          </div>
          <div className="flex flex-col justify-center text-isNotEnable-primary-color border-payment-for-product p-4 text-center">
            <span className="text-sm">A pagar:</span>
            <div className="font-bold text-sm color-title">
              {numberWithCommas(total)} €
            </div>
          </div>
          <div className="flex flex-col justify-center text-center text-isNotEnable-primary-color">
            <span className="text-sm">Total cuenta:</span>
            <div className="font-bold text-sm text-black">
              {numberWithCommas(payload.total + payload.tip)} €
            </div>
          </div>
        </div>
      </div>
      <h4 className="text-lg color-title font-bold">Con qué quieres pagar</h4>

      <PaymentButtons
        payload={newPayload}
        clickPayment={handleClickPayment}
        amount={total}
        active={total > 0}
      />

      <div className="flex text-center text-xs mt-2 text-isNotEnable-primary-color">
        <p>
          Al continuar, estas aceptando los{" "}
          <strong className="text-purple-900"> Términos y Condiciones </strong>{" "}
          y la{" "}
          <strong className="text-purple-900">Política de Privacidad</strong> de
          Menium
        </p>
      </div>
    </div>
  )
}

export default ConnectToRedux(PayByProduct, ["Commerce", "BranchOffice"])
