import ConnectToRedux from "components/global/ConnectToRedux"
import PaymentButtons from "components/global/PaymentButtons"
import { useGetCollection } from "hooks/useGetCollection"
import React, { useEffect, useState } from "react"
import { getIntervalHours } from "utils/Utils"
import DetailPayment from "../Components/DetailPayment"
import { useHistory } from "react-router-dom"
import Terms from "../Components/Terms"
const TakeAway = ({ payload, User, Commerce, BranchOffice, cleanCart }) => {
  const [newPayload, setNewPayload] = useState({
    ...payload,
    hour: "",
    branchOffice: "",
    location: "",
  })
  const history = useHistory()

  const [, branchOffices] = useGetCollection([
    "Shops",
    Commerce.NO_ID_FIELD,
    "BranchOffices",
  ])

  const schedules = getIntervalHours(Commerce.schedules)

  const handleClickPayment = (res) => {
    //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
    //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
    //Verifica si fue exitoso o no viene en {res}
    if (res.status) {
      cleanCart()
      history.push("/paid")
    } else {
      alert("El pago no fue exitoso")
    }
  }

  const handleChangeSelect = ({ target: { value, name } }) => {
    if (name === "branchOffice") {
      const newValue = JSON.parse(value);

      setNewPayload({
        ...newPayload,
        branchOffice: newValue.id,
        location: newValue.location,
      })
    } else {

      let estimate = new Date();
      if (value === "Lo antes posible (entrega inmediata/10-20min)") {
        estimate.setMinutes(estimate.getMinutes() + 20);
      }
      else {
        const splitHour = value.split(":");

        const estimateDate = new Date();

        estimateDate.setHours(parseInt(splitHour[0]), parseInt(splitHour[1]));
        estimate = estimateDate;
      }

      setNewPayload({
        ...newPayload,
        [name]: value,
        pickupTime: estimate,
        estimatedPickupTime: estimate
      })
    }
  }

  useEffect(() => {


    setNewPayload(newPay => ({
      ...newPay,
      ...payload,
      commerce: Commerce.NO_ID_FIELD,
      mode: "TakeAway",
      uid: User?.uid ? User.uid : null,
      hour: "",
      branchOffice: "",
      commerceName: Commerce.Name,
    }))
  }, [Commerce.NO_ID_FIELD, Commerce.Name, User.uid, payload])

  const branch = BranchOffice.selectOtherPlace ? newPayload?.id : true;

  const active = newPayload.hour && branch;

  return (
    <div className="px-2 my-3 flex-1">
      <p>Detalles de la recogida</p>
      <span className="text-sm text-gray-500">
        Si es posible, escoge el sitio y la hora de recogida
      </span>
      <p>Hora:</p>
      <select
        name="hour"
        value={newPayload.hour}
        onChange={handleChangeSelect}
        className="w-full border-2 border-gray-300 rounded py-1 px-0.5 text-gray-500"
      >
        <option value={""}>Seleccione al menos una opción</option>

        {schedules &&
          schedules.map((el, index) => (
            <option value={el} key={`option_item_hour_${index}`}>
              {el}
            </option>
          ))}
      </select>

      {
        BranchOffice.selectOtherPlace && (
          <>
            <p>Sitio</p>
            <select
              name="branchOffice"
              value={JSON.stringify({
                id: newPayload.branchOffice,
                location: newPayload.location,
              })}
              onChange={handleChangeSelect}
              className="w-full border-2 border-gray-300 rounded py-1 px-0.5 text-gray-500"
            >
              <option value={""}>Seleccione al menos una opción</option>

              {branchOffices.map((el, i) => (
                <option
                  value={JSON.stringify({ id: el.NO_ID_FIELD, location: el.address })}
                  key={`option_item_place_${i}`}
                >
                  {el.address}
                </option>
              ))}
            </select>
          </>
        )
      }
      <DetailPayment payload={payload} />
      <PaymentButtons
        payload={newPayload}
        clickPayment={handleClickPayment}
        amount={payload.total + payload.tip}
        active={active}
      />
      <Terms />
    </div>
  )
}

export default ConnectToRedux(TakeAway, ["User", "Commerce", "BranchOffice"])
