import { container } from 'Global'
import React from 'react'
import backIcon from 'assets/icons/back.svg'
import { useHistory } from 'react-router'

const TechnicalSupport = () => {

    const history = useHistory();

    return (
        <div
            style={container}
            className="overflow-y-hidden flex flex-col"
        >
            <nav className="flex flex-row h-20 items-center justify-center border-b-2">
                <div className="ml-10 mr-12 w-full">
                    <img src={backIcon} className="w-4" alt="Icon back" onClick={() => history.goBack()} />
                </div>
                <div className="flex-1 font-bold text-xl absolute">
                    <h2 className="text-2xl">Soporte Tecnico</h2>
                </div>
            </nav>
            <div className="flex flex-col h-full justify-center items-center p-7 text-center">
                <p>¿Tienes algún problema, pregunta o sugerencia? Por favor contacta a soporte@meniumapp.com</p>
            </div>
        </div>
    )
}

export default TechnicalSupport
