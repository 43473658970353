import {
    setDoc,
    doc,
    getDoc,
    getFirestore,
    updateDoc,
} from "@firebase/firestore"

class Rate {
    saveRate = async (rateShop, rateApp, comments, ...route) => {
        try {
            //algorithm

            //total user rated : 6
            //sum of max rating of user count: 6 x 5 = 30
            //sum of rating : 25
            //rating = (25 * 5) / 30

            //rate for restaurant
            const docRefRestaurant = doc(getFirestore(), ...route)
            const docSnapRestaurant = await getDoc(docRefRestaurant)

            let dataRestaurant = { ...docSnapRestaurant.data() }

            if (
                dataRestaurant.peopleRated !== undefined &&
                dataRestaurant.sumRating !== undefined
            ) {
                const commentsBefore = dataRestaurant.comments ? dataRestaurant.comments : []
                const payloadToRestaurant = {
                    peopleRated: dataRestaurant.peopleRated + 1,
                    sumRating: dataRestaurant.sumRating + rateShop,
                    comments: [
                        ...commentsBefore,
                        comments.restaurant
                            ? comments.restaurant
                            : "Votación sin comentario de restaurante",
                    ],
                }
                await updateDoc(doc(getFirestore(), ...route), payloadToRestaurant)
            }

            //rate for app
            const docRef = doc(getFirestore(), "App", "Menium")
            const docSnap = await getDoc(docRef)

            let data = { ...docSnap.data() }

            const payloadToApp = {
                peopleRated: data ? data.peopleRated + 1 : 1,
                sumRating: data ? data.sumRating + rateApp : 1,
                comments: [
                    ...data.comments,
                    comments.app ? comments.app : "Votación sin comentario de aplicación",
                ],
            }

            await setDoc(doc(getFirestore(), "App", "Menium"), payloadToApp)

            // const restaurantRef = await
        } catch (error) {
        }
    }
    getRate = async (...route) => {
        const docRefRestaurant = doc(getFirestore(), ...route)

        const docSnapRestaurant = await getDoc(docRefRestaurant)

        let dataRestaurant = { ...docSnapRestaurant.data() }

        const totalUserRated = dataRestaurant.peopleRated

        const maxRaiting = totalUserRated * 5

        const raiting = (dataRestaurant.sumRating * 5) / maxRaiting

        return raiting
    }
}

export default Rate