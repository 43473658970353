import PaymentButtons from "components/global/PaymentButtons"
import React, { useState, useEffect } from "react"
import { numberWithCommas } from "utils/Utils"
import { InputAdornment, OutlinedInput } from "@mui/material"
import Tips from "../../../views/MyAcount2/Components/Tips"
import securePaymentGray from 'assets/images/secure-payment-footer-gray.png'
import CurrencyInput from "react-currency-input-field"
import { useSelector } from 'react-redux'


const PayByExactAmount = ({ payload }) => {

  const spanishText = ["Pagar por importe exacto", "Introduce el importe que deseas pagar:", "Faltaría por pagar:", "Total de la cuenta:", "¿Qué te ha parecido el servicio?"];
  const englishText = ["Pay exact amount", "Enter the amount you'd like to pay:", "Remaining:", "Bill total:", "How was the service?"];

  const language = useSelector(state => state.main.Language);
  const merchantDetails = useSelector(state => state.main.BranchOffice);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const [inputValue, setInputValue] = useState({
    value: 0.00,
    label: "0.00",
  })

  const zeroTip = {
    label: "NONE",
    value: 0.0
  }

  const handleChange = ({ target: { value } }) => {
    try {
      if (value) {
        const convertValue = parseFloat(value)
        const maxValue = (payload.remainingPrice).toFixed(2);

        if (convertValue <= maxValue) {
          setInputValue({ value: convertValue, label: value })
        } else {
          alert("Valor no valido");
        }
      } else {
        setInputValue({ value: 0.00, label: "" })
      }
    } catch (error) {
      alert("Solo se permiten valores númericos");
    }
  }

  const handleAmountChange = (value, name) => {
    if (value == undefined) {
      setInputValue({ value: 0.00, label: "0,00€" });
      return;
    }
    var entryFloat = value.replace(',', '.');
    const entryAmount = parseFloat(entryFloat);

    setInputValue({ value: entryAmount, label: entryAmount + '€' });
  }

  const [valueTip, setValueTip] = useState(zeroTip)
  const [isStaticTip,] = useState(false)
  const handleChangeTip = (el) => {
    if (!isStaticTip) {
      setValueTip(el);
    }
  }

  return (
    <div className="flex flex-col space-y-2 pb-4">
      <h4 className="text-xl font-bold color-title">
        {texts[0]}
      </h4>
      <span className="mt-6 text-center">{texts[1]}</span>
      <div className="flex pt-2 justify-center">
        {/* <OutlinedInput
          inputProps={{ inputMode: "number" }}
          endAdornment={<InputAdornment position="end">€</InputAdornment>}
          onChange={handleChange}
          type="number"
          value={inputValue.label}
          className="w-2/5 h-10 text-center"
          color="primary"
        /> */}
        <CurrencyInput
          className={inputValue.value > 0 ? `amountInputFieldActive` : `amountInputField`}
          id="amount-input"
          name="amount-input"
          placeholder="0,00€"
          decimalsLimit={2}
          decimalSeparator={","}
          groupSeparator={"."}
          disableGroupSeparators={true}
          disableAbbreviations={true}
          maxLength={6}
          minLength={0}
          maxValue={payload.remainingPrice}
          suffix={"€"}
          onValueChange={(value, name) => handleAmountChange(value, name)}
        />
      </div>
      <div className="flex flex-row justify-between py-2">
        <div className="flex flex-col justify-center">
          <span className="font-title-standard text-isNotEnable-primary-color">
            {texts[2]}
          </span>
          <div className="font-title-bold text-center">
            <label className="font-title-bold">
              {
                payload.tip +
                  payload.remainingPrice -
                  inputValue.value ===
                  0
                  ? 0
                  : numberWithCommas(
                    (
                      payload.remainingPrice -
                      inputValue.value
                    ).toFixed(2),
                  )}€
            </label>
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <span className="font-title-standard text-isNotEnable-primary-color">
            {texts[3]}
          </span>
          <div className="font-title-bold text-center">
            {numberWithCommas(
              (payload.totalPrice).toFixed(2),
            )}€
          </div>
        </div>
      </div>
      {!merchantDetails?.isTipDisabled ?
        <h4 className="text-lg color-title font-bold">
          {texts[4]}
          <Tips
            value={valueTip.label}
            paymentAmount={inputValue.value}
            onGetValue={handleChangeTip}
          />
        </h4>
        : null
      }
      <PaymentButtons
        payload={payload}
        amount={inputValue.value}
        active={inputValue.value > 0}
        paymentType={2}
        tipAmountCalculated={valueTip.amount}
      />
      <img className="footer-payment-image-center" src={securePaymentGray}></img>
    </div>
  )
}

export default PayByExactAmount
