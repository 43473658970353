import React from 'react';

const PersonalDataForm = ({
    userData,
    onChange,
    error
}) => {

    return (
        <form
        >
            <div className="pt-2">
                <div className="text-gray-500 text-lg" >
                    Nombre
                </div>
                <div  >
                    <input type="text" placeholder="Introduce tu nombre" className={`w-full h-12	text-xl border-botom-input ${error.name}`}
                        name="name"
                        required
                        value={userData.name}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="pt-2">
                <div className="text-gray-500 text-lg" >
                    Apellidos
                </div>
                <div  >
                    <input type="text" placeholder="Introduce tus apellidos" className={`w-full h-12 text-xl border-botom-input ${error.lastName}`}
                        name="lastName"
                        value={userData.lastName}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="pt-2">
                <div className="text-gray-500 text-lg" >
                    Numero de telefono
                </div>
                <div  >
                    <input type="tel" placeholder="Introduce tu numero" className={`w-full h-12 text-xl border-botom-input ${error.tel}`}
                        name="tel"
                        value={userData.tel}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="pt-2">
                <div className="text-gray-500 text-lg" >
                    Fecha de nacimiento
                </div>
                <div  >
                    <input type="date" placeholder="Introduce tu fecha de nacimiento" className={`w-full h-12 text-xl border-botom-input ${error.date_of_birth}`}
                        name="date_of_birth"
                        value={userData.date_of_birth}
                        onChange={onChange}
                    />
                </div>
            </div>
        </form>
    )
}

export default PersonalDataForm