import { Order } from "firebase/order"
import React, { useState } from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import ConnectToRedux from "components/global/ConnectToRedux"

const Receipt = ({ payment }) => {
  const initConfig = {
    email: "",
    name: "",
    nameBussines: "",
    nameEmisor: "",
    document: "",
  }

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [configEmail, setConfigEmail] = useState(initConfig)
  const [loadingEmail, setLoadingEmail] = useState(false)

  const orderClass = new Order()

  const handleChangeInputs = ({ target: { value, name } }) =>
    setConfigEmail({ ...configEmail, [name]: value })

  const handleChangeStateModal = () => setIsOpenModal(!isOpenModal)

  const sendEmail = async () => {
    setLoadingEmail(true)
    const res = await orderClass.sendReceipt(configEmail.email, payment)

    if (res.data.success) {
      alert("Correo enviado")
      handleChangeStateModal()
      setConfigEmail(initConfig)
    } else {
      alert("Error al enviar el correo")
    }
    setLoadingEmail(false)
  }

  return (
    <>
      <div
        onClick={handleChangeStateModal}
        className="flex justify-center items-center flex-col text-center bg-purple-900 border-white border-1 rounded-xl"
      >
        <p className="text-white font-bold">Enviar recibo</p>
      </div>
      <SwipeableDrawer
        open={isOpenModal}
        onClose={handleChangeStateModal}
        anchor="bottom"
        PaperProps={{
          square: false,
          style: {
            borderTopRightRadius: "1rem",
            borderTopLeftRadius: "1rem",
          },
        }}
      >
        <>
          <div className="flex flex-col bg-gradient-menium space-y-5 pb-8 px-4 py-6">
            <div className="flex flex-row justify-between">
              <label className="text-white">Enviar mi recibo</label>
              <button
                onClick={handleChangeStateModal}
                className="text-isNotEnable-primary-color"
              >
                Cancelar
              </button>
            </div>
            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <label className="text-purple-300">
                Introduce tu correo electrónico
              </label>
              <input
                type="email"
                className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                placeholder="Correo electronico"
                onChange={handleChangeInputs}
                name="email"
                value={configEmail.email}
              />
            </div>
            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <label className="text-purple-300">
                Nombre y apellidos (o razón social)
              </label>
              <input
                className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                placeholder="Nombre"
                type="text"
                onChange={handleChangeInputs}
                name="name"
                value={configEmail.name}
              />
            </div>
            <div className="text-purple-300 font-bold">Para empresa</div>
            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <input
                className="border-0 py-2 bg-transparent text-white focus:border-0 focus:outline-none"
                placeholder="Nombre de la empresa"
                type="text"
                onChange={handleChangeInputs}
                name="nameBussines"
                value={configEmail.nameBussines}
              />
            </div>
            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <input
                className="border-0 py-2 bg-transparent text-white focus:border-0 focus:outline-none"
                placeholder="Domicilio del emisor"
                type="text"
                onChange={handleChangeInputs}
                name="nameEmisor"
                value={configEmail.nameEmisor}
              />
            </div>
            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <input
                className="border-0 py-2 bg-transparent text-white focus:border-0 focus:outline-none"
                placeholder="CIF/NIF"
                inputMode="numeric"
                type="number"
                pattern="[0-9]*"
                onChange={handleChangeInputs}
                name="document"
                value={configEmail.document}
              />
            </div>
            <button
              onClick={loadingEmail ? null : sendEmail}
              className="rounded-lg bg-gradient-menium border-2 border-white py-3 text-white"
            >
              {loadingEmail ? "Enviando..." : "Enviar mi recibo"}
            </button>
          </div>
        </>
      </SwipeableDrawer>
      {/* <BottomDialog
                content={
                     */}
    </>
  )
}

export default ConnectToRedux(Receipt, "App")
