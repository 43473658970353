import React, { useEffect, useState } from "react"
import ConnectToRedux from "components/global/ConnectToRedux"
import Tips from "../MyAcount2/Components/Tips"
import Modal from "components/global/Modal"
import { container } from "Global"
import Share from "assets/icons/share.png"
import Navbar from "components/OnlyPayment/Navbar"
import Loader from "components/Loader/Loader"
import List from "components/OnlyPayment/List"
import OpenOrder from './Sections/OpenOrder'
import useGetTable from "hooks/useGetTable"
import useGetTableQr from "hooks/useGetTableQr"
import { OnlyPaymentService } from "Service/OnlyPayment"
import Cookies from "js-cookie"
import useGetTableById from "hooks/useGetTableById"
import { useHistory } from "react-router-dom"
import camareroLoading from "../../assets/images/camarero-azul.gif"
import { useSelector } from 'react-redux'

const tipsCustom = [
    {
        label: "0%",
        value: { isPercent: true, value: 0 },
    },
    {
        label: "5%",
        value: { isPercent: true, value: 5 },
    },
    {
        label: "10%",
        value: { isPercent: true, value: 10 },
    },
    {
        label: "15%",
        value: { isPercent: true, value: 15 },
    },
]

const OnlyPayment = ({
    Mode,
    BranchOffice,
    Commerce,
    setBranchOffice
}) => {
    const [payload, setPayload] = useState({
        totalPrice: 0,
        items: [],
        tip: 0,
        note: "",
    })

    const spanishText = ["Haz tu pedido al camarero, o espera un momento a que se actualice la cuenta"];
    const englishText = ["Order from your waiter, or wait a moment for the bill to update"];

    const language = useSelector(state => state.main.Language);

    const [texts, setTexts] = useState(spanishText);

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const router = useHistory()

    const [infoTable, setInfoTable] = useState({
        loading: true,
        data: null
    })

    const [valueTip, setValueTip] = useState(tipsCustom[0])
    const [isStaticTip,] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [verifyModal,] = useState({
        open: false,
        title: "",
        description: "",
        showCloseIcon: true,
    })
    const [note, setNote] = useState("");

    // const { isLoading: loading, loadingSuccess: loadingTable, data: data } = useGetTableQr(Commerce.commerceId, BranchOffice.branchOfficeId, BranchOffice.table.tableName)
    const { isLoading: loading, data: data, error: error } = useGetTableById(localStorage.getItem('tableId'));

    const [updateTime, setUpdateTime] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleChangeTip = (el) => {
        if (!isStaticTip) {
            setValueTip(el)
        }
    }

    const CleanShoppingCart = () => {
        alert("No podemos eliminar pedidos ya servidos o en preparación")
    }

    const handleChangeNote = (event) => {
        const value = event.target.value;

        if (value.length <= 100) {
            setNote(value)
        }
    }

    const handleChangeStateModal = () => setOpenModal(!openModal)

    const saveNote = () => {
        setPayload({ ...payload, note })
        handleChangeStateModal()
    }

    const shareCommerce = () => {
        let rute = `verify-mode/${Commerce.NO_ID_FIELD}/${BranchOffice.NO_ID_FIELD}`

        if (Mode !== "isTakeAway") {
            rute += `/${BranchOffice.table.NO_ID_FIELD}/${Mode}`
        }

        navigator.share({
            title: "Menium App",
            text: "Pide, Paga y Vete",
            url: rute,
        })
    }

    useEffect(() => {

        if (!loading) {
            if (BranchOffice != null && BranchOffice != undefined && data != null && data != undefined) {
                localStorage.setItem("branch_office", JSON.stringify({ ...BranchOffice, table: data }));
            }
            setBranchOffice({ ...BranchOffice, table: data })
            setPayload(data)
            setInfoTable({ loading: false, data })
            setIsActive(true);
            Cookies.set("branch_office", JSON.stringify({ ...BranchOffice, table: data }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data])

    useEffect(() => {
        const updateTableData = () => {
            let interval = null;
            if (isActive && !loading) {
                if (!isUpdating) {

                    var tableId = localStorage.getItem('tableId');
                    if (!tableId || tableId == undefined) {
                        clearInterval(interval);
                        router.push("/closed");
                        return () => clearInterval();
                    }

                    setIsUpdating(true);
                    interval = setInterval(() => {
                        if (!loading) {
                            // const tableRequest = {
                            //     userIdentifier: Cookies.get("anonGuid"),
                            //     commerceId: Commerce.commerceId,
                            //     branchOfficeId: BranchOffice.branchOfficeId,
                            //     tableCode: BranchOffice.table.tableName
                            // };

                            OnlyPaymentService.getTableUsingId(tableId).then(tableData => {
                                // setBranchOffice({ ...BranchOffice, table: tableData })

                                if (tableData.isOpen == false) {
                                    clearInterval(interval);
                                    router.push("/closed");
                                    return () => clearInterval(interval);
                                }

                                setPayload(tableData);
                                setInfoTable({ loading: false, data: tableData });
                                localStorage.setItem("branch_office", JSON.stringify({ ...BranchOffice, table: tableData }));
                                Cookies.set("branch_office", JSON.stringify({ ...BranchOffice, table: tableData }));
                            }, function (error) {
                                console.log("ERROR");
                            });
                        }
                        setIsUpdating(false);
                    }, 10000);
                }

            } else if (!isActive && updateTime !== 0) {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }

        updateTableData();
    }, [isActive]);

    return (
        <div className="overflow-hidden h-full flex flex-col" style={container}>
            {
                infoTable.loading ? (
                    <Loader />
                ) : (
                    <>
                        <Navbar tableName={infoTable.data.tableName} branchOfficeId={infoTable.data.branchOfficeId} />

                        <div className="overflow-hidden flex flex-col flex-1 ">
                            <div className="flex-1 overflow-y-auto mt-14 h-2/5">
                                {
                                    payload?.items.length > 0 ? (
                                        <List items={payload.items} />
                                    ) : (
                                        <div className="w-full h-full flex flex-col justify-center items-center text-center px-8 text-isNotEnable-primary-color text-base ">
                                            <img src={camareroLoading} className="camareroloading"></img>
                                            {/* <p>Todavía no se han añadido productos a tu cuenta. Haz tu pedido al camarero.</p> */}
                                            <p className="text-color-menium">{texts[0]}</p>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="main-view-border-rounded">
                                {/* <div className="grid-config justify-between items-center pt-3 px-2">
                                    <button className="p-1 border-2 rounded-full h-full w-full text-xs flex justify-center items-center">
                                        Añadir Promoción
                                    </button>
                                    <button
                                        onClick={shareCommerce}
                                        className="p-1 border-2 rounded-full text-xs w-full h-full flex justify-center items-center"
                                    >
                                        <img
                                            src={Share}
                                            className="text-black font-bold h-5"
                                            alt="Compartir"
                                        />
                                    </button>
                                    <button
                                        onClick={handleChangeStateModal}
                                        className="p-1 border-2 rounded-full w-full h-full text-xs flex justify-center items-center"
                                    >
                                        Nota cocina
                                    </button>

                                    <button
                                        className="text-red-600 text-xs h-full"
                                        onClick={CleanShoppingCart}
                                    >
                                        Eliminar todo
                                    </button>
                                </div> */}

                                {/* <Tips
                                    tips={tipsCustom}
                                    value={valueTip.label}
                                    onGetValue={handleChangeTip}
                                /> */}
                                <OpenOrder
                                    payload={payload}
                                    tip={valueTip}
                                />
                            </div>
                        </div>
                    </>
                )
            }
            <Modal
                open={verifyModal.open}
                onClose={() => { }}
                showCloseIcon={verifyModal.showCloseIcon}
            >
                <div className="bg-gradient-meniumtext-white opacity-25 w-full h-full z-10 " />
                <div className="bg-gradient-menium text-white rounded-lg p-4">
                    <div className="items-center">
                        <div className="rounded-full flex items-center justify-center ">
                            <i className="bx bx-error text-3xl" />
                        </div>
                        <div className=" md:mt-0 md:ml-6 text-center md:text-left">
                            <p className="text-lg text-left">{verifyModal.title}</p>
                            <p className="text-sm mt-3 text-left">
                                {verifyModal.description}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={openModal}
                onClose={handleChangeStateModal}
            >
                <div className="space-y-6 flex flex-col py-16 px-8">
                    <span className="font-extrabold">Nota a cocina</span>
                    <textarea
                        onChange={handleChangeNote}
                        value={note}
                        maxLength={100}
                        placeholder="Usa este espacio para mandarle una petición concreta al chef"
                    />
                    <button
                        className="bg-gradient-menium text-white p-2 rounded-full max-w-sm w-full"
                        onClick={saveNote}
                    >
                        Guardar
                    </button>
                </div>
            </Modal>
        </div>
    )
}


export default ConnectToRedux(OnlyPayment, [
    "Commerce",
    "BranchOffice",
])