import React from "react"
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "react-spring-bottom-sheet/dist/style.css"
import X from "assets/icons/XIcon.png"
import securePaymentGray from 'assets/images/secure-payment-footer-gray.png'

const BottomDialog = ({
  open,
  onHandle,
  content,
  backgroundColor,
  showClose = true,
}) => {
  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onHandle}
        PaperProps={
          {
            className: "rounded-t-3xl pt-3"
          }
        }
      >
        {showClose && (
          <div className={`flex pr-3 justify-end`}>
            <img
              onClick={onHandle}
              src={X}
              height={30}
              width={30}
              alt="Close icon"
            />
          </div>
        )}
        <div className={`flex flex-col px-6 ${backgroundColor} overflow-y-scroll`}>

          <content.type {...content.props} open={open} />
        </div>
      </SwipeableDrawer>
    </>
  )
}
export default BottomDialog
