import React, { useEffect, useState } from "react"
import { ItemList } from "./Items/ItemList"
import { MenyLanguaje } from "./Items/MenuLanguaje"
import { useHistory } from "react-router"
import ButtonLogin from "./Items/ButtonLogin"
import { useSelector } from 'react-redux'
//ICONS
import user from "../../../assets/icons/user.svg"
import invoice from "../../../assets/icons/invoice.svg"
import heart from "assets/icons/heart.svg"
import creditCard from "assets/icons/credit-card.svg"
import gift from "assets/icons/gift.svg"
import request from "assets/icons/request copy.svg"
import customerService from "assets/icons/customer-service copy.svg"
import M from "assets/icons/meniumMicon.png"
import { Link } from "react-router-dom"

export const MenuDrawer = () => {
  const history = useHistory()

  const spanishText = ["Mi cuenta", "Mi información", "Mi historial de pedidos", "Mis favoritos", "Metodos de pago", "!Comparte y gana!", "Preguntas frecuentes", "Soporte Tecnico", "Sobre Menium", "Políticas legales"];
  const englishText = ["My account", "My information", "My order history", "My favorites", "Payment methods", "Share and win!", "FAQ", "Technical support", "About Menium", "Legal policies"];

  const language = useSelector(state => state.main.Language);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  return (
    <>
      <div className="px-4">
        {/* <h2 className="text-black text-xl font-bold">Idioma</h2>

        <div className="-ml-1">
          <MenyLanguaje />
        </div> */}

        <h2 className="text-black text-2xl font-extrabold">{texts[0]}</h2>
      </div>
      <div className="separator"></div>

      <ItemList
        icon={user}
        label={texts[1]}
        onClick={() => history.push("/my-information")}
      ></ItemList>
      <ItemList
        icon={invoice}
        label={texts[2]}
        onClick={() => history.push("/score")}
      ></ItemList>
      <ItemList
        icon={heart}
        label={texts[3]}
        onClick={() => history.push("/my-favorite")}
      ></ItemList>
      <ItemList
        icon={creditCard}
        label={texts[4]}
        onClick={() => history.push("/payment-methods")}
      ></ItemList>
      <ItemList icon={gift} label={texts[5]}></ItemList>
      <ItemList icon={request} label={texts[6]}></ItemList>
      <ItemList
        icon={customerService}
        label={texts[7]}
        onClick={() => history.push("/technical-support")}
      ></ItemList>
      <ItemList
        className="bg-menium-gradient"
        icon={M}
        label={texts[8]}
      ></ItemList>

      <ButtonLogin redirect={() => history.push("/login")}></ButtonLogin>
      <div className="text-sm px-4 text-color-menium">
        <Link to="/legal_policies">{texts[9]}</Link>
      </div>
    </>
  )
}
