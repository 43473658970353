import PaymentButtons from "components/global/PaymentButtons"
import React, { useState, useEffect } from "react"
import { numberWithCommas } from "utils/Utils"
import Tips from "../../../views/MyAcount2/Components/Tips"
import securePaymentGray from 'assets/images/secure-payment-footer-gray.png'
import { useSelector } from 'react-redux'

const zeroTip = {
  label: "NONE",
  value: 0.0
}

const PayTheTotal = ({ payload }) => {
  // const newPayload = {
  //   ...payload,
  //   paidOut: payload.total + payload.tip,
  //   modeOpenOrder: "PayTheTotal",
  //   usePayPerProducts: false,
  // }

  const spanishText = ["Pagar el total", "Pagado:", "A pagar:", "Total cuenta:", "¿Qué te ha parecido el servicio?"];
  const englishText = ["Pay the total", "Paid:", "Payable:", "Bill total:", "How was the service?"];

  const language = useSelector(state => state.main.Language);
  const merchantDetails = useSelector(state => state.main.BranchOffice);
  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const [valueTip, setValueTip] = useState(zeroTip)
  const [isStaticTip,] = useState(false)
  const handleChangeTip = (el) => {
    if (!isStaticTip) {
      setValueTip(el)
    }
  }

  return (
    <div className="flex flex-col space-y-4 pb-4">
      <h4 className="text-xl font-bold color-title">
        {texts[0]}
      </h4>
      <div className="flex justify-between">
        <div className="flex flex-col justify-center text-center">
          <span className="font-title-standard text-isNotEnable-primary-color">{texts[1]}</span>
          <h2 className="font-title-bold text-black productPaymentSpacerBorder">
            {numberWithCommas(payload.totalPrice - payload.remainingPrice)} €
          </h2>
        </div>
        <div
          className="flex flex-col px-4 py-2 rounded justify-center text-center"
        >
          <span className="font-title-standard text-isNotEnable-primary-color">{texts[2]}</span>
          <h2 className="color-title font-title-bold productPaymentAmountBorder">
            {numberWithCommas(
              (payload.remainingPrice),
            )}{" "}
            €
          </h2>
        </div>
        <div className="flex flex-col justify-center text-center">
          <span className="font-title-standard text-isNotEnable-primary-color">{texts[3]}</span>
          <h2 className="font-title-bold text-black productPaymentSpacerBorder">
            {numberWithCommas((payload.totalPrice))} €
          </h2>
        </div>
      </div>
      {!merchantDetails?.isTipDisabled ?
        <h4 className="text-lg color-title font-bold">
          {texts[4]}
          <Tips
            value={valueTip.label}
            paymentAmount={payload.remainingPrice}
            onGetValue={handleChangeTip}
          />
        </h4>
        : null
      }
      <PaymentButtons
        payload={payload}
        amount={payload.remainingPrice}
        active={payload.remainingPrice !== 0}
        paymentType={0}
        tipAmountCalculated={valueTip.amount}
      />
      <img className="footer-payment-image-center" src={securePaymentGray}></img>
    </div >
  )
}

export default PayTheTotal
