import React from 'react'
import ConnectToRedux from 'components/global/ConnectToRedux'
import { useGetCollection } from 'hooks/useGetCollection'
import Items from './Items'

const InOrder = ({ Commerce, BranchOffice, showAlert }) => {

    const ref = [
        "Shops",
        Commerce.NO_ID_FIELD,
        "BranchOffices",
        BranchOffice.NO_ID_FIELD,
        "Tables",
        BranchOffice.table.NO_ID_FIELD,
        "Order"
    ]

    const [, order] = useGetCollection(ref);

    const idOrder = order[0] ? order[0].NO_ID_FIELD : 'B';

    const [loadingDefaults, defaults] = useGetCollection([...ref, idOrder, "defaults"])

    const [loadingCustoms, customs] = useGetCollection([...ref, idOrder, "custom"])

    return (
        <>
            {
                loadingDefaults === "success" && loadingCustoms === "success" && (
                    <>
                        {
                            (defaults.length > 0 || customs.length > 0) ? (
                                <>
                                    {
                                        defaults && defaults.map(
                                            (product, i) => <Items product={product} defaultType={true} key={`In_Order_Defaults_${i}`} />
                                        )
                                    }

                                    {
                                        customs && customs.map(
                                            (product, i) => <Items product={product} defaultType={false} key={`In_Order_Customs_${i}`} />
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        showAlert && (
                                            <div className="w-full h-full flex flex-col justify-center items-center text-center px-16 text-isNotEnable-primary-color text-base ">
                                                <p>Todavía no has añadido productos a la cuenta</p>
                                                <p className="mt-6">Vuelve al menú para añadir productos</p>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default ConnectToRedux(InOrder, ["Commerce", "BranchOffice"])
