import loadingQR from 'assets/images/loading-blue-spinner.gif'

const Loader = () => {
    return (
        <div className='flex loaderqr'>
            <img src={loadingQR} className="h-28 mt-20"></img>
        </div>
    );
};

export default Loader;