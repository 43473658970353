import ConnectToRedux from "components/global/ConnectToRedux"
import { useGetDoc } from "hooks/useGetDoc"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Cookies from "js-cookie"
import loadingQR from "assets/images/loading-white-spinner.gif"
import MeniumLogo from 'assets/images/logo-menium-white-full_smaller.png'
import { container } from "Global"
import useGetTableQr from "hooks/useGetTableQr"
import useGetAuthToken from "hooks/useGetAuthToken"
import useGetMerchantDetails from "hooks/useGetMerchantDetails"
import { OnlyPaymentService } from "Service/OnlyPayment"
import { v4 as uuidv4 } from 'uuid';

const VerifyQROnlyPayment = ({
    setMode,
    setBranchOffice,
    setCommerce
}) => {

    const { idCommerce, idBranchOffice, idTable } = useParams()
    const router = useHistory()
    const { isLoading: isMerchantLoading, data: merchantData, error: merchantLoadError } = useGetMerchantDetails(idBranchOffice);
    const [isNotFoundQR, setIsNotFoundQR] = useState(false);
    const [dataTable, setDataTable] = useState({});

    useEffect(() => {
        let isSubscribed = true;
        const getData = async () => {
            if (merchantData != undefined && merchantData != null && merchantData.commerceId != null && merchantData.commerceId != undefined) {
                var userGuid = Cookies.get("anonGuid");
                if (!userGuid || userGuid == undefined) {
                    userGuid = uuidv4();
                    Cookies.set("anonGuid", userGuid);
                }
                var userToken = "";
                const anonAuthRequest = {
                    anonGuid: userGuid,
                    commerceId: merchantData.commerceId,
                    branchOfficeId: merchantData.branchOfficeId,
                    tableName: idTable
                };
                userToken = await OnlyPaymentService.getAnonToken(anonAuthRequest);

                if (userToken) {
                    Cookies.set("anonToken", userToken.token);
                    // Now, lets deal with opening table QR

                    const tableRequest = {
                        userIdentifier: userGuid,
                        commerceId: merchantData.commerceId,
                        branchOfficeId: merchantData.branchOfficeId,
                        tableCode: idTable
                    };
                    const resp = await OnlyPaymentService.getTableFromQr(tableRequest);
                    localStorage.setItem('tableId', resp.tableId);

                    if (isSubscribed) {
                        setDataTable({ ...resp });
                    }
                }
                else {
                    console.log("No user token...");
                }
            }
        }
        getData();

        return () => isSubscribed = false;

    }, [merchantData])

    useEffect(() => {
        if (dataTable != null && dataTable.tableId != undefined && dataTable.tableId != null && merchantData != undefined && merchantData != null && merchantData.branchOfficeId != undefined) {
            Cookies.set("commerce", JSON.stringify(merchantData), { expires: 1 })
            localStorage.setItem("commerce", JSON.stringify(merchantData));
            Cookies.set(
                "branch_office",
                JSON.stringify({ ...merchantData, table: dataTable }),
                { expires: 1 },
            )
            localStorage.setItem("branch_office", JSON.stringify({ ...merchantData, table: dataTable }));
            Cookies.set('mode', "isOpenOrder", { expires: 1 });
            localStorage.setItem("mode", "isOpenOrder");
            setCommerce({
                ...merchantData
            })
            setBranchOffice({
                ...merchantData,
                table: dataTable,
            })
            setMode("isOpenOrder")
            if (merchantData.branchOfficeId == "272a80ae-b033-45f1-ab7c-9026f183c4cf") {
                setTimeout(() => router.push("/only-payment"), 200);
            } else {
                setTimeout(() => router.push("/view_and_pay"), 200);
            }
        }
    }, [dataTable, merchantData])

    return (
        <div
            className="flex justify-center items-center flex-col"
            style={{ height: window.innerHeight, width: window.innerWidth, backgroundColor: '#24146a', backgroundImage: `url(${merchantData?.branchOfficeId == "272a80ae-b033-45f1-ab7c-9026f183c4cf" ? merchantData?.backgroundImageNoLogoUrl : "https://res.meniumapp.com/restaurantimages/ochentagradosmalasana_background.jpg"})`, backgroundPosition: 'center', backgroundSize: 'auto 100%' }}
        >
            {
                !isNotFoundQR ? (
                    <>
                        <img
                            src={MeniumLogo}
                            alt="Menium Logo"
                            className="full-width-logo"
                        />
                        <img src={loadingQR} alt="QR" className="h-28 mt-20" />
                        <h1 className="text-white text-2xl">Preparando tu mesa</h1>
                    </>
                ) : (
                    <>
                        {
                            !dataTable?.isOpen ? (
                                <h1 className="text-white text-2xl">Mesa cerrada</h1>
                            ) : (
                                <h1 className="text-white text-2xl">QR no valido</h1>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default ConnectToRedux(VerifyQROnlyPayment, ["Commerce", "BranchOffice", "Mode"])