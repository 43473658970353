import { container } from 'Global'
import React, { useEffect, useState } from 'react'
import ConnectToRedux from 'components/global/ConnectToRedux'
import { numberWithCommas } from 'utils/Utils'
import { useHistory } from 'react-router-dom'
import MeniumLogo from 'assets/images/logo-menium-white-full_smaller.png'
import useGetTable from 'hooks/useGetTable'
import useGetTableQr from 'hooks/useGetTableQr'
import spanishicon from 'assets/icons/spanishicon.png'
import englishicon from 'assets/icons/englishicon.png'
import meniumlogo from 'assets/images/meniumlogo-white.png'
import useGetTableById from 'hooks/useGetTableById'
import securePayment from 'assets/images/secure-payment-footer.png'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from 'storage/Actions/main'

const ViewAnPay = ({ Commerce, BranchOffice }) => {

    const router = useHistory();

    // const { isLoading: isTableLoading, loadingSuccess: loadingTable, data: dataTable } = useGetTableQr(Commerce.commerceId, BranchOffice.branchOfficeId, BranchOffice.table.tableName)
    const { isLoading: isTableLoading, loadingSuccess: loadingTable, data: dataTable } = useGetTableById(BranchOffice.table.tableId);

    const [orderTotal, setOrderTotal] = useState("0");

    const handleClickViewAndPay = () => router.push("/only-payment");
    const handleClickShowMenu = () => router.push("/view_menu");

    const language = useSelector(state => state.main.Language);
    const dispatch = useDispatch();

    const spanishText = ["Mesa: ", "Ver la carta", "Ver y pagar la cuenta: "];
    const englishText = ["Table: ", "View the menu", "View and pay the bill: "];

    const [texts, setTexts] = useState(spanishText);
    const [showicon, setIcon] = useState(englishicon);
    const [isSpanish, setIsSpanish] = useState(true);

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    useEffect(() => {

        if (BranchOffice && BranchOffice.noShowMenuViewAndPage) {
            router.push("/view_menu")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BranchOffice])

    useEffect(() => {
        if (!isTableLoading && dataTable != null && dataTable.remainingPrice != null) {

            setOrderTotal(numberWithCommas(dataTable.remainingPrice))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTableLoading])

    const languageClick = () => {
        if (isSpanish === true) {
            setTexts(englishText);
            setIcon(spanishicon);
            setIsSpanish(false);
        } else {
            setTexts(spanishText);
            setIcon(englishicon);
            setIsSpanish(true);
        }
    }

    const handleLanguageChange = (event) => {
        const value = event.target.value;
        dispatch(setLanguage(value));
        if (value === 0) {
            setTexts(spanishText);
        } else if (value === 1) {
            setTexts(englishText);
        }
    }

    if (BranchOffice && !BranchOffice.noShowMenuViewAndPage && !BranchOffice.backgroundImageUrl) {
        return (
            <div className="flex justify-center items-center flex-col bg-gradient-menium" style={container}>
                <img
                    src={MeniumLogo}
                    alt="Menium Logo"
                    className="full-width-logo"
                />

                <div className="space-y-4 flex justify-center flex-col mt-14">
                    <button
                        className="bg-white p-2 rounded-lg flex flex-col text-center items-center"
                        onClick={handleClickShowMenu}
                    >
                        <label className="text-color-menium">Ver la carta</label>
                    </button>
                    <button
                        className="bg-white text-black p-2 rounded-lg flex flex-col text-center items-center"
                        onClick={handleClickViewAndPay}
                    >
                        <label className="text-color-menium">Ver y pagar la cuenta:</label>
                        <label className="text-isNotEnable-primary-color">{orderTotal}€</label>
                    </button>
                </div>
            </div>
        )
    }
    else if (BranchOffice && !BranchOffice.noShowMenuViewAndPage && BranchOffice.backgroundImageUrl) {
        return (
            <div className="flex justify-end items-center flex-col" style={{ height: window.innerHeight, width: window.innerWidth, backgroundColor: '#24146a', backgroundImage: `url(${BranchOffice.backgroundImageUrl})`, backgroundPosition: 'center', backgroundSize: 'auto 100%' }}>
                <div className="meniumtoplogo"><img src={meniumlogo}></img></div>
                {/* <div className="countryflagbutton" onClick={languageClick}><img src={showicon}></img></div> */}
                <FormControl className="languageSelectorForm" sx={{ m: 1, pt: 0, pb: 0, pr: 0, pl: 0, minWidth: 30, border: 1, borderColor: "#ffffff !important", borderRadius: 3 }} size="small" margin="dense" hiddenLabel="true">
                    <Select
                        sx={{ pt: 0, pb: 0, pr: 0, pl: 0 }}
                        className="languageSelector"
                        labelId="language-select-label"
                        id="language-select"
                        value={language}
                        onChange={handleLanguageChange}
                    >
                        <MenuItem value={0}>Español</MenuItem>
                        <MenuItem value={1}>English</MenuItem>
                    </Select>
                </FormControl>
                <div className="space-y-6 flex flex-col mb-15">
                    <div className="flex flex-col text-center items-center"><h1 className="tablecodetitle">{texts[0]} {dataTable?.tableName}</h1></div>
                    {!BranchOffice.isOnlyPayment ?
                        <button
                            className="bg-white p-2 rounded-lg rounded-full fullwidth-button flex flex-col text-center items-center"
                            onClick={handleClickShowMenu}
                        >
                            <label className="text-isNotEnable-primary-color">{texts[1]}</label>
                        </button>
                        : null
                    }
                    <button
                        className="bg-white text-black p-2 rounded-full fullwidth-button flex flex-col text-center items-center"
                        onClick={handleClickViewAndPay}
                    >
                        <label className="text-isNotEnable-primary-color">{texts[2]}</label>
                        <label className="text-color-menium">{orderTotal}€</label>
                    </button>
                </div>
                <img className="footer-payment-image" src={securePayment}></img>
            </div>
        )
    }

    return null
}

export default ConnectToRedux(ViewAnPay, ["Commerce", "BranchOffice"])