import LogoMenium from "assets/images/LOGO-BLANCO.png"
const NoPhone = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center bg-gradient-menium">
      <div className="items-center flex flex-col mb-10">
        <img src={LogoMenium} alt="Logo" />
      </div>

      <div
        className="bg-purple-500 p-8 flex flex-col items-center text-white"
        style={{ borderRadius: "20px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-14 mb-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>

        <p className="text-white">Para acceder debes ingresar desde tu movil</p>
      </div>
    </div>
  )
}

export default NoPhone
