import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { store } from "storage/storage"
import { FirebaseAppProvider } from "reactfire"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "assets/Material/Theme"

import config from "./config.json"

const environment = process.env.STAGE_ENVIRONMENT || "development"

const { firebase } = config[environment]

ReactDOM.render(
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebase}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </FirebaseAppProvider>
  </Provider>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
