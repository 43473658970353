import { addUser, removeUser } from 'storage/Actions/main'

const mapStateToProps = state => ({
    User: state.main.User
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        addUser,
        removeUser
    }
}

export default sendInfo