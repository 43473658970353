import { getFunctions, httpsCallable } from "@firebase/functions"

const { doc, setDoc, collection, deleteDoc, addDoc, getDocs } = require("@firebase/firestore");
const { getTotalInShoppingCart } = require("utils/Utils")

class ToPay {

    constructor(firestore, func) {
        this.initStoreApp = firestore;

        if (func) {
            this.initFuncApp = getFunctions(func);
        }
    }

    setShoppingCart = async (ShoppingCart, before, ref) => {

        const defaults = ShoppingCart.defaults.map(current => {

            const quantity = before.defaults[current.index].quantity - current.quantity

            return { ...current, quantity, NO_ID_FIELD: before.defaults[current.index].NO_ID_FIELD }
        }, [])

        const custom = ShoppingCart.custom.map(current => {

            const quantity = before.custom[current.index].quantity - current.quantity

            return { ...current, quantity, NO_ID_FIELD: before.custom[current.index].NO_ID_FIELD }
        }, [])

        await defaults.forEach(async product => {
            const refDoc = doc(this.initStoreApp, ...ref, 'defaults', product.NO_ID_FIELD)

            if (product.quantity !== 0) {
                await setDoc(refDoc, product);
            }
            else {
                await setTimeout(async () => await deleteDoc(refDoc), 1000)
            }
        });

        custom.forEach(async product => {
            const refDoc = doc(this.initStoreApp, ...ref, 'custom', product.NO_ID_FIELD)

            if (product.quantity !== 0) {
                await setDoc(refDoc, product);
            }
            else {
                await setTimeout(async () => await deleteDoc(refDoc), 1000)
            }

        });

        return defaults
    }

    updateStateOrder = async (payload, ref) => {

        const refElement = await doc(this.initStoreApp, ...ref);

        await setDoc(refElement, payload);

        return true
    }

    getQuantity = async (refCollection) => {

        let cont = 0;

        const querySnapshot = await getDocs(refCollection);

        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            cont++
        });

        return cont
    }

    setGlobalOrder = async (payload) => {

        const ref = collection(this.initStoreApp, 'orders')

        const numberOrder = await this.getQuantity(ref)

        const { id } = await addDoc(ref, { ...payload, orderId: numberOrder + 1 });


        return { id, numberOrder }
    }

    byProduct = async (payload, products, beforeProducts, ref) => {

        const totalByAllProducts = getTotalInShoppingCart(products);
        const copyPayload = { ...payload, paidOut: payload.paidOut + totalByAllProducts }

        await this.updateStateOrder(copyPayload, ref);

        await this.setShoppingCart(products, beforeProducts, ref)

        return copyPayload
    }

    getRefence = async ({ commerce, branchOffice, idTable }) => {

        const refCollection = ['Shops', commerce, 'BranchOffices', branchOffice, "Tables", idTable, "Order"]

        const ref = collection(
            this.initStoreApp,
            ...refCollection
        )

        const querySnapshot = await getDocs(ref);

        let idRef = null

        querySnapshot.forEach((doc) => {
            idRef = doc.id
        });

        return [...refCollection, idRef]

    }

    otherMethod = async (payload) => {

        try {
            const ref = await this.getRefence(payload)

            const table = payload.idTable

            delete payload.idTable

            await this.updateStateOrder(payload, ref)

            if (payload.paidOut - (payload.total + payload.tip) === 0) {

                const refCollection = ['Shops', payload.commerce, 'BranchOffices', payload.branchOffice, "Tables", table, "Order"]

                const deleteCollection = httpsCallable(this.initFuncApp, "deleteCollection");

                deleteCollection({ ref: refCollection })

            }
        } catch (error) {
            alert(error)
        }
    }

}

export default ToPay