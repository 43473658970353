import ConnectToRedux from 'components/global/ConnectToRedux'
import { container } from 'Global'
import useGetTableQr from "hooks/useGetTableQr"
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from 'utils/Utils';
import { Link } from 'react-scroll';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import englishicon from 'assets/icons/englishicon.png';
import spanishicon from 'assets/icons/spanishicon.png';
import useGetTableById from 'hooks/useGetTableById'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from 'storage/Actions/main'

const ViewMenuPDF = ({ BranchOffice, Commerce }) => {

    // const { isLoading: loadingTable, loadingSuccess: loadingSuccess, data: dataTable } = useGetTableQr(Commerce.commerceId, BranchOffice.branchOfficeId, BranchOffice.table.tableName)
    const { isLoading: isTableLoading, loadingSuccess: loadingTable, data: dataTable } = useGetTableById(BranchOffice.table.tableId);

    const router = useHistory()

    const [orderTotal, setOrderTotal] = useState("0");
    const [iframeShow, setIframeShow] = useState("")

    const language = useSelector(state => state.main.Language);
    const dispatch = useDispatch();

    const spanishText = ["Carta", "Ver y pagar la cuenta: ", "Volver", "Cuenta"];
    const englishText = ["Menu", "View and pay the bill: ", "Back", "Bill"];
    const [texts, setTexts] = useState(spanishText);
    const [icon, setIcon] = useState(englishicon);
    const languageTranslation = ["es", "en"];

    const handleClick = () => router.push("/only-payment");

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const handleLanguageChange = (event) => {
        const value = event.target.value;
        dispatch(setLanguage(value));
        setLanguage(event.target.value);
        if (value === 0) {
            setTexts(spanishText);
        } else if (value === 1) {
            setTexts(englishText);
        }
    }

    useEffect(() => {
        if (BranchOffice != null && BranchOffice.showMenu) {
            setIframeShow(BranchOffice.showMenu);
            // const isPdf = BranchOffice.showMenu.includes(".pdf");

            // if (isPdf) {
            //     setIframeShow(`https://docs.google.com/viewerng/viewer?url=${BranchOffice.showMenu}&embedded=true`);
            // }
            // else {
            //     setIframeShow(BranchOffice.showMenu)
            // }
        }

    }, [BranchOffice?.showMenu])

    useEffect(() => {
        if (!isTableLoading && dataTable != null && dataTable.remainingPrice != null) {
            const total = dataTable.remainingPrice;
            setOrderTotal(total > 0.00 ? numberWithCommas(total) : numberWithCommas("0.00"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTableLoading])

    const handleBackButton = () => {
        router.goBack();
    }

    return (
        <div className="h-100vh">
            {
                BranchOffice.showMenu == null || BranchOffice.showMenu == "" ?
                    <div>
                        <div className="menunav main-view-border-nav-rounded">
                            <ul className="ulnav">
                                <li>
                                    <div className="menunavbuttons">
                                        <div className="menubackbutton" onClick={handleBackButton}>
                                            <ChevronLeftIcon
                                                height={30}
                                                width={30}
                                                className=" chevron"
                                            />
                                        </div>
                                        <div className='menuheadertext'>
                                            <h1>{texts[0]}</h1>
                                        </div>
                                        <div className='menuheaderflags'>
                                            <FormControl className="blackLanguageSelectorForm" sx={{ m: 1, pt: 0, pb: 0, pr: 0, pl: 0, minWidth: 30, border: 1, borderColor: 'primary.contrastText', borderRadius: 10 }} size="small" margin="dense" variant="outlined" hiddenLabel="true">
                                                <Select
                                                    sx={{ pt: 0, pb: 0, pr: 0, pl: 0 }}
                                                    className="blackLanguageSelector"
                                                    labelId="black-language-select-label"
                                                    id="black-language-select"
                                                    value={language}
                                                    onChange={handleLanguageChange}
                                                >
                                                    <MenuItem value={0}>es</MenuItem>
                                                    <MenuItem value={1}>en</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>

                                    </div>
                                </li>
                                {
                                    BranchOffice.menus.filter(men => men.language == `${languageTranslation[language]}`).map(m => {
                                        return <li className="menunavitem" key={m.name}><Link activeClass="active text-solid-menium" smooth spy to={m.name} offset={-100}>{m.name}</Link></li>
                                    })
                                }
                            </ul>
                        </div>
                        {
                            BranchOffice.menus.filter(men => men.language == `${languageTranslation[language]}`).map(m => {
                                return <div className="menusections" id={m.name}><h2 className="menusectiontitle">{m.name}</h2>{m.urls.map(n => <img className="menuimages" src={n}></img>)}</div>
                            })
                        }
                        <div className="bottom-spacer"></div>
                        <div className="fixed bottom-4 h-17 w-full flex justify-around">
                            <button
                                className={[
                                    "bg-gradient-menium text-white p-2 rounded-full fullwidth-button flex flex-col text-center items-center buttonShadowSeeBill",

                                ].join(" ")}
                                onClick={handleClick}
                            >
                                <label className="text-white">{texts[1]}</label>
                                <label className="text-white">{orderTotal}€</label>
                            </button>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="menunav-web main-view-border-nav-rounded">
                            <ul className="ulnav">
                                <li>
                                    <div className="menunavbuttons-web">
                                        <div className="menubackbutton flex" onClick={handleBackButton}>
                                            <ChevronLeftIcon
                                                height={30}
                                                width={30}
                                                className=" chevron"
                                            />
                                            <label className="text-black volver-margin">{texts[2]}</label>
                                        </div>
                                        <div className='menuheadertext-web'>
                                            <h1>{texts[0]}</h1>
                                        </div>
                                        <div className="menubackbutton-right flex" onClick={handleClick}>
                                            <label className="text-black volver-margin ">{texts[3]}</label>
                                            <ChevronLeftIcon
                                                height={30}
                                                width={30}
                                                className=" chevron-right"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <iframe javascript="allow" className="w-full h-100p iframe-menu" src={BranchOffice.showMenu} />
                    </div>
            }
        </div>
        // <div style={container}>

        //     {
        //         iframeShow && (
        //             <iframe
        //                 src={iframeShow}
        //                 title="Menu"
        //                 scrolling="auto"
        //                 javascript="allow"
        //                 className="w-full h-full"
        //             />
        //         )
        //     }

        //     <div className="fixed bottom-4 h-10 w-full flex justify-around">
        // <button
        //     className={[
        //         "bg-gradient-menium text-white p-2 pl-4 pr-4 rounded-full flex-1 ml-3 mr-3 max-w-sm min-w-max ",

        //     ].join(" ")}
        //     onClick={handleClick}
        // >
        //     {`Ver y pagar la cuenta: ${orderTotal}€`}
        // </button>
        //     </div>
        // </div>
    )
}

export default ConnectToRedux(ViewMenuPDF, ["Commerce", "BranchOffice"])