import React, { useEffect, useState } from "react"
import { CategoryMenu } from "components/home/CategoryMenu"
import CategorySection from "components/home/CategorySection"
import Navbar from "components/global/navbar"
import ConnectToRedux from "components/global/ConnectToRedux"
import { useHistory } from "react-router"
import { container } from "Global"
import { getQuantityAndTotal } from "utils/Utils"
import { numberWithCommas } from "utils/Utils"
import useGetMenu from "hooks/useGetMenu"
import { CircularProgress } from "@mui/material"
import Modal from "components/global/Modal"
import X from "assets/icons/X.svg"

const Home = ({
  ShoppingCart,
  Mode,
  Commerce,
  BranchOffice,
  Search,
  setSearch,
}) => {
  //this is home
  const history = useHistory()
  const [openModal, setOpenModal] = useState({
    open: false,
    title: "",
    description: "",
    showCloseIcon: true,
    onClose: () => { },
  })
  const [statusTable, setStatusTable] = useState(false);

  const [menuState, setMenuState] = useState({
    loading: true,
    value: []
  })

  const [menuLoading, menuData] = useGetMenu(
    `Shops/${Commerce.NO_ID_FIELD}/BranchOffices/${BranchOffice.NO_ID_FIELD}/Menus`,
    Mode,
  )

  const [category, setCategory] = useState("")

  const handleChangeSearch = ({ target: { value } }) => setSearch(value)

  const handleSelectedItem = (selected) => setCategory(selected)

  const handleChangeStateModeSelected = () => history.push("/account")

  useEffect(() => {
    if (!BranchOffice.active) {
      setOpenModal({
        open: true,
        title: "Sucursal desactivada",
        description:
          "En estos momentos no se encuentra habilitada esta sucursal",
        showCloseIcon: false,
        onClose: () => { },
      })
    } else if (BranchOffice?.table?.isCloseTable) {
      setStatusTable(BranchOffice.table.isCloseTable)
      setOpenModal({
        open: BranchOffice.table.isCloseTable,
        title: "Mesa cerrada",
        description:
          "En cuanto el camarero prepare la mesa, podrás usar Menium para pedir y pagar",
        showCloseIcon: true,
        onClose: () => setOpenModal((el) => ({ ...el, open: false })),
      })
    }
  }, [BranchOffice, Commerce])

  useEffect(() => {
    if (!menuLoading && menuData?.categories) {
      if (menuData?.categories?.length > 0) {
        setCategory(menuData.categories[0].name)
      }
    }
  }, [menuLoading, menuData])

  useEffect(() => {
    // UseEffect del Observador del menu
    const sections = document.querySelectorAll(".boxSearch")

    const funcObserver = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCategory(entry.target.dataset.section)
        }
      })
    }

    const root = document.querySelector("#container")

    const observer = new IntersectionObserver(funcObserver, {
      root,
      rootMargin: `0px 0px -${root.clientHeight - 50}px 0px`,
      threshold: 0.5,
    })

    sections.forEach((el) => observer.observe(el))
  }, [menuData])

  useEffect(() => {
    const schedules = Commerce.schedules;

    const currentDay = new Date();

    const splitHour = hours => {

      const hourAndMinutes = hours.split(" - ");
      const split1 = hourAndMinutes[0].split(":");

      const hour1 = parseInt(split1[0]);

      const minutes1 = parseInt(split1[1]);

      const split2 = hourAndMinutes[1].split(":");

      const hour2 = parseInt(split2[0]);

      const minutes2 = parseInt(split2[1]);

      return {
        init: [hour1, minutes1],
        end: [hour2, minutes2]
      }
    }

    const verifyScheduleCommerce = schedules.reduce((previus, current) => {
      const day = current.dayInt;

      if (currentDay.getDay() === day) {
        const { init, end } = splitHour(current.Hour);

        const initDay = new Date(),
          endDay = new Date();

        initDay.setHours(...init, 0)
        endDay.setHours(...end, 0)

        if (initDay <= currentDay && currentDay <= endDay) {
          return [...previus, current];
        }

      }

      return previus
    }, [])

    setMenuState({
      loading: false,
      value: verifyScheduleCommerce
    })

  }, [Commerce])

  useEffect(() => {
    if (BranchOffice) {
      if (BranchOffice.isOnlyPayment) {
        history.push("/only-payment");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BranchOffice])

  const payment = getQuantityAndTotal(ShoppingCart)

  const isOpenOrderMode = Mode === "isOpenOrder"

  return (
    <div className="overflow-y-hidden relative flex flex-col" style={container}>
      <div className="inline shadow-menu">
        <Navbar />
        {Search.isOpenSearch && (
          <div className="p-2 mb-4">
            <input
              type="text"
              placeholder="Busca un producto del menú"
              className="input-register mt-2"
              onChange={handleChangeSearch}
              value={Search.search}
            />
          </div>
        )}
        {!Search.isOpenSearch && !menuLoading && menuData && (
          <CategoryMenu
            categories={menuData.categories}
            marked={category}
            onSelected={handleSelectedItem}
          />
        )}
      </div>
      <div
        className={`overflow-y-scroll flex-1 ${Search.isOpenSearch ? "mt-4" : ""
          }`}
        id="container"
      >
        {
          menuState.loading || menuLoading ? (
            <div className="py-4 text-isNotEnable-primary-color text-center">
              <CircularProgress />
            </div>
          ) : (
            menuState.value.length > 0 ? (
              !menuData?.categories ? (
                <div className="px-7 text-isNotEnable-primary-color text-center h-full flex justify-center items-center">
                  Este QR no cuenta con menus activos en la hora actual
                </div>
              ) : (
                menuData?.categories.map((el, i) => (
                  <CategorySection
                    categorie={el}
                    isAdd={statusTable}
                    key={`section_categories_${el.name}`}
                  />
                ))
              )
            ) : (
              <div className="px-7 text-isNotEnable-primary-color text-center h-full flex justify-center items-center">
                Sucursal cerrada
              </div>
            )
          )
        }
        <div className="h-16"></div>
      </div>

      <div className="fixed bottom-4 h-10 w-full flex justify-around">
        <button
          className={[
            "bg-gradient-menium text-white p-2 pl-4 pr-4 rounded-full flex-1 ml-3 mr-3 max-w-sm min-w-max ",
            (ShoppingCart.custom.length === 0 && ShoppingCart.defaults.length === 0) && "text-isNotEnable-secundary-color",
          ].join(" ")}
          disabled={ShoppingCart.custom.length === 0 && ShoppingCart.defaults.length === 0}
          onClick={handleChangeStateModeSelected}
        >
          {payment[0] === 0
            ? "Añadir productos"
            : `Pedir ${payment[0]} por ${numberWithCommas(
              payment[1].toFixed(2),
            )}€`}
        </button>
        {isOpenOrderMode && (
          <button
            className="bg-gradient-menium text-white p-2 rounded-full flex-1 ml-3 mr-3 max-w-sm"
            onClick={() => history.push("/account")}
          >
            Ver cuenta
          </button>
        )}
      </div>

      <Modal
        open={openModal.open}
        onClose={openModal.onClose}
        closeIcon={
          <div className="glassButton">
            <img src={X} alt="close icon" />
          </div>
        }
        showCloseIcon={openModal.showCloseIcon}
      >
        <div className="bg-gradient-meniumtext-white opacity-25 w-full h-full z-10 " />
        <div className="bg-gradient-menium text-white rounded-lg p-4">
          <div className="items-center">
            <div className="rounded-full flex items-center justify-center ">
              <i className="bx bx-error text-3xl" />
            </div>
            <div className=" md:mt-0 md:ml-6 text-center md:text-left">
              <p className="text-lg text-left">{openModal.title}</p>
              <p className="text-sm mt-3 text-left">{openModal.description}</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConnectToRedux(Home, [
  "ShoppingCart",
  "Commerce",
  "Mode",
  "BranchOffice",
  "Search",
])
