import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { OnlyPaymentService } from "Service/OnlyPayment"
import Rate from "../Components/Rate"
import Receipt from "../Components/Receipt"
import Register from "../Components/Register"
import { useSelector } from 'react-redux'

const Footer = () => {
  const history = useHistory();

  const spanishText = ["Volver a la cuenta"];
  const englishText = ["Return to the bill"];
  const [texts, setTexts] = useState(spanishText);

  const language = useSelector(state => state.main.Language);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  return (
    <div className="bg-menium-opacity-80 w-full p-4">
      <div
        onClick={() => {
          Cookies.remove("payment_made");
          var tableId = localStorage.getItem('tableId');
          if (!tableId || tableId == undefined) {
            history.push("/closed");
          } else {
            OnlyPaymentService.getTableUsingId(tableId)
              .then(tableData => {
                if (tableData.isOpen == false) {
                  history.push("/closed");
                }
                else {
                  history.push("/only-payment");
                }
              });
          }
        }}
        className="flex justify-center items-center flex-col h-30 text-center bg-transparent border-white border-1 rounded-xl mb-4"
      >
        <p className="text-white font-bold">{texts[0]}</p>
      </div>
      <Register />
    </div>
  )
}

export default Footer
