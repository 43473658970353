import { Tree } from "Class/Tree"
import ConnectToRedux from "components/global/ConnectToRedux"
import React, { useEffect, useState } from "react"
import ItemModifier from "./ItemModifier"

const SectionModifier = (props) => {

  const tree = new Tree()

  const [currentCount, setCurrentCount] = useState(0);

  const updateCurrentCount = () => {
    setCurrentCount(tree.getCountGroup(props.nodePath, props.Modifier.subItems))
    props.onChange()
  }

  useEffect(() => {
    setCurrentCount(tree.getCountGroup(props.nodePath, props.Modifier.subItems));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Modifier])

  return (
    <div>
      <div className="flex p-2 bg-title-section justify-between text-black items-center pr-5">
        <h2 className="text-lg">{props.name}</h2>
        <label className="text-isNotEnable-primary-color ml-2">
          {
            props.min > 0 ? "Requerido" : "Opcional"
          }
        </label>
      </div>
      <div className="flex justify-between px-5 text-isNotEnable-secundary-color mt-2 mb-2">

        <div className="flex">
          {
            props.min > 0 && (
              <label className="mr-2">Min: {props.min}</label>
            )
          }
          <label>Max: {props.max}</label>
        </div>
        <label>Seleccionado: {currentCount}/{props.max}</label>

      </div>

      <div className="px-5 mt-2 pb-5">
        <div className="flex w-full flex-col">
          {props.subItems.map((subItem, j) => (
            <ItemModifier
              {...subItem}
              groupName={props.name}
              IHaveInTheGroup={currentCount}
              minimumInGroup={props.min}
              maximumInGroup={props.max}
              onChange={updateCurrentCount}
              nodePath={[...props.nodePath, j]}
              reference={`${props.reference}_${subItem.plu}_subitem_${j}`}
              key={`${props.reference}_${subItem.plu}_subitem_${j}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

SectionModifier.defaultProps = {
  topbar: false,
  nodePath: [],
}

export default ConnectToRedux(SectionModifier, "Modifier")
