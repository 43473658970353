import React, { useEffect } from "react"
// import PropTypes from "prop-types"
import { CategoryMenuItem } from "./CategoryMenuItem"

export const CategoryMenu = ({ categories, marked, onSelected }) => {


  const rename = marked.split(' ').join('')

  useEffect(() => {

    const element = document.getElementById(`${rename}_item_menu`)

    if (element) {
      element.scrollIntoView({ inline: "start" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marked])

  return (
    <nav className="border-b-2 flex flex-row justify-center bg-white" id="navCategory">
      <div className="relative flex p-3 space-x-6 overflow-x-scroll" id="containerNav">
        {categories.map((el, i) => (
          <CategoryMenuItem
            label={el.name}
            key={`item_menu_categories_${i}`}
            isMarked={marked === el.name}
            onClick={onSelected}
          />
        ))}
      </div>
    </nav>
  )
}

// CategoryMenu.propTypes = {
//   categories: PropTypes.array.isRequired,
//   marked: PropTypes.string.isRequired,
//   onSelected: PropTypes.func,
// }

// CategoryMenu.defaultProps = {
//   onSelected: () => { },
// }
