import { User } from "firebase/firebase"
import React, { useEffect, useState } from "react"
import { useMemo } from "react"
import { useHistory } from "react-router"
import { useFirebaseApp } from "reactfire"
import { numberWithCommas } from "utils/Utils"
import ConnectToRedux from "./ConnectToRedux"
import config from "../../config.json"
import { endpoint } from "Global"
import Cookies from "js-cookie"
import Modal from "components/global/Modal"
import LastMinuteTips from "views/MyAcount2/Components/LastMinuteTips"
import { OnlyPaymentService } from "Service/OnlyPayment"
import { useSelector } from 'react-redux'

const environment = process.env.NODE_ENV || "development"

const ButtonsOptionStripe = ({
  statePayment,
  payload,
  amount,
  setPaymentOrder,
  commerce,
  active,
  tipAmountCalculated
}) => {
  const [paymentRequest, setPaymentRequest] = useState(null)
  // const userClass = new User()
  const history = useHistory()
  const userClass = useMemo(() => new User(), [])
  return <></>
}

const PaymentButtons = ({
  User,
  payload,
  clickPayment,
  amount,
  Commerce,
  setPaymentOrder,
  active,
  tipAmountCalculated,
  paymentType
}) => {
  const router = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const spanishText = ["Pagar", "Cargando pago...", "¿Quieres dejar propina?"];
  const englishText = ["Pay", "Loading payment...", "Do you want to leave tip?"];

  const language = useSelector(state => state.main.Language);
  const merchantDetails = useSelector(state => state.main.BranchOffice);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const [tipAmountApply, setTipAmountApply] = useState(tipAmountCalculated)
  const zeroTip = {
    label: "NONE",
    value: 0.0
  }
  const [lastMinuteTip, setLastMinuteTip] = useState(zeroTip)
  useEffect(() => {
    setTipAmountApply(tipAmountCalculated);
  }, [tipAmountCalculated])

  const handleLastMinuteTip = (el) => {
    setLastMinuteTip(el);
    setTipAmountApply(el.amount);
  }

  const handleClickCartMethod = async () => {
    if ((!tipAmountApply || tipAmountApply == 0) && !openModal && !merchantDetails?.isTipDisabled) {
      setOpenModal(true);
    }
    else {
      if (active) {
        setIsLoading(true);
        var tipSend = 0.00;
        if (tipAmountApply) {
          tipSend = tipAmountApply;
        }

        const paymentStartRequest = {
          tableId: payload.tableId,
          tableCode: payload.tableName,
          commerceId: payload.commerceId,
          branchOfficeId: payload.branchOfficeId,
          businessName: Commerce.name,
          amount: amount,
          tip: tipSend,
          userIdentifier: Cookies.get("anonGuid"),
          gateway: 3, // This is DEMO Payment Gateway (auto confirm possible)
          paymentType: paymentType,
          lines: []
        };
        if (payload.products && payload.modeOpenOrder && payload.modeOpenOrder == "PayByProduct") {
          paymentStartRequest.paymentType = 3;
          const prods = Object.values(payload.products);
          prods.forEach((item) => {
            if (item.quantity > 0) {
              const newItem = {
                itemPosReference: item.itemPosReference,
                itemPrice: item.unitPrice,
                units: item.quantity,
                totalPrice: item.quantity * item.unitPrice
              };
              paymentStartRequest.lines.push(newItem);
            }
          });
        }

        const paymentDataResponse = await fetch(`${endpoint}/Payment/request`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get("anonToken")}`
          },
          body: JSON.stringify(paymentStartRequest)
        }).then(resp => resp.json());

        if (paymentDataResponse.error) {
          console.log(paymentDataResponse.error);
        }
        else {
          const confirmDemoPaymentResponse = await OnlyPaymentService.setDemoPaymentConfirmed(paymentDataResponse.identifier);
          Cookies.set("payment_made", paymentDataResponse.amount);
          Cookies.set("payment_ref", paymentDataResponse.identifier);
          router.push("/paid");
          return null;
        }
      }
    }
  }

  const handleStatePayment = (state) => clickPayment(state)

  return (
    <div className="mt-2 flex flex-col space-y-3">
      <button
        className={[
          "bg-gradient-menium w-full rounded-full text-white px-4 py-2",
          !active && "text-isNotEnable-secundary-color",
        ].join(" ")}
        onClick={!isLoading ? handleClickCartMethod : null}
        disabled={!active}
      >
        {isLoading
          ? `${texts[1]}`
          : `${texts[0]} ${numberWithCommas(amount + (tipAmountApply ?? 0))}€`}
      </button>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="space-y-2 flex flex-col py-8 px-8">
          <h4 className="text-lg color-title font-bold text-center mt-4">
            {texts[2]}
          </h4>
          <LastMinuteTips
            value={lastMinuteTip.label}
            paymentAmount={amount}
            onGetValue={handleLastMinuteTip}
          />
          <button
            className={[
              "bg-gradient-menium w-full rounded-full text-white px-4 py-2",
              !active && "text-isNotEnable-secundary-color",
            ].join(" ")}
            onClick={!isLoading ? handleClickCartMethod : null}
            disabled={!active}
          >
            {isLoading
              ? `${texts[1]}`
              : `${texts[0]} ${numberWithCommas(amount + (tipAmountApply ?? 0))}€`}
          </button>
        </div>
      </Modal>
    </div>
  )

}

PaymentButtons.defaultProps = {
  active: true,
}

export default ConnectToRedux(PaymentButtons, ["User", "Commerce", "App"])
