export class Tree {



  groupRemove = (group) =>
    group.subItems.reduce(
      (previus, current) => [
        ...previus,
        ...this.nodeGroupRemove(current.subItems),
      ],
      [],
    )

  nodeGroupRemove = (node) =>
    node.reduce(
      (previus, current) => [
        ...previus,
        {
          ...current,
          subItems: this.groupRemove(current),
        },
      ],
      [],
    )

  removeGroupInSubItems = (tree) => {
    const product = {
      ...tree,
      subItems: this.groupRemove(tree),
    }

    return product
  }

  getCountGroup = (rute, tree) => {

    const copyRute = [...rute];
    copyRute.splice(0, 1);

    const getTree = tree[rute[0]]

    if (copyRute.length > 0) {
      return this.getCountGroup(copyRute, getTree.subItems)
    }

    const items = getTree.subItems;

    const count = items.reduce((previus, current) => previus + current.quantity, 0);

    return count
  }

  verifyGroup = (group) => {

    const min = group.min,
      max = group.max,
      quantityProducts = group.subItems.reduce((previus, current) => {
        if (current) {
          return previus + 1
        }

        return previus
      }, 0),
      name = group.name

    const verify = []

    if (min > quantityProducts || max < quantityProducts) {
      const msg = `En el grupo "${name}" solo se permite un minimo de ${min} y un maximo de ${max} items seleccionados`

      verify.push({
        msg,
        state: false,
      })
    }

    const subItems = group.subItems.reduce(
      (previus, current) => [...previus, ...this.verifyNode(current)],
      [],
    )

    return [...verify, ...subItems]
  }

  verifyNode = (node) =>
    node.subItems.reduce(
      (previus, current) => [...previus, ...this.verifyGroup(current)],
      [],
    )

  verifyTree = (tree) => {
    const copyTree = { ...tree }
    const res = copyTree.subItems.reduce(
      (previus, current) => [...previus, ...this.verifyGroup(current)],
      [],
    )

    return res
  }

  total = (tree) => {
    const priceProduct = tree.price
    const quantity = tree.quantity ? tree.quantity : 1

    const sum_subItems = tree.subItems.reduce(
      (previus, current) => previus + this.total(current),
      0,
    )
    return (priceProduct + sum_subItems) * quantity
  }

  searchNode = (nodePath, plu, tree) => {
    const copyNodePath = [...nodePath]
    let acum = null,
      node = null

    if (tree) {
      while (copyNodePath.length > 0) {
        if (!acum) {
          acum = tree.subItems[copyNodePath[0]]
        } else {
          acum = acum.subItems[copyNodePath[0]]
        }

        copyNodePath.shift()

        if (copyNodePath.length === 0) {
          node = acum
        }
      }
      return node
    }

    return false
  }

  group = (
    tree,
    nodePath,
    push,
    afterData = null,
    init = true,
    isDestroy = false,
  ) => {
    let node = null,
      leaves = null
    const currentPosition = nodePath[0]

    if (init) {
      node = tree.subItems[currentPosition]
      leaves = tree.subItems
    } else {
      node = afterData.subItems[currentPosition]
      leaves = afterData.subItems
    }

    nodePath.shift()

    leaves[currentPosition] = {
      ...node,
      subItems: this.node(nodePath, push, node, isDestroy),
    }

    return leaves
  }

  node = (nodePath, push, afterData = null, isDestroy) => {
    const currentPosition = nodePath[0]
    let node = null,
      leaves = null

    nodePath.shift()

    leaves = afterData.subItems

    if (nodePath.length > 1) {
      node = afterData.subItems[currentPosition]

      leaves[currentPosition] = {
        ...node,
        subItems: this.group(null, nodePath, push, node, false, isDestroy),
      }

      return leaves
    }

    if (isDestroy) {
      let newLeaves = []

      for (let index = 0; index < leaves.length; index++) {
        const element = leaves[index]

        if (index !== currentPosition && element) {
          newLeaves[index] = element
        }
      }

      leaves = newLeaves
    } else {
      leaves[currentPosition] = push
    }

    return leaves
  }

  AddOrUpdateNode = (nodePath, data, tree) => {
    tree.subItems = this.group(tree, nodePath, data);
    return tree
  }

  DestroyNode = (nodePath, tree) => {
    tree.subItems = this.group(tree, nodePath, null, null, true, true)

    return tree
  }
}
