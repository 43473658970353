import ConnectToRedux from "components/global/ConnectToRedux"
import PaymentButtons from "components/global/PaymentButtons"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { numberWithCommas } from "utils/Utils"
import BottomsPayByDivision from "./BottomsPayByDivision"

const PayByDivision = ({ payload, BranchOffice }) => {
  const history = useHistory()

  const [division, setDivision] = useState({
    toPayPerPerson: 1,
    toPay: 1,
  })

  const [payPerPerson, setPayPerPerson] = useState(0)

  const incrementToPayPerPerson = () => {
    const increment = division.toPayPerPerson + 1
    setDivision({ ...division, toPayPerPerson: increment })
  }

  const decrementToPayPerPerson = () => {
    const decrement = division.toPayPerPerson - 1

    if (decrement >= 1) {

      if (division.toPay <= decrement) {
        setDivision({ ...division, toPayPerPerson: decrement })
      }
      else {
        setDivision({ toPay: decrement, toPayPerPerson: decrement })

      }
    }

  }

  const incrementToPay = () => {
    if (division.toPay < division.toPayPerPerson) {
      const increment = division.toPay + 1
      setDivision({ ...division, toPay: increment })
    }
  }

  const decrementToPay = () => {
    const decrement = division.toPay - 1

    if (decrement >= 1) {
      setDivision({ ...division, toPay: decrement })
    }
  }

  const handleClickPayment = (res) => {
    //En este metodo es el que se ejecuta cuando se paga por apple pay o google pay
    //El payload ya se guarda en el redux listo para redirijir a la ruta /Pagado
    //Verifica si fue exitoso o no viene en {res}
    if (res.status) {
      history.push("/Pagado")
    } else {
      alert("El pago no fue exitoso")
    }
  }

  const newPayload = {
    ...payload,
    paidOut: payload.paidOut + division.toPay * payPerPerson,
    modeOpenOrder: "PayByDivision",
    usePayPerProducts: false,
    amount: division.toPay * payPerPerson,
  }

  useEffect(() => {
    const result =
      (payload.total + payload.tip - payload.paidOut) / division.toPayPerPerson

    setPayPerPerson(result)
  }, [
    division.toPayPerPerson,
    division.toPay,
    payload.total,
    payload.tip,
    payload.paidOut,
  ])

  useEffect(() => {
    if (BranchOffice) {
      setDivision((data) => ({
        ...data,
        toPayPerPerson: BranchOffice.table.numberPeople,
      }))
    }
  }, [BranchOffice])

  return (
    <div className="flex flex-col space-y-4 pb-8">
      <h4 className="text-xl font-bold color-title">Pagar por división</h4>
      <div className="flex flex-col text-sm">
        <span>Dividir el total de la cuenta entre:</span>
        <span className="text-isNotEnable-primary-color">
          (Aunque no usen Menium)
        </span>
      </div>

      <BottomsPayByDivision
        total={division.toPayPerPerson}
        onClickIncrement={incrementToPayPerPerson}
        onClickDecrement={decrementToPayPerPerson}
      >
        <div className="flex flex-col items-end text-sm">
          <span className="text-isNotEnable-primary-color">A pagar por</span>
          <span className="text-isNotEnable-primary-color">persona:</span>
          <span className="text-black font-bold">
            {numberWithCommas(payPerPerson)} €
          </span>
        </div>
      </BottomsPayByDivision>

      <span>Número de personas por el que pagarás:</span>

      <BottomsPayByDivision
        total={division.toPay}
        onClickIncrement={incrementToPay}
        onClickDecrement={decrementToPay}
      >
        <div className="flex flex-col items-end text-sm">
          <span className="text-isNotEnable-primary-color">A pagar:</span>
          <span className="ttext-black font-bold">
            {numberWithCommas(division.toPay * payPerPerson)} €
          </span>
        </div>
      </BottomsPayByDivision>

      <h4 className="text-lg color-title font-bold">Con qué quieres pagar</h4>

      <PaymentButtons
        payload={newPayload}
        clickPayment={handleClickPayment}
        amount={division.toPay * payPerPerson}
        active={(division.toPay * payPerPerson) > 0}
      />

      <div className="flex text-center text-xs mt-2 text-isNotEnable-primary-color">
        <p>
          Al continuar, estas aceptando los{" "}
          <strong className="color-title"> Términos y Condiciones </strong> y la{" "}
          <strong className="color-title">Política de Privacidad</strong> de
          Menium
        </p>
      </div>
    </div>
  )
}

export default ConnectToRedux(PayByDivision, "BranchOffice")
