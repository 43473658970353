import { setPaymentApp, setPaymentOrder } from "storage/Actions/main"

const mapStateToProps = (state) => ({
  mode: state.main.paymentMode,
  payment: state.main.payment,
})

const sendInfo = {
  mapStateToProps,
  mapDispatchToProps: {
    setPaymentApp,
    setPaymentOrder,
  },
}

export default sendInfo
