import React from "react"

const BottomsPayByDivision = ({
  onClickDecrement,
  onClickIncrement,
  children,
  total,
}) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div>
        <div className="flex flex-row space-x-5 items-center">
          <div>
            <div
              onClick={onClickDecrement}
              className="relative w-4 h-4 text-green-700 font-bold bg-green-100 opacity-90  rounded-full flex justify-center items-center text-center p-5"
            >
              -
            </div>
          </div>

          <div>
            <div
              style={{
                borderColor: "#4527A0",
                color: "#4527A0",
              }}
              className=" border-2 text-center rounded px-2"
            >
              {total}
            </div>
          </div>
          <div>
            <div
              onClick={onClickIncrement}
              className="relative w-4 h-4 font-bold text-green-700 bg-green-100 opacity-90 rounded-full flex justify-center items-center text-center p-5"
            >
              +
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default BottomsPayByDivision
