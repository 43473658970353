import React from "react"
import plusSignGreen from "assets/icons/plus-sign-green.png"
import minusSignGreen from "assets/icons/minus-sign-green.png"

const BottomsPayByDivision = ({
  onClickDecrement,
  onClickIncrement,
  children,
  total,
}) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div>
        <div className="flex flex-row space-x-5 items-center">
          <img className="w-8 h-8" src={minusSignGreen} onClick={onClickDecrement}></img>
          <div>
            <div
              style={total > 0 ? {
                borderColor: "#48b99f",
                color: "#48b99f",
                backgroundColor: "#eaf7f4",
                width: "1.8rem",
                height: "1.8rem",
                lineHeight: "1.8rem",
                fontSize: "1.1rem"
              } : {
                borderColor: "#44546c",
                color: "#44546c"
              }}
              className="border-2 text-center rounded px-2"
            >
              {total}
            </div>
          </div>
          <img className="w-8 h-8" src={plusSignGreen} onClick={onClickIncrement}></img>
        </div>
      </div>
      {children}
    </div>
  )
}

export default BottomsPayByDivision
