// SHOPPING CART
export const SET_SHOPPING_CART_DEFAULTS = "SET_SHOPPING_CART_DEFAULTS"
export const SET_SHOPPING_CART_CUSTOM = "SET_SHOPPING_CART_CUSTOM"
export const ADD_TO_SHOPPING_CART_DEFAULTS = "ADD_TO_SHOPPING_CART_DEFAULTS"
export const ADD_TO_SHOPPING_CART_CUSTOM = "ADD_TO_SHOPPING_CART_CUSTOM"
export const RESET_SHOPPING_CART = "RESET_SHOPPING_CART"

// USER
export const SET_USER = "SET_USER"
export const REMOVE_USER = "REMOVE_USER"

//APP MODE PAYMENT
export const SET_APP_MODE = "SET_APP_MODE"

//Commerce
export const SET_COMMERCE = "SET_COMMERCE"

// BranchOffice
export const SET_BRANCH_OFFICE = "SET_BRANCH_OFFICE"

// Menu
export const SET_MODE = "SET_MODE"

export const SET_PAYMENT_ORDER = "SET_PAYMENT_ORDER"

// Search
export const CHANGE_STATE_SEARCH = "CHANGE_STATE_SEARCH"
export const SET_SEARCH = "SET_SEARCH"

// Modifier
export const SET_MODIFIER = "SET_MODIFIER";
export const CHANGE_REFRESH_VALIDATE_MODIFIER = "CHANGE_REFRESH_VALIDATE_MODIFIER"

export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_LANGUAGE = "GET_LANGUAGE";