import { addDoc, collection, doc, setDoc } from "@firebase/firestore"
import { getFunctions } from "@firebase/functions"

class Order {

    constructor(app, firestore) {
        this.initFuncApp = getFunctions(app)
        this.initStoreApp = firestore
    }

    setGlobalOrder = async (payload) => {
        const ref = collection(this.initStoreApp, 'orders')

        const { id } = await addDoc(ref, payload);

        return id
    }

    setNewRefOrder = async (infoOrder, ShoppingCart, ref) => {

        const refElement = await collection(this.initStoreApp, ...ref);

        const { id } = await addDoc(refElement, infoOrder);

        await this.setShoppingCart(ShoppingCart, [...ref, id])

        return true

    }

    setUpdateRefOrder = async (infoOrder, ShoppingCart, ref) => {

        const refElement = await doc(this.initStoreApp, ...ref);

        await setDoc(refElement, infoOrder);

        await this.setShoppingCart(ShoppingCart, [...ref])

        return true

    }

    setShoppingCart = async (ShoppingCart, ref) => {

        const collectionRefDefaults = collection(this.initStoreApp, ...ref, 'defaults')

        ShoppingCart.defaults.forEach(async product => {

            await addDoc(collectionRefDefaults, product);
        });

        const collectionRefCustoms = collection(this.initStoreApp, ...ref, 'custom')

        ShoppingCart.custom.forEach(async product => {

            await addDoc(collectionRefCustoms, product);
        });

        return true
    }

    newOrder = async (payload, ref) => {
        const copyPayload = { ...payload }
        const ShoppingCart = payload.after.ShoppingCart;

        delete copyPayload.after.ShoppingCart;

        return await this.setNewRefOrder(copyPayload.after, ShoppingCart, ref)
    }

    updateOrder = async (payload, ref) => {
        const copyPayload = { ...payload }
        const ShoppingCart = payload.after.ShoppingCart;

        delete copyPayload.after.ShoppingCart;

        copyPayload.after.total = copyPayload.after.total + copyPayload.before.total;
        copyPayload.after.paidOut = copyPayload.before.paidOut;
        copyPayload.after.usePayPerProducts = copyPayload.before.usePayPerProducts

        return await this.setUpdateRefOrder(copyPayload.after, ShoppingCart, ref)
    }

}

export default Order