import React from "react"
import { numberWithCommas, nameWithFirstUpper } from "utils/Utils"
import plusSignGreen from "assets/icons/plus-sign-green.png"
import minusSignGreen from "assets/icons/minus-sign-green.png"

const ProductListItem = ({ item, checked, index, onChange }) => {

  const handleChangeInput = ({ target: { value } }) => {
    try {
      const parseValue = parseInt(value)

      if (parseValue <= item.remainingUnits && parseValue >= 0) {
        onChange(parseValue, item, index)
      }
      else if (!value) {
        onChange(0, item, index)
      }

    } catch (error) {
      alert("Valor no permitido")
    }
  }

  const handleIncrement = () => {
    if (!item.quantity) {
      item.quantity = 0;
    }

    var value = item.quantity + 1 <= item.remainingUnits ? item.quantity + 1 : item.quantity;
    item.quantity = value;

    onChange(value, item, index);

  }

  const handleDecrement = () => {
    if (!item.quantity) {
      item.quantity = 0;
    }

    var value = item.quantity > 0 ? item.quantity - 1 : 0;
    item.quantity = value;

    onChange(value, item, index);
  }

  const onKeyDownOverride = () => {
    return false;
  }

  return (
    <div className="flex flex-row justify-between items-center py-3 border-b-2">
      <div className="flex flex-row justify-around items-center space-x-4 w-full text-isNotEnable-primary-color ml-2">
        <span>x{item.remainingUnits}</span>
        {/* <span className="flex-1">{item.itemLineName}</span> */}
        <span className={`flex-1 ${item.remainingUnits > 0 ? "" : "text-isNotEnable-secundary-color"}`}>{nameWithFirstUpper(item.itemLineName)}</span>
        <span className={`min-w-max ${item.remainingUnits > 0 ? "" : "text-isNotEnable-secundary-color"}`}>{numberWithCommas(item.unitPrice)}€ </span>
      </div>
      <div className="flex flex-row space-x-2 ml-2 mr-9">
        <img className="w-6 h-6 mt-0.5" src={minusSignGreen} onClick={handleDecrement}></img>
        <input
          disabled={!item.quantity || item.quantity == 0 ? true : false}
          inputMode="numeric"
          pattern="[0-9]*"
          type="number"
          className={[
            "input-by-product text-center ",
            checked && "bg-aditional-menium"
          ].join(" ")}
          value={item.quantity ? item.quantity : 0}
          onKeyDown={onKeyDownOverride}
          readOnly={true}
        />
        <img className="w-6 h-6 mt-0.5" src={plusSignGreen} onClick={handleIncrement}></img>
      </div>
    </div>
  )
}
export default React.memo(ProductListItem)
