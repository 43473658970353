import React from "react"
import { numberWithCommas } from "utils/Utils"
import ConnectToRedux from "components/global/ConnectToRedux"
import { Link } from "react-router-dom"

const DetailPaymentOpenOrder = ({ payload, Commerce, BranchOffice, User }) => {
  return (
    <>
      <div className="flex items-center">
        <div className="flex max-h-28 space-x-2 flex-1 justify-start" />
        <div className="w-52">
          <div className="flex justify-between text-xs font-14px">
            <label className="text-right flex-1">Pagado:</label>
            <label className="flex-05 text-right">
              {numberWithCommas(payload.paidOut)}€
            </label>
          </div>
          <div className="flex justify-between text-xs font-14px">
            <label className="text-right flex-1">Falta por pagar:</label>
            <label className="flex-05 text-right">
              {numberWithCommas(payload.total + payload.tip - payload.paidOut)}€
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center mt-3">
        <div className="flex max-h-28 space-x-2 flex-1 justify-start" />
        <div className="w-52">
          <div className="text-gray-500 flex justify-between text-xs font-14px">
            <label className="text-right flex-1">Propina:</label>
            <label className="flex-05 text-right">
              {numberWithCommas(payload.tip)}€
            </label>
          </div>
          <div className="font-bold flex justify-between text-base">
            <label className="text-right flex-1">TOTAL:</label>
            <label className="flex-05 text-right">
              {numberWithCommas(payload.total + payload.tip)}€
            </label>
          </div>
        </div>
      </div>
      {!User.name && (
        <div className="text-xs text-center text-isNotEnable-primary-color mt-2">
          <Link to="/register" className="color-title">
            Regístrate
          </Link>{" "}
          o{" "}
          <Link to="/login" className="color-title">
            Inicia sesión
          </Link>{" "}
          para recibir promos y descuentos
        </div>
      )}
    </>
  )
}

export default ConnectToRedux(DetailPaymentOpenOrder, [
  "Commerce",
  "BranchOffice",
  "User",
])
