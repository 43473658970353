import React from "react"
import ConnectToRedux from "components/global/ConnectToRedux"
import { numberWithCommas } from "utils/Utils"

const DefaultItems = ({ product, onChange, index }) => {
  const handleClickAggregate = () => {
    onChange("defaults", index, { ...product, quantity: product.quantity + 1 })
  }

  const handleClickDecrement = () => {
    onChange("defaults", index, { ...product, quantity: product.quantity - 1 })
  }

  return (
    <div className="flex  pb-2 mt-2 separator">
      <div className="ml-5 flex flex-col items-center  justify-between space-y-1">
        <label className="h-5 font-bold text-base text-purple-800">
          x{product.quantity}
        </label>
        <label
          onClick={handleClickDecrement}
          className="bg-aditional-menium text-green-700 bg-green-200 rounded-full w-5 h-5 flex items-center justify-center text-lg font-bold"
        >
          -
        </label>
      </div>
      <div className="flex-1 text-lg">
        <div className="flex">
          <div className="flex flex-col ml-2 truncate-text-multiline w-1/2">
            <h3 className="text-base">{product.name}</h3>
          </div>
        </div>
      </div>
      <div className="mr-8 flex flex-col items-end justify-between space-y-1">
        <label className="h-5 text-sm">
          {numberWithCommas(
            parseFloat(product.price * product.quantity).toFixed(2),
          )}{" "}
          €
        </label>
        <label
          onClick={handleClickAggregate}
          className="bg-aditional-menium text-green-700 bg-green-200 rounded-full w-5 h-5 flex items-center justify-center text-lg font-bold"
        >
          +
        </label>
      </div>
    </div>
  )
}

export default ConnectToRedux(DefaultItems, "ShoppingCart")
