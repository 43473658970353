import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import facebookIcon from "assets/images/facebook.svg"
import googleIcon from "assets/images/google.svg"
import checkIcon from "assets/icons/check.svg"
import { useFirebaseApp, useFirestore } from "reactfire"
import { User } from "firebase/firebase"
import ConnectToRedux from "components/global/ConnectToRedux"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { useSelector } from 'react-redux'

const Register = ({ addUser }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const userClass = new User(useFirebaseApp(), useFirestore())
  const history = useHistory()

  const spanishText = ["Crea tu cuenta en 10 segundos", "Recibirás grandes descuentos y promociones", "Crea una cuenta en Menium y podrás:", "Disfrutar de descuentos especiales", "Incluir varios metodos de pago", "Guardar tus restaurantes favoritos", "¡Y mucho más!", "O entra en", "¿Ya tienes cuenta?", "Cancelar"];
  const englishText = ["Create an account in 10 seconds", "You'll receive discounts and promotions", "Create a Menium account so you can:", "Enjoy special discounts", "Save multiple payment methods", "Save your favorite restaurants", "And a lot more!", "or use", "Already have an account?", "Cancel"];
  const [texts, setTexts] = useState(spanishText);

  const language = useSelector(state => state.main.Language);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  const handleChangeStateModal = () => setIsOpenModal(!isOpenModal)

  const createUserWithGoogle = () => {
    userClass
      .loginUserWithGoogle()
      .then((resp) => {
        addUser(resp.user)
        history.push("/")
      })
      .catch((err) => alert(err))
  }

  const createUserWithFacebook = () => {
    userClass
      .loginUserWithFacebook()
      .then((resp) => {
        addUser(resp.user)
        history.push("/")
      })
      .catch((err) => alert(err))
  }

  return (
    <>
      <div
        onClick={handleChangeStateModal}
        className="flex justify-center items-center flex-col text-center bg-transparent border-white border-1 rounded-xl two h-30"
      >
        <p className="text-white font-bold">{texts[0]}</p>
        <p className="text-white text-xs">
          {texts[1]}
        </p>
      </div>

      <SwipeableDrawer
        open={isOpenModal}
        anchor="bottom"
        onClose={handleChangeStateModal}
        PaperProps={{
          square: false,
          style: {
            borderTopRightRadius: "1rem",
            borderTopLeftRadius: "1rem",
          },
        }}
      >
        <>
          <div className="flex flex-col space-y-3 pb-8 bg-gradient-menium px-4 py-6">
            <div className="flex flex-row justify-between">
              <button className="text-white">{texts[0]}</button>
              <button
                onClick={handleChangeStateModal}
                className="text-isNotEnable-primary-color"
              >
                {texts[9]}
              </button>
            </div>
            <p className="text-white">{texts[2]}</p>
            <ul className="space-y-3 text-white">
              <li className="flex flex-row space-x-2">
                <div>
                  <img
                    height={20}
                    width={20}
                    src={checkIcon}
                    alt="check icon"
                  />
                </div>
                <div>{texts[3]}</div>
              </li>
              <li className="flex flex-row space-x-2">
                <div>
                  <img
                    height={20}
                    width={20}
                    src={checkIcon}
                    alt="check icon"
                  />
                </div>
                <div>{texts[4]}</div>
              </li>
              <li className="flex flex-row space-x-2">
                <div>
                  <img
                    height={20}
                    width={20}
                    src={checkIcon}
                    alt="check icon"
                  />
                </div>
                <div>{texts[5]}</div>
              </li>
              <li className="flex flex-row space-x-2">
                <div>
                  <img
                    height={20}
                    width={20}
                    src={checkIcon}
                    alt="check icon"
                  />
                </div>
                <div>{texts[6]}</div>
              </li>
            </ul>

            <button
              onClick={() => history.push("/register")}
              className="w-full text-center rounded-full py-2 text-white bg-gradient-menium border-2 border-white"
            >
              {texts[0]}
            </button>

            <div className="pt-5 pb-5">
              <div className="w-full h-2 flex items-center justify-center relative">
                <div className="h-0.5 w-full bg-white absolute" />
                <div className="bg-purple-800 rounded-full absolute text-white w-1/3.5 px-4 text-center">
                  {texts[7]}
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between">
              <button
                onClick={createUserWithGoogle}
                className=" text-xs border-2 border-white rounded-full px-4 py-2 text-white items-center flex flex-row space-x-2"
              >
                <img
                  height={20}
                  width={20}
                  src={googleIcon}
                  alt="google icon"
                />
                <span>Google</span>
              </button>
              <button
                onClick={createUserWithFacebook}
                className=" text-xs border-2 border-white rounded-full px-4 py-2 text-white items-center flex flex-row space-x-2"
              >
                <img
                  height={20}
                  width={20}
                  src={facebookIcon}
                  alt="facebook icon"
                />
                <span>FaceBook</span>
              </button>
              <button className=" text-xs border-2 border-white rounded-full px-4 py-2 text-white items-center">
                Apple ID
              </button>
            </div>

            <div className="flex flex-row justify-center space-x-2">
              <span className="text-white">{texts[8]}</span>
              <Link to="/login" className="text-purple-300 underline">
                Log in
              </Link>
            </div>
          </div>
        </>
      </SwipeableDrawer>
    </>
  )
}

export default ConnectToRedux(Register, "User")
