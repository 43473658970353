import { ChevronLeftIcon, MenuIcon } from '@heroicons/react/outline'
import Drawer from 'components/Drawer/Drawer'
import { MenuDrawer } from 'components/global/MenuDrawer/MenuDrawer'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from 'storage/Actions/main'

const Navbar = ({ tableName, branchOfficeId }) => {

    const BranchOffice = useSelector(state => state.main.BranchOffice);
    const language = useSelector(state => state.main.Language);
    const dispatch = useDispatch();

    const spanishText = ["Mesa: ", "Tu cuenta", "Ver la carta", "Volver"];
    const englishText = ["Table: ", "Your bill", "View menu", "Back"];

    const [draweState, setDrawerState] = useState(false);
    const [texts, setTexts] = useState(spanishText);
    const history = useHistory()

    const handleBackPage = () => {
        if (!BranchOffice.isOnlyPayment) {
            history.push("/view_menu");
        } else {
            history.replace("/view_and_pay");
        }
    }

    const handleCloseDrawer = () => {
        setDrawerState(false)
    }

    useEffect(() => {
        if (language == 0) {
            setTexts(spanishText);
        } else if (language == 1) {
            setTexts(englishText);
        }
    }, [language])

    const handleLanguageChange = (event) => {
        const value = event.target.value;
        dispatch(setLanguage(value));
        setLanguage(event.target.value);
        if (value === 0) {
            setTexts(spanishText);
        } else if (value === 1) {
            setTexts(englishText);
        }
    }

    return (
        <>
            <div
                className="pt-4 flex flex-col justify-center items-center pb-0"
            >
                <div
                    className={[
                        "fixed pt-12 pb-3 justify-between bg-white items-start text-center w-full",
                        "grid grid-cols-3 items-center justify-items-start main-view-border-nav-rounded"
                    ].join(" ")}
                >
                    <div
                        className="flex flex-row items-center w-full"
                        onClick={handleBackPage}
                    >
                        <ChevronLeftIcon
                            height={30}
                            width={30}
                            className="text-black"
                        />
                        {!BranchOffice.isOnlyPayment ? texts[2] : texts[3]}
                    </div>
                    <div className={["flex-1 text-center flex flex-col items-center w-full"].join(" ")}>
                        <div className="flex flex-col items-center justify-center">
                            <div className="font-title-bold w-full text-center">
                                <p>{texts[1]}</p>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="bg-aditional-menium-reverse rounded-full w-2 h-2" />
                                <p className="table-name-bill">{texts[0]}{tableName}</p>
                            </div>
                        </div>
                    </div>

                    <div className={
                        [
                            "flex flex-row",
                            "w-full justify-end pr-5"
                        ].join(" ")
                    }>
                        <FormControl className="blackLanguageSelectorForm" sx={{ m: 1, pt: 0, pb: 0, pr: 0, pl: 0, minWidth: 30, border: 1, borderColor: 'primary.contrastText', borderRadius: 10 }} size="small" margin="dense" variant="outlined" hiddenLabel="true">
                            <Select
                                sx={{ pt: 0, pb: 0, pr: 0, pl: 0 }}
                                className="blackLanguageSelector"
                                labelId="black-language-select-label"
                                id="black-language-select"
                                value={language}
                                onChange={handleLanguageChange}
                            >
                                <MenuItem value={0}>es</MenuItem>
                                <MenuItem value={1}>en</MenuItem>
                            </Select>
                        </FormControl>
                        <MenuIcon
                            height={20}
                            width={20}
                            className="text-black"
                            onClick={() => setDrawerState(true)}
                        />
                    </div>
                </div>
            </div>
            <Drawer isOpen={draweState} setIsOpen={handleCloseDrawer}>
                <MenuDrawer />
            </Drawer>
        </>
    )
}

export default Navbar