import React from 'react'
import ItemsList from './ItemsList'

const List = ({ items }) => {
    return (
        <div className="overflow-y-scroll h-full">
            {
                items.map((item, i) => (
                    <ItemsList product={item} key={`product_${i}`} />
                ))
            }
        </div>
    )
}

export default List