import React from 'react';
import NavBar from "components/My-Information/NavBar";
import Items from 'components/legal_policies/Items';

// Pdfs
import TermsAndCondition from 'assets/PDFs/Terminos_Condiciones.pdf';
import LegalWarning from 'assets/PDFs/Aviso_legal.pdf';
import CookiesPolicies from 'assets/PDFs/Politica_Cookies.pdf';
import PrivacyPolicies from 'assets/PDFs/Politica_Privacidad.pdf';

const LegalPolicies = () => {
    return (
        <>
            <NavBar
                save={false}
                title="Políticas legales"
            ></NavBar>
            <div className='flex flex-col items-center px-10'>
                <Items
                    name="Terminos y Condiciones"
                    pdf={TermsAndCondition}
                    pdfName="Terminos y Condiciones.pdf"
                />
                <Items
                    name="Aviso Legal"
                    pdf={LegalWarning}
                    pdfName="Aviso Legal.pdf"
                />
                <Items
                    name="Políticas de Cookies"
                    pdf={CookiesPolicies}
                    pdfName="Políticas de Cookies.pdf"
                />
                <Items
                    name="Políticas de Privacidad"
                    pdf={PrivacyPolicies}
                    pdfName="Políticas de Privacidad.pdf"
                />
            </div>
        </>
    );
};

export default LegalPolicies;
