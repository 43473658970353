import React, { useEffect, useState } from "react"
// import { container } from "Global"
import meniumLogo from "assets/images/logo-menium-white.png"
import { XIcon, CheckIcon } from "@heroicons/react/solid"
import { useHistory } from "react-router"
import Footer from "./Sections/Footer"
import CloseOrder from "./Components/CloseOrder"
import TakeAway from "./Components/TakeAway"
import OpenOrder from "./Components/OpenOrder"
import useGetTableQr from 'hooks/useGetTableQr'
import ConnectToRedux from "components/global/ConnectToRedux"
import Cookies from "js-cookie"
import { numberWithCommas } from "utils/Utils"
import { OnlyPaymentService } from "Service/OnlyPayment"
import useGetTableById from "hooks/useGetTableById"
import speed from "assets/icons/speed.png"
import food from "assets/icons/food.png"
import price from "assets/icons/price.png"
import service from "assets/icons/service.png"
import StarIcon from "@mui/icons-material/StarBorderRounded"
import StarIconFilled from "@mui/icons-material/StarRounded"
import { autocompleteClasses, Rating } from "@mui/material"
import { fontSize } from "@mui/system"
import { useSelector } from 'react-redux'

const PaymentMade = ({ }) => {
  const history = useHistory()
  const [branchOffice, setBranchOffice] = useState(JSON.parse(localStorage.getItem("branch_office")));
  const [paymentAmount, setPaymentAmount] = useState(Cookies.get("payment_made"));
  const [paymentRef, setPaymentRef] = useState(Cookies.get("payment_ref"));
  const [tableCurrent, setTableCurrent] = useState("0.00");
  const [starRating, setStarRating] = useState(0);
  const [isReviewed, setIsReviewed] = useState(false);
  const { isLoading: loading, data: data, error: error } = useGetTableById(localStorage.getItem('tableId'));

  const spanishText = ["¡Muchas Gracias!", "¡Pago en la mesa: ", " completado!", "Tú has pagado: ", "Para cerrar la cuenta falta pagar: ", " ¿Cómo valorarías tu experiencia?", " ¿Algo en lo que podamos mejorar?", "Rapidéz", "Comida", "Servicio", "Precio", "¡Gracias por tu valoración!"];
  const englishText = ["Thank you!", "Payment at table: ", " completed!", "You paid: ", "Amount pending to close the bill: ", "How would you rate your experience?", "Is there anything we can improve on?", "Speed", "Food", "Service", "Price", "Thank you for your rating!"];
  const [texts, setTexts] = useState(spanishText);

  const language = useSelector(state => state.main.Language);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  useEffect(() => {
    let isSubscribed = true;

    const setReview = async () => {
      if (starRating != null && starRating != 0) {
        // Create payment feedback object
        var feedbackData = {
          paymentReference: paymentRef,
          rating: starRating
        };

        var feedbackIsSet = await OnlyPaymentService.setPaymentFeedback(feedbackData);

        if (starRating === 5) {
          window.location.replace(branchOffice.reviewUrl);
        }
      } else {
        console.log("Star Rating is null!");
      }
    }

    setReview();
    return () => isSubscribed = false;
  }, [starRating])

  const handleSendSpeedImprovement = async () => {
    // Setup feedback data for speed improvement
    var feedbackData = {
      paymentReference: paymentRef,
      improvementCategory: 0
    };

    var feedbackSet = await OnlyPaymentService.setPaymentFeedback(feedbackData);

    setIsReviewed(true);
  }

  const handleSendPriceImprovement = async () => {
    // Setup feedback data for speed improvement
    var feedbackData = {
      paymentReference: paymentRef,
      improvementCategory: 1
    };

    var feedbackSet = await OnlyPaymentService.setPaymentFeedback(feedbackData);

    setIsReviewed(true);
  }

  const handleSendFoodImprovement = async () => {
    // Setup feedback data for speed improvement
    var feedbackData = {
      paymentReference: paymentRef,
      improvementCategory: 2
    };

    var feedbackSet = await OnlyPaymentService.setPaymentFeedback(feedbackData);

    setIsReviewed(true);
  }

  const handleSendServiceImprovement = async () => {
    // Setup feedback data for speed improvement
    var feedbackData = {
      paymentReference: paymentRef,
      improvementCategory: 3
    };

    var feedbackSet = await OnlyPaymentService.setPaymentFeedback(feedbackData);

    setIsReviewed(true);
  }

  const handleClickImprovement = () => {
    setIsReviewed(true);
  }

  return (
    <div className="flex w-full h-screen flex-col justify-stretch-mode" style={{ height: window.innerHeight, width: window.innerWidth, backgroundColor: '#24146a', backgroundImage: `url(${branchOffice.backgroundImageNoLogoUrl})`, backgroundPosition: 'center', backgroundSize: 'auto 100%' }}>
      <div className="absolute right-3 top-3">
        <XIcon
          onClick={() => {
            // let rest = payment.total + payment.tip - payment.paidOut

            // if (payment.mode === "Open Order" && rest > 0) {
            //   history.push("/account")
            // } else {
            //   history.push("/")
            // }
            history.push("/view_and_pay");
          }}
          height={30}
          width={30}
          className="text-white"
        />
      </div>

      <div className="flex flex-col mt-10 justify-content-between items-center">
        <div className="meniumtoplogo"><img src={meniumLogo}></img></div>
        <div className="flex flex-row justify-left items-start">
          <div className="mt-10 mr-5 w-15 h-15 bg bg-aditional-menium-reverse rounded-full">
            <CheckIcon />
          </div>
          <div className="flex flex-col">
            <h3 className="font-bold text-white mt-10">{texts[0]}</h3>

            <p className="text-white text-base text-center mt-2">
              {texts[1]} {branchOffice?.table?.tableName} {texts[2]}
            </p>
          </div>

        </div>

        <div className="flex flex-col width-text-container mt-5 p-4">
          <p className="text-white text-center ml-5">{texts[3]} <span className="green-text">{paymentAmount ? numberWithCommas(paymentAmount) : numberWithCommas("0.00")}€</span></p>
          <p className="text-white text-center mt-2 ml-5">{texts[4]} <span className="red-text">{data?.remainingPrice && data.remainingPrice > 0.00 ? numberWithCommas(data.remainingPrice) : numberWithCommas("0.00")}€</span></p>
        </div>

      </div>

      {isReviewed === false &&
        <div className="flex flex-col justify-end items-center w-full">
          <div className="text-white text-center mt-5">
            {texts[5]}
          </div>
          <Rating
            name="post-payment-rating"
            value={starRating}
            onChange={(event, newValue) => { setStarRating(newValue); }}
            emptyIcon={<StarIcon style={{ opacity: 1.0, color: "#FFFFFF" }} fontSize="large" />}
            icon={<StarIconFilled style={{ opacity: 1.0, color: "#faaf00" }} fontSize="large" />}
            size="large"
            fontSize="large"
          />
          <div className="text-white text-center mt-5">
            {texts[6]}
          </div>
          <div className="flex flex-row mt-5 justify-between items-center w-full pl-5 pr-5">
            <div className="flex flex-col w-80 justify-center items-center" onClick={handleSendSpeedImprovement}>
              <img src={speed} style={{ width: 50 + 'px', height: 'auto' }}></img>
              <div className="text-white">{texts[7]}</div>
            </div>
            <div className="flex flex-col w-80 justify-center items-center" onClick={handleSendFoodImprovement}>
              <img src={food} style={{ width: 50 + 'px', height: 'auto' }}></img>
              <div className="text-white">{texts[8]}</div>
            </div>
            <div className="flex flex-col w-80 justify-center items-center" onClick={handleSendServiceImprovement}>
              <img src={service} style={{ width: 50 + 'px', height: 'auto' }}></img>
              <div className="text-white">{texts[9]}</div>
            </div>
            <div className="flex flex-col w-80 justify-center items-center" onClick={handleSendPriceImprovement}>
              <img src={price} style={{ width: 50 + 'px', height: 'auto' }}></img>
              <div className="text-white">{texts[10]}</div>
            </div>
          </div>

        </div>
      }
      {isReviewed === true &&
        <div className="flex flex-col justify-end items-center w-full">
          <div className="green-text text-center">
            {texts[11]}
          </div>

        </div>
      }
      <Footer />

    </div>
  )
}

export default ConnectToRedux(PaymentMade, "App")
