import { useEffect, useState } from "react";
import { collection, query } from "@firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useGetCollection = (rute, ...condition) => {

    const refCollection = collection(useFirestore(), ...rute);

    const [collectionResponse, setCollectionResponse] = useState({
        status: 'loading',
        data: [],
        error: null
    })

    const { status, data, error } = useFirestoreCollectionData(query(refCollection, ...condition));

    useEffect(() => {

        if (status === "success") {

            setCollectionResponse({
                data,
                status,
                error
            })
        }
        else if (status === "error") {
        }

    }, [status, data, error])

    return [collectionResponse.status, collectionResponse.data, collectionResponse.error]

}