import { container } from 'Global'
import React from 'react'
import backIcon from 'assets/icons/back.svg'
import { useHistory } from 'react-router'
import { useGetCollection } from 'hooks/useGetCollection'
import ConnectToRedux from 'components/global/ConnectToRedux'
import { where } from '@firebase/firestore'
import { numberWithCommas } from 'utils/Utils'

const Score = ({ User }) => {

    const history = useHistory();
    const [loading, data] = useGetCollection(['orders'], where('uid', '==', User.uid ? User.uid : ''))

    return (
        <div
            style={container}
            className="overflow-y-hidden flex flex-col"
        >
            <nav className="flex flex-row h-20 items-center justify-center border-b-2">
                <div className="ml-10 mr-12 w-full">
                    <img src={backIcon} className="w-4" alt="Icon back" onClick={() => history.goBack()} />
                </div>
                <div className="flex-1 font-bold text-xl absolute">
                    <h2 className="text-2xl">Historial</h2>
                </div>
            </nav>
            <div className="flex flex-col h-full p-7 pt-0 text-center overflow-y-scroll">
                {
                    loading === "success" && (
                        data.map((el, i) => (
                            <div className="flex w-full mt-3 pb-2 border-b-2" key={`score_item_${i}`}>
                                <div className="flex-1 flex flex-col truncate-text-multiline text-center">
                                    <h3 className="font-bold">{el.commerceName}</h3>
                                    <label>
                                        {
                                            el?.ShoppingCart.defaults.map(product => product.name).join(', ')
                                        }
                                        {
                                            el?.ShoppingCart.custom.map(product => product.name).join(', ')
                                        }
                                    </label>
                                </div>
                                <div className="ml-4 mr-4 flex flex-col text-center w-max justify-between">
                                    <label className="font-bold">{numberWithCommas((el.total + el.tip).toFixed(2))}€</label>
                                    <span className="font-light text-isNotEnable-primary-color">
                                        {new Date(el.date).toLocaleDateString("es-ES", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                    </span>
                                </div>

                                {/* <div className="font-bold h-full flex justify-center items-center">
                                    <label className="font-bold text-xl">{">"}</label>
                                </div> */}
                            </div>
                        ))
                    )
                }
            </div>
        </div>
    )
}

export default ConnectToRedux(Score, 'User')
