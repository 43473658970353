import { Tree } from "Class/Tree"
import { useEffect, useMemo, useState } from "react";

const useModifier = (tree, setTree, path, plu) => {

    const [search, setSearch] = useState(null);

    const treeClass = useMemo(() => new Tree(), []);

    const pushNode = (push) => {

        const response = treeClass.AddOrUpdateNode(path, push, tree)

        setTree(response);
    }

    const deleteNode = () => {
        const response = treeClass.DestroyNode(path, tree);

        setTree(response);
    }

    useEffect(() => {

        const searchNode = () => {
            const resultSearch = treeClass.searchNode(path, plu, tree);
            setSearch(resultSearch)
        }

        searchNode()

    }, [tree, path, plu, treeClass])

    return { pushNode, deleteNode, search }

}

export default useModifier
