import config from "./config.json"

const environment = process.env.STAGE_ENVIRONMENT || "development"

export const container = {
  height: window.innerHeight,
  maxHeight: window.innerHeight,
}

const { stripePublicKey, endpoint: base } = config[environment]

export const endpoint = base;
