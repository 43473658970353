import ConnectToRedux from "components/global/ConnectToRedux"
import Modal from "components/global/Modal"
import React, { useEffect, useState } from "react"
// import { Modal } from "@mui/material"
import { getQuantityByProduct, numberWithCommas } from "utils/Utils"
import ModalModifier from "./ModalModifier"
import Xfit from "assets/icons/XFit.svg"

const TestItem = ({
  isLast,
  section,
  ShoppingCart,
  setShoppingCartCustom,
  product,
  onChangeState,
  setModifier,
}) => {
  const [shoppingPositionInRedux, setShoppingPositionInRedux] = useState(null)
  const [itemValue, setItemValue] = useState({
    name: product.name,
    picture: product.picture,
    price: product.price,
    description: product.description,
    plu: product.plu,
    quantity: product.quantity ? product.quantity : 0,
    productType: product.productType,
  })

  const isIPhone = navigator.userAgent.match(/iPhone/i)
  const styleMultiLineTruncate = isIPhone
    ? { WebkitLineClamp: 2 }
    : { WebkitLineClamp: 3 }

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleClickImage = () => setIsOpenModal(true)

  const handleClickAggregate = () => {
    if (product.subItems.length > 0) {
      handleClickImage()
    } else {
      let add = {
        ...itemValue,
        quantity: itemValue.quantity + 1,
        remark: "",
        subItems: [],
      }

      setItemValue(add)
      onChangeState(add, shoppingPositionInRedux, false)
    }
  }

  const handleClickDecrement = () => {
    let arrShoppingCartCustom = [...ShoppingCart.custom]

    const indexCustomPosition = ShoppingCart.custom.findIndex(
      (el) => el.plu === product.plu,
    )

    if (indexCustomPosition >= 0) {
      const quantity = arrShoppingCartCustom[indexCustomPosition].quantity

      if (quantity > 1) {
        arrShoppingCartCustom[indexCustomPosition].quantity = quantity - 1
      } else {
        arrShoppingCartCustom.splice(indexCustomPosition, 1)
      }

      setShoppingCartCustom(arrShoppingCartCustom)
    } else {
      let remove = { ...itemValue, quantity: itemValue.quantity - 1 }

      setItemValue(remove)
      onChangeState(remove, shoppingPositionInRedux)
    }
  }

  useEffect(() => {
    const ShoppingPosition = ShoppingCart.defaults.findIndex(
      (el) => el.plu === product.plu,
    )

    if (ShoppingPosition >= 0) {
      setShoppingPositionInRedux(ShoppingPosition)
      setItemValue((value) => ({
        ...value,
        quantity: ShoppingCart.defaults[ShoppingPosition].quantity,
      }))
    } else {
      setShoppingPositionInRedux(null)
    }
  }, [ShoppingCart.defaults.length, product.plu, product, ShoppingCart])

  useEffect(() => {
    if (isOpenModal) {
      const subItems = product.subItems.map((el) => ({ ...el, subItems: [] }))
      setModifier({ ...product, subItems })
    } else {
      setModifier(null)
    }
  }, [isOpenModal, product, setModifier])

  const totalQuantity = getQuantityByProduct(ShoppingCart, product.plu)

  return (
    <div
      className={["flex separator pb-2 mt-2", isLast && "ObserverSon"].join(
        " ",
      )}
      data-section={section}
    >
      <div
        className={`w-5 mr-3
        } flex flex-col items-center ml-3 justify-between space-y-1`}
      >
        {totalQuantity > 0 && (
          <>
            <label className="h-5 font-bold text-sm text-purple-800">
              x{totalQuantity}
            </label>
            <div
              onClick={handleClickDecrement}
              className="greenAdd bg-aditional-menium "
            >
              <p>-</p>
            </div>
          </>
        )}
      </div>
      <div className="flex-1 text-lg">
        <div className="flex">
          {product.picture ? (
            <img
              src={product.picture}
              alt={product.name}
              className="rounded-sm object-cover"
              style={{ width: "3.8rem", height: "3.8rem" }}
              onClick={handleClickImage}
            />
          ) : (
            <div />
          )}
          <div
            className={[
              "flex flex-col flex-1 truncate-text-multiline w-1/2",
              product.picture && "ml-2",
            ].join(" ")}
            style={styleMultiLineTruncate}
          >
            <h3 className="text-base" onClick={handleClickImage}>
              {product.name}
            </h3>
            <p className="text-sm text-isNotEnable-primary-color box-content">
              {product.description}
            </p>
          </div>
        </div>
      </div>
      <div className="mr-3 flex flex-col items-end justify-between space-y-1 ml-1">
        <label className="h-5 text-sm">
          {numberWithCommas(parseFloat(product.price).toFixed(2))} €
        </label>
        <div
          onClick={handleClickAggregate}
          className="greenAdd bg-aditional-menium "
        >
          <p>+</p>
        </div>
      </div>
      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        closeIcon={
          <div className="glassButton">
            <img src={Xfit} alt="close icon" />
          </div>
        }
      >
        <ModalModifier
          onChangeState={onChangeState}
          onClose={() => setIsOpenModal(false)}
          shoppingPositionInRedux={shoppingPositionInRedux}
          setIsOpenModal={setIsOpenModal}
          product={product}
        />
      </Modal>
    </div>
  )
}

export default ConnectToRedux(TestItem, ["ShoppingCart", "Modifier"])
