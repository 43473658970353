import React from "react"
import cancel from "../../assets/icons/cancel.svg"
import { Drawer as Sidebar } from "@mui/material/"
export default function Drawer({ children, isOpen, setIsOpen }) {
  return (
    <Sidebar
      className="z-10 bg-gray-500 bg-opacity-50 p-0"
      anchor="right"
      open={isOpen}
      onClose={setIsOpen}
      disableScrollLock={true}
    >
      <div className="relative w-full h-full pb-10 overflow-y-hidden">
        <div className="relative flex justify-end w-full">
          <img
            src={cancel}
            className="w-5 h-5 mr-4 mt-4"
            onClick={setIsOpen}
            alt="close icon"
          />
        </div>
        <div className="flex pt-6 flex-col space-y-6 pr-8 overflow-y-scroll h-full">
          {children}
        </div>
      </div>
    </Sidebar>
  )
}
