import React, { useEffect, useState } from 'react'
import { getQuantityAndTotal, numberWithCommas } from 'utils/Utils';
import DetailPaymentOpenOrder from '../Components/DetailPaymentOpenOrder';
import { useGetCollection } from 'hooks/useGetCollection';
import ConnectToRedux from 'components/global/ConnectToRedux';
import Order from 'Service/Order';
import { useFirebaseApp, useFirestore } from 'reactfire';
import BottomDialog from 'components/account/bottomDialog';
import OptionsBottomMenu from 'components/menu/OptionsBottomMenu';
import Modal from 'components/global/Modal';
import Terms from '../Components/Terms';

const OpenOrder = ({ payload, Commerce, BranchOffice, resetShoppingCart, getValueTipInTotal, tip, setStaticTip }) => {

    const [isOpenModalAskFor, setIsOpenModalAskFor] = useState(false);
    const [isOpenBottomDialog, setIsOpenBottomDialog] = useState(false);
    const [order, setOrder] = useState({
        total: 0,
        tip: 0,
        paidOut: 0,
    })
    const [isAwaitOrderPush, setIsAwaitOrderPush] = useState(false);

    const refCollection = [
        "Shops",
        Commerce.NO_ID_FIELD,
        "BranchOffices",
        BranchOffice.NO_ID_FIELD,
        "Tables",
        BranchOffice.table.NO_ID_FIELD,
        "Order"
    ]

    const [loadingOrderBefore, OrderBefore] = useGetCollection(refCollection)

    const orderClass = new Order(useFirebaseApp(), useFirestore());

    const changeStateBottomDialog = () => setIsOpenBottomDialog(!isOpenBottomDialog)

    const changeStateModalAskFor = () => setIsOpenModalAskFor(!isOpenModalAskFor);

    const count = getQuantityAndTotal(payload.ShoppingCart);

    const handleAddOrder = async () => {

        if (!isAwaitOrderPush) {
            setIsAwaitOrderPush(true)
            if (order.NO_ID_FIELD) {
                await orderClass.updateOrder({ before: { ...order }, after: { ...payload } }, [...refCollection, order.NO_ID_FIELD]);
            }
            else {
                await orderClass.newOrder({ after: { ...payload } }, refCollection);
            }

            resetShoppingCart()

            changeStateModalAskFor()
            setIsAwaitOrderPush(false)
        }
    }

    useEffect(() => {
        let payloadAfter = {
            total: 0,
            tip: 0,
            paidOut: 0,
            usePayPerProducts: true,
            commerceName: Commerce.Name,
            configTip: tip
        }

        if (OrderBefore && OrderBefore[0]) {

            const tip = getValueTipInTotal(OrderBefore[0].total)

            payloadAfter = { ...payloadAfter, ...OrderBefore[0], tip }

            if (OrderBefore[0].configTip) {
                setStaticTip(OrderBefore[0].configTip)
            }

        }

        setOrder(payloadAfter)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingOrderBefore, OrderBefore, tip.value.value, Commerce.Name, getValueTipInTotal])

    const isEnablePayment = order.paidOut - (order.total + order.tip) === 0

    return (
        <div className="px-2 my-3">

            <DetailPaymentOpenOrder payload={order} />

            <div className="h-10 w-full flex justify-around mt-2">
                <button
                    className={
                        [
                            "bg-gradient-menium text-white p-2 pl-4 pr-4 rounded-full flex-1 ml-3 mr-3 max-w-sm min-w-max ",
                            payload.total === 0 && "text-isNotEnable-secundary-color"
                        ].join(" ")
                    }
                    disabled={payload.total === 0}
                    onClick={changeStateModalAskFor}
                >
                    Pedir ({count[0]})
                </button>
                <button
                    className={
                        [
                            "bg-gradient-menium text-white p-2 rounded-full flex-1 ml-3 mr-3 max-w-sm",
                            isEnablePayment && "text-isNotEnable-secundary-color"
                        ].join(' ')
                    }
                    disabled={isEnablePayment}
                    onClick={changeStateBottomDialog}
                >
                    Pagar
                </button>
            </div>

            <Terms />

            <BottomDialog
                content={
                    <OptionsBottomMenu payload={order} />
                }
                onHandle={changeStateBottomDialog}
                open={isOpenBottomDialog}
            />

            <Modal
                open={isOpenModalAskFor}
                onClose={changeStateModalAskFor}
            >
                <div className="space-y-6 flex flex-col py-16 px-8">
                    <h2 className="text-purple-800 text-2xl font-extrabold">¡Aviso!</h2>
                    <label>
                        Si continuas dejarás pedido a cocina <span className="text-purple-700 font-extrabold">{count[0]}</span> productos por un importe de <span className="text-purple-800 font-extrabold">{numberWithCommas(count[1])}€</span> que no podrás cancelar sin la aprobación de un camarero
                    </label>
                    <div className="flex flex-row justify-between">
                        <button
                            onClick={changeStateModalAskFor}
                            className="text-white bg-gradient-menium rounded-full px-5 py-2"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleAddOrder}
                            className="text-white bg-gradient-menium rounded-full px-5 py-2"
                            disabled={isAwaitOrderPush}
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ConnectToRedux(OpenOrder, ['Commerce', 'BranchOffice', 'ShoppingCart'])
