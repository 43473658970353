import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "@firebase/auth"
import { setDoc, doc, getDoc, where, query, collection, getDocs } from "@firebase/firestore"
import { getFunctions, httpsCallable } from "@firebase/functions"
import { renderToStaticMarkup } from "react-dom/server"
// import { getStorage } from "@firebase/storage";
import Banner from "components/email/welcome"

export class User {
  constructor(app, firestore) {
    this.initFuncApp = getFunctions(app)
    this.initAuthApp = getAuth(app)
    this.initStoreApp = firestore
  }

  verifyUserWithEmail = async email => {
    const ref = collection(this.initStoreApp, "Users")
    const queryDoc = await query(ref, where("email", "==", email))

    const docResult = await getDocs(queryDoc)

    if (docResult.size >= 1) {
      return true
    }

    return false
  }

  createCustomerAndUser = async ({
    name,
    date_of_birth,
    photoURL,
    email,
    uid,
  }) => {
    const createCustomer = httpsCallable(this.initFuncApp, "createCustomer")

    const customer = await createCustomer({ name, email })

    if (customer) {
      let user = {
        uid,
        name,
        date_of_birth,
        email,
        photoURL,
        stripeCustomerId: customer.data.id,
      }

      const docRef = doc(this.initStoreApp, "Users", uid)

      const result = await setDoc(docRef, user)

      return { result, user }
    }

    return { error: "Error creando el customerId en Stripe" }
  }

  createUser = async ({ names, surnames, date_of_birth, email, password }) => {
    try {

      const isExist = await this.verifyUserWithEmail(email);

      if (!isExist) {
        const userInFirebase = await createUserWithEmailAndPassword(
          this.initAuthApp,
          email,
          password,
        )

        if (userInFirebase) {
          const uid = userInFirebase.user.uid

          const user = await this.createCustomerAndUser({
            name: `${names} ${surnames}`,
            date_of_birth,
            email,
            photoURL: "",
            uid,
          })

          return user
        }

        return false;
      }
      else {
        alert(`El correo ${email} ya a sido registrado en Menium`)
        return false
      }
    } catch (error) {
      alert(`Error al crear el usuario ${names}`)
      return false
    }
  }

  loginUserWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider()

      const googleInfo = await signInWithPopup(this.initAuthApp, provider)

      const {
        user: { displayName: name, email, photoURL, uid },
      } = googleInfo

      const check = await this.checkoutUser(uid)

      if (check.user) {
        return {
          isSendEmail: false,
          user: check.user
        }
      }

      const user = await this.createCustomerAndUser({
        name,
        date_of_birth: "",
        email,
        photoURL,
        uid,
      })

      if (user.error) {
        return {
          error: true
        }
      }

      return { ...user, isSendEmail: true };

    } catch (error) {
      alert("Ah ocurrido un error, vuelve a intentarlo");
      return {
        error: true
      }
    }
  }

  loginUserWithFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider()

      const facebookInfo = await signInWithPopup(this.initAuthApp, provider)

      const {
        user: { displayName: name, email, photoURL, uid },
      } = facebookInfo

      const check = await this.checkoutUser(uid)

      if (check.user) {
        return {
          isSendEmail: false,
          user: check.user
        }
      }

      const user = this.createCustomerAndUser({
        name,
        date_of_birth: "",
        email,
        photoURL,
        uid,
      })

      if (user.error) {
        return {
          error: true
        }
      }

      return { ...user, isSendEmail: true };

    } catch (error) {
      alert("Ah ocurrido un error, vuelve a intentarlo");
      return {
        error: true
      }

    }
  }

  checkoutUser = async (uid) => {
    const ref = doc(this.initStoreApp, "Users", uid)
    const docResult = await getDoc(ref)

    if (docResult.exists()) {
      return { user: docResult.data() }
    }

    return { err: "Usuario no encontrado" }
  }

  getSecret = async (money) => {
    const callSecret = httpsCallable(this.initFuncApp, "checkoutStripe")
    const req = await callSecret({ amount: money })
    return req.data.client_secret
  }

  loginWithEmailAndPassword = async (email, password) => {
    try {
      const {
        user: { uid },
      } = await signInWithEmailAndPassword(this.initAuthApp, email, password)

      const response = await this.checkoutUser(uid)

      if (response.user) {
        return {
          verify: true,
          user: response.user
        }
      }

      return {
        verify: false
      }

    } catch (error) {
      alert("Verifique sus credenciales")
      console.error(error)
      return false
    }
  }

  sendEmail = async (email) => {
    try {
      const htmlTemplate = renderToStaticMarkup(<Banner />)
      const callableEmail = httpsCallable(this.initFuncApp, "sendEmail")
      let payload = {
        toEmail: email,
        subject: "Bienvenido a menium app",
        html: htmlTemplate,
        attachments: "",
      }

      await callableEmail(payload)
    } catch (error) {
      console.log(error)
    }
  }

  sendRecovery = async (email) => {
    try {
      let response
      sendPasswordResetEmail(this.initAuthApp, email)
        .then((res) => {
          response = true
        })
        .catch((error) => {
          response = false
        })
      return response
    } catch (error) { }
  }
}
