import React from "react"
import ConnectToRedux from "components/global/ConnectToRedux"
import { numberWithCommas } from "utils/Utils"
import { Tree } from "Class/Tree"

const CustomItems = ({ product, onChange, index }) => {
  const tree = new Tree()

  const handleClickAggregate = () => {
    onChange("custom", index, { ...product, quantity: product.quantity + 1 })
  }

  const handleClickDecrement = () => {
    onChange("custom", index, { ...product, quantity: product.quantity - 1 })
  }

  const total = tree.total(product)

  // const description = product.subItems
  //   ? product.subItems
  //     .reduce((previus, current) => [...previus, current.name], [])
  //     .join(", ")
  //   : "modificado"

  const description = product.subItems.map((el) => el.name).join(", ")

  return (
    <div className="flex separator pb-2 mt-2">
      <div className="ml-5 flex flex-col items-center justify-between space-y-1">
        <label className="h-5 font-bold text-base text-purple-800">
          x{product.quantity}
        </label>
        <label
          onClick={handleClickDecrement}
          className="bg-aditional-menium text-green-700 bg-green-200 rounded-full w-5 h-5 flex items-center justify-center text-lg font-bold"
        >
          -
        </label>
      </div>
      <div className="flex-1 text-lg">
        <div className="flex">
          <div className="flex flex-col ml-2 truncate-text-multiline">
            <h3 className="text-base">{product.name}</h3>
            <p className="text-sm text-isNotEnable-primary-color box-content">{description}</p>
          </div>
        </div>
      </div>
      <div className="mr-7 flex flex-col items-end justify-between space-y-1">
        <label className="h-5 text-sm">
          {numberWithCommas(parseFloat(total).toFixed(2))} €
        </label>
        <label
          onClick={handleClickAggregate}
          className="bg-aditional-menium text-green-700 bg-green-200 rounded-full w-5 h-5 flex items-center justify-center text-lg font-bold"
        >
          +
        </label>
      </div>
    </div>
  )
}

export default ConnectToRedux(CustomItems, "ShoppingCart")
