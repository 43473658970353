import { Tree } from "Class/Tree"

export const getQuantityAndTotal = (ShoppingCart) => {

  const tree = new Tree()

  const paymentDefaults = ShoppingCart.defaults.reduce(
    (previus, current) => {
      return [
        current.quantity + previus[0],
        current.price * current.quantity + previus[1],
      ]
    },
    [0, 0],
  )

  const paymentCustom = ShoppingCart.custom.reduce(
    (previus, current) => {
      const product = tree.total(current);

      return [
        current.quantity + previus[0],
        product + previus[1],
      ]
    },
    [0, 0],
  )
  const payment = [
    paymentDefaults[0] + paymentCustom[0],
    paymentDefaults[1] + paymentCustom[1],
  ]

  return payment
}

export const getQuantityByProduct = (ShoppingCart, PLU) => {

  const paymentDefaults = ShoppingCart.defaults.reduce(
    (previus, current) => {
      if (current.plu === PLU) {
        return current.quantity + previus
      }

      return previus
    },
    0,
  )

  const paymentCustom = ShoppingCart.custom.reduce(
    (previus, current) => {
      if (current.plu === PLU) {
        return current.quantity + previus
      }

      return previus
    },
    0,
  )

  const payment = paymentDefaults + paymentCustom

  return payment
}

export const getTotalInShoppingCart = ShoppingCart => {

  const tree = new Tree();

  const paymentDefaults = ShoppingCart.defaults.reduce(
    (previus, current) => (current.price * current.quantity) + previus,
    0,
  )

  const paymentCustom = ShoppingCart.custom.reduce(
    (previus, current) => {

      const total = tree.total(current);

      return (total) + previus
    },
    0,
  )

  return paymentCustom + paymentDefaults;
}

export const numberWithCommas = (number) => {
  if (number === 0) {
    return "0";
  }
  return parseFloat(number).toFixed(2).toString().replace('.', ",");
}

export const nameWithFirstUpper = (textInput) => {
  if (!textInput) {
    return null;
  }
  return textInput.charAt(0).toUpperCase() + textInput.slice(1).toLowerCase();
}

// Get Hours in Commercer
const staticHours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]

const getHour = (Schedules) => {
  const date = new Date();
  const time = date.toTimeString().split(':').splice(0, 2)

  const hours = Schedules.filter(el => {
    const day = date.getDay()

    if (day === el.dayInt) {

      return true;
    }

    return false;

  })

  const res = hours[0].Hour.split(' - ');

  return { start: res[0], end: res[1], currentTime: { hour: time[0], minutes: time[1] } };
}

const verifyHour = ({ start, end, currentTime: { hour, minutes } }) => {

  let hourCurrent = parseInt(hour);
  let minutesCurrent = parseInt(minutes);

  if (minutesCurrent > 0 && minutesCurrent <= 30 && (25 - minutesCurrent) >= 0) {
    minutesCurrent = 0;
  }
  else if (minutesCurrent > 0 && minutesCurrent <= 30 && (25 - minutesCurrent) < 0) {
    minutesCurrent = 30;
  }
  else if (minutesCurrent > 30 && (25 + minutesCurrent) < 60) {
    minutesCurrent = 30;
  }
  else if (minutesCurrent > 30 && (25 + minutesCurrent) >= 60) {
    hourCurrent++;
    minutesCurrent = 0;
  }

  if (hourCurrent === 24) {
    return null
  }

  const formatHour = hourCurrent > 9 ? hourCurrent : `0${hourCurrent}`
  const formatMinutes = minutesCurrent > 9 ? minutesCurrent : `0${minutesCurrent}`

  const startPositionInArr = staticHours.findIndex(el => el === start);
  const endPositionInArr = staticHours.findIndex(el => el === end);
  const currentPosition = staticHours.findIndex(el => el === `${formatHour}:${formatMinutes}`)

  const diferenceInStart = startPositionInArr - currentPosition;
  const diferenceInEnd = endPositionInArr - currentPosition;

  if (diferenceInEnd > 0) {

    let isImmediately = true;

    if (diferenceInStart >= 1) {
      isImmediately = false
    }

    if (diferenceInStart > 0) {

      return { start: startPositionInArr + 1, end: endPositionInArr + 1, isImmediately }
    }

    return { start: currentPosition + 1, end: endPositionInArr + 1, isImmediately }
  }

  return null

}

export const getIntervalHours = (Schedules) => {
  const hour = getHour(Schedules);

  const identify = verifyHour(hour)

  if (identify !== null) {
    const results = staticHours.slice(identify.start, identify.end)

    if (identify.isImmediately) {
      return ["Lo antes posible (entrega inmediata/10-20min)", ...results]
    }

    return results
  }

  return null

}