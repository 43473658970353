import React, { useCallback, useEffect, useState } from "react"
import Navbar from "components/global/navbar"
import ConnectToRedux from "components/global/ConnectToRedux"
import Tips from "./Components/Tips"
import TakeAway from "./Sections/TakeAway"
import { getTotalInShoppingCart } from "utils/Utils"
import CloseOrder from "./Sections/CloseOrder"
import { useHistory } from "react-router"
import OpenOrder from "./Sections/OpenOrder"
import List from "components/menu/List"
import Modal from "components/global/Modal"
import { container } from "Global"
import Share from "assets/icons/share.png"

const tipsCustom = [
  {
    label: "0%",
    value: { isPercent: true, value: 0 },
  },
  {
    label: "5%",
    value: { isPercent: true, value: 5 },
  },
  {
    label: "10%",
    value: { isPercent: true, value: 10 },
  },
  {
    label: "15%",
    value: { isPercent: true, value: 15 },
  },
]

const MyAcount2 = ({
  ShoppingCart,
  Mode,
  resetShoppingCart,
  BranchOffice,
  Commerce,
  isWithNavbar
}) => {
  const [payload, setPayload] = useState({
    total: 0,
    tip: 0,
    ShoppingCart,
    date: new Date(),
    note: "",
  })

  const [valueTip, setValueTip] = useState(tipsCustom[2])
  const [isStaticTip, setIsStaticTip] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [verifyModal, setVerifyModal] = useState({
    open: false,
    title: "",
    description: "",
    showCloseIcon: true,
  })
  const [note, setNote] = useState("")
  const history = useHistory()

  const handleChangeTip = (el) => {
    if (!isStaticTip) {
      setValueTip(el)
    }
  }

  const CleanShoppingCart = () => {
    resetShoppingCart()
    history.push("/")
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getValueTipInTotal = useCallback(
    (total) => {
      if (valueTip.value.isPercent) {
        const tipValue = valueTip.value.value

        const tipToPayload = parseFloat(((total * tipValue) / 100).toFixed(2))

        return tipToPayload
      } else {
        return parseFloat(valueTip.value.value.toFixed(2))
      }
    },
    [valueTip.value.isPercent, valueTip.value.value],
  )

  const handleChangeNote = (event) => {
    const value = event.target.value;

    if (value.length <= 100) {
      setNote(value)
    }
  }

  const setStaticTip = (tip) => {
    setIsStaticTip(true)
    setValueTip(tip)
  }

  const handleChangeStateModal = () => setOpenModal(!openModal)
  const saveNote = () => {
    setPayload({ ...payload, note })
    handleChangeStateModal()
  }

  const shareCommerce = () => {
    let rute = `verify-mode/${Commerce.NO_ID_FIELD}/${BranchOffice.NO_ID_FIELD}`

    if (Mode !== "isTakeAway") {
      rute += `/${BranchOffice.table.NO_ID_FIELD}/${Mode}`
    }

    navigator.share({
      title: "Menium App",
      text: "Pide, Paga y Vete",
      url: rute,
    })
  }

  useEffect(() => {
    if (!BranchOffice.active) {
      setVerifyModal({
        open: true,
        title: "Sucursal desactivada",
        description:
          "En estos momentos no se encuentra habilitada esta sucursal",
        showCloseIcon: false,
      })
    }
  }, [BranchOffice])

  useEffect(() => {
    const tip = getValueTipInTotal(payload.total)

    setPayload((data) => ({ ...data, tip }))
  }, [valueTip.value.value, payload.total, getValueTipInTotal])

  useEffect(() => {
    const total = getTotalInShoppingCart(ShoppingCart)

    setPayload((pay) => ({
      ...pay,
      tip: getValueTipInTotal(total),
      total: total,
      ShoppingCart,
    }))
  }, [ShoppingCart, getValueTipInTotal])

  return (
    <div className="overflow-hidden h-full flex flex-col" style={container}>
      <Navbar isMycount={true} showBackPage={isWithNavbar} />

      <div className="overflow-hidden flex flex-col flex-1 ">
        <div className="flex-1 overflow-y-auto mt-14 h-2/5 shadow-bottom-menu">
          {!BranchOffice?.table?.isCloseTable ? (
            <>
              {ShoppingCart.defaults.length > 0 ||
                ShoppingCart.custom.length > 0 ||
                Mode === "isOpenOrder" ? (
                <List />
              ) : (
                <div className="w-full h-full flex flex-col justify-center items-center text-center px-16 text-isNotEnable-primary-color text-base ">
                  <p>Todavía no has añadido productos a la cuenta</p>
                  {
                    isWithNavbar && (
                      <p className="mt-6">Vuelve al menú para añadir productos</p>
                    )
                  }
                </div>
              )}
            </>
          ) : (
            <div className="w-full h-full flex justify-center items-center text-center px-14 text-isNotEnable-primary-color text-sm ">
              Ahora la mesa está cerrada pero en cuanto el camarero te la
              prepare, podrás usar Menium para pedir y pagar
            </div>
          )}
        </div>

        <div className="">
          <div className="grid-config justify-between items-center pt-3 px-2">
            <button className="p-1 border-2 rounded-full h-full w-full text-xs flex justify-center items-center">
              Añadir Promoción
            </button>
            <button
              onClick={shareCommerce}
              className="p-1 border-2 rounded-full text-xs w-full h-full flex justify-center items-center"
            >
              <img
                src={Share}
                className="text-black font-bold h-5"
                alt="Compartir"
              />
            </button>
            <button
              onClick={handleChangeStateModal}
              className="p-1 border-2 rounded-full w-full h-full text-xs flex justify-center items-center"
            >
              Nota cocina
            </button>

            <button
              className="text-red-600 text-xs h-full"
              onClick={CleanShoppingCart}
            >
              Eliminar todo
            </button>
          </div>

          <Tips
            tips={tipsCustom}
            value={valueTip.label}
            onGetValue={handleChangeTip}
          />
          {Mode === "isTakeAway" && (
            <TakeAway payload={payload} cleanCart={resetShoppingCart} />
          )}

          {Mode === "isCloseOrder" && (
            <CloseOrder cleanCart={resetShoppingCart} payload={payload} />
          )}

          {Mode === "isOpenOrder" && (
            <OpenOrder
              payload={payload}
              tip={valueTip}
              getValueTipInTotal={getValueTipInTotal}
              setStaticTip={setStaticTip}
            />
          )}
        </div>
      </div>
      <Modal
        open={verifyModal.open}
        onClose={() => { }}
        showCloseIcon={verifyModal.showCloseIcon}
      >
        <div className="bg-gradient-meniumtext-white opacity-25 w-full h-full z-10 " />
        <div className="bg-gradient-menium text-white rounded-lg p-4">
          <div className="items-center">
            <div className="rounded-full flex items-center justify-center ">
              <i className="bx bx-error text-3xl" />
            </div>
            <div className=" md:mt-0 md:ml-6 text-center md:text-left">
              <p className="text-lg text-left">{verifyModal.title}</p>
              <p className="text-sm mt-3 text-left">
                {verifyModal.description}
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleChangeStateModal}
      >
        <div className="space-y-6 flex flex-col py-16 px-8">
          <span className="font-extrabold">Nota a cocina</span>
          <textarea
            onChange={handleChangeNote}
            value={note}
            maxLength={100}
            placeholder="Usa este espacio para mandarle una petición concreta al chef"
          />
          <button
            className="bg-gradient-menium text-white p-2 rounded-full max-w-sm w-full"
            onClick={saveNote}
          >
            Guardar
          </button>
        </div>
      </Modal>
    </div>
  )
}

MyAcount2.defaultProps = {
  isWithNavbar: true
}

export default ConnectToRedux(MyAcount2, [
  "ShoppingCart",
  "Mode",
  "Commerce",
  "BranchOffice",
])
