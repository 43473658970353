import { setModifier, setRefreshValidateModifier } from 'storage/Actions/main'

const mapStateToProps = state => ({
    Modifier: state.main.modifierProduct,
    refreshValidateModifier: state.main.refreshValidateModifier
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        setModifier,
        setRefreshValidateModifier
    }
}

export default sendInfo
