import React, { useEffect, useState } from "react"
import TermsAndCondition from "assets/PDFs/Terminos_Condiciones.pdf"
import PrivacyPolicy from "assets/PDFs/Politica_Privacidad.pdf"
import { useSelector } from 'react-redux'


const Terms = () => {
  const spanishText = ["Al continuar, estas aceptando los", "Términos y Condiciones", "y la", "Política de Privacidad", "de Menium"];
  const englishText = ["By continuing, you're accepting the", "Terms and Conditions", "and", "Privacy Policies", "of Menium"];

  const language = useSelector(state => state.main.Language);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
  }, [language])

  return (
    <div className="flex text-center text-xs mt-2">
      <p>
        {texts[0]}{" "}
        <a
          style={{ color: "#4527A0" }}
          href={TermsAndCondition}
          download="Términos y Condiciones.pdf"
        >
          {" "}
          {texts[1]}{" "}
        </a>{" "}
        {texts[2]}{" "}
        <a
          style={{ color: "#4527A0" }}
          href={PrivacyPolicy}
          download="Política de privacidad.pdf"
        >
          {texts[3]}
        </a>{" "}
        {texts[4]}
      </p>
    </div>
  )
}

export default Terms
