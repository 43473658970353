import ConnectToRedux from "components/global/ConnectToRedux"
import React from "react"
import CustomItems from "./CustomItems"
import DefaultItems from "./DefaultItems"
import InOrder from "./InOrder"

const List = ({
  ShoppingCart,
  setShoppingCartCustom,
  setShoppingCartDefaults,
  Mode,
}) => {
  const handleChangeShoppingCart = (type, i, data) => {
    const cart = { ...ShoppingCart }

    cart[type][i] = data

    if (data.quantity === 0) {
      cart[type].splice(i, 1)
    }

    if (type === "custom") {
      setShoppingCartCustom(cart[type])
    } else {
      setShoppingCartDefaults(cart[type])
    }
  }

  return (
    <div className="overflow-y-scroll h-full">
      {Mode === "isOpenOrder" && (
        <InOrder
          showAlert={
            !(
              ShoppingCart.defaults.length > 0 || ShoppingCart.custom.length > 0
            )
          }
        />
      )}

      {ShoppingCart.defaults.map((product, i) => (
        <DefaultItems
          index={i}
          product={product}
          onChange={handleChangeShoppingCart}
          key={`product_default_${i}`}
        />
      ))}

      {ShoppingCart.custom.map((product, i) => (
        <CustomItems
          index={i}
          product={product}
          onChange={handleChangeShoppingCart}
          key={`product_default_${i}`}
        />
      ))}
    </div>
  )
}

export default ConnectToRedux(List, ["ShoppingCart", "Mode"])
