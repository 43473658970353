import PaymentButtons from "components/global/PaymentButtons"
import React, { useState } from "react"
import { numberWithCommas } from "utils/Utils"
import { InputAdornment, OutlinedInput } from "@mui/material"

const PayByExactAmount = ({ payload }) => {
  const [inputValue, setInputValue] = useState({
    value: 0,
    label: "",
  })

  const handleChange = ({ target: { value } }) => {
    try {
      if (value) {
        const convertValue = parseFloat(value)
        const maxValue = ((payload.total + payload.tip) - payload.paidOut).toFixed(2);

        if (convertValue <= maxValue) {
          setInputValue({ value: convertValue, label: value })
        } else {
          alert("Valor no valido")
        }
      } else {
        setInputValue({ value: 0, label: "" })
      }
    } catch (error) {
      alert("Solo se permiten valores númericos")
    }
  }

  const newPayload = {
    ...payload,
    paidOut: payload.paidOut + inputValue.value,
    modeOpenOrder: "PayByExactAmount",
    usePayPerProducts: false,
    amount: inputValue.value,
  }

  return (
    <div className="flex flex-col space-y-4 pb-8">
      <h4 className="text-xl font-bold color-title">
        Pagar por importe exacto
      </h4>
      <span className="mt-6">Introduce el importe que deseas pagar</span>
      <div className="flex justify-center">
        <OutlinedInput
          inputProps={{ inputMode: "decimal" }}
          endAdornment={<InputAdornment position="end">€</InputAdornment>}
          onChange={handleChange}
          type="number"
          value={inputValue.label}
          className="w-2/5 h-10 text-center"
          color="primary"
        />
      </div>
      <div className="flex flex-row justify-between py-4">
        <div className="flex flex-col justify-center">
          <span className="text-sm text-isNotEnable-primary-color">
            Faltaria por pagar
          </span>
          <div className="font-bold text-sm text-center">
            <label>
              {payload.total +
                payload.tip -
                payload.paidOut -
                inputValue.value ===
                0
                ? 0
                : numberWithCommas(
                  (
                    payload.total +
                    payload.tip -
                    payload.paidOut -
                    inputValue.value
                  ).toFixed(2),
                )}
              {" "}€
            </label>
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <span className="text-sm text-isNotEnable-primary-color">
            Total de la cuenta:
          </span>
          <div className="font-bold text-sm text-center">
            {numberWithCommas(
              (payload.total + payload.tip - payload.paidOut).toFixed(2),
            )}{" "}
            €
          </div>
        </div>
      </div>
      <h4 className="text-lg color-title font-bold">Con qué quieres pagar</h4>
      <PaymentButtons payload={newPayload} amount={inputValue.value} active={inputValue.value > 0} />

      <div className="flex text-center text-xs mt-2 text-isNotEnable-primary-color">
        <p>
          Al continuar, estas aceptando los{" "}
          <strong className="color-title"> Términos y Condiciones </strong> y la{" "}
          <strong className="color-title">Política de Privacidad</strong> de
          Menium
        </p>
      </div>
    </div>
  )
}

export default PayByExactAmount
