import ConnectToRedux from 'components/global/ConnectToRedux'
import useModifier from 'hooks/useModifier'
import React, { useState, useEffect } from 'react'
import { numberWithCommas } from 'utils/Utils'
import SectionModifier from './SectionModifier'

const Icon = ({ className, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={["h-4 w-4", className].join(' ')} fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
)
const ItemModifier = (props) => {

    const { pushNode, deleteNode } = useModifier(props.Modifier, props.setModifier, [...props.nodePath], props.plu);

    const [isActive, setIsActive] = useState(false);
    const [isPermitAdd, setIsPermitAdd] = useState(true);
    const europeMoney = numberWithCommas(props.price); // String(props.price.toFixed(2)).replace('.', ',')

    const onClick = () => {

        if (!isActive) {

            if (props.maximumInGroup <= props.IHaveInTheGroup) {

                // const msg = `En el grupo "${props.groupName}" solo se permite un minimo de ${props.minimumInGroup} y un maximo de ${props.maximumInGroup} items seleccionados`

                // alert(msg)

                return null
            }

            const send = { ...props }

            delete send.nodePath;
            delete send.reference;
            delete send.Modifier;
            delete send.setModifier;
            delete send.isActive;
            delete send.onChange;
            delete send.IHaveInTheGroup;
            delete send.groupName;
            delete send.maximumInGroup;
            delete send.minimumInGroup;
            delete send.refreshValidateModifier;
            delete send.setRefreshValidateModifier

            send.quantity = 1;
            send.remark = "";


            send.subItems = send.subItems.map(el => ({ ...el, subItems: [], remark: '' }))

            pushNode(send);
            props.setRefreshValidateModifier()
        }
        else {
            deleteNode();
            props.setRefreshValidateModifier()
        }

        props.onChange();

        setIsActive(!isActive)

    }

    useEffect(() => {

        setIsPermitAdd(!(props.maximumInGroup <= props.IHaveInTheGroup));

    }, [props.maximumInGroup, props.IHaveInTheGroup])

    return (
        <div>
            <div className={
                [
                    "flex w-full justify-between items-center mb-2 space-x-2 ",
                    (!isPermitAdd && !isActive) && "text-isNotEnable-secundary-color"
                ].join(" ")
            }>
                <label className="flex-1">{props.name}</label>
                <label className="import-color">+{europeMoney}€</label>
                <div
                    className={
                        [
                            "border-2 h-5 w-5",
                            isActive && "checkColorActive"
                        ].join(' ')
                    }
                    onClick={onClick}
                >
                    {
                        isActive && (
                            <Icon className="text-white" />
                        )
                    }
                </div>

            </div>
            <div>
                {
                    (props.subItems.length > 0 && isActive) && (
                        props.subItems.map((el, i) => (
                            <SectionModifier
                                {...el}
                                onChange={props.onChange}
                                nodePath={[...props.nodePath, i]}
                                topbar={false}
                                reference={props.reference}
                                key={`${props.reference}_sectionModifier`}
                            />
                        ))
                    )
                }
            </div>
        </div>
    )
}


ItemModifier.defaultProps = {
    isActive: false
}

export default ConnectToRedux(ItemModifier, 'Modifier')
