import React from "react"
import { Tree } from "Class/Tree"
import { numberWithCommas } from "utils/Utils"

const ProductListItem = ({
  quantity,
  name,
  NO_ID_FIELD,
  price,
  subItems,
  onAddCountItem,
  onReduceCountItem,
  onChange,
  index,
  checked,
}) => {
  const tree = new Tree()

  const total = tree.total({ quantity, price, subItems })

  const handleChangeInput = ({ target: { value } }) => {
    try {
      const parseValue = parseInt(value)

      if (value <= quantity && value > 0) {
        onChange(parseValue, NO_ID_FIELD, index)
      }

      if (value === "") {
        onChange("", NO_ID_FIELD, index)
      }
    } catch (error) {
      if (value === "") {
        onChange("", NO_ID_FIELD, index)
      }
    }
  }

  const handleIncrement = () => {
    if (checked) {
      if (checked.quantity + 1 <= quantity) {
        onChange(checked.quantity + 1, NO_ID_FIELD, index)
      }
    } else {
      onChange(1, NO_ID_FIELD, index)
    }
  }

  const handleDecrement = () => {
    if (checked) {
      if (checked.quantity - 1 > 0) {
        onChange(checked.quantity - 1, NO_ID_FIELD, index)
      } else if (checked.quantity - 1 === 0) {
        onChange("", NO_ID_FIELD, index)
      }
    }
  }

  return (
    <div className="flex flex-row justify-between items-center py-3 ">
      <div className="flex flex-row justify-around items-center space-x-4 w-full text-isNotEnable-primary-color">
        <div className="">
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            type="number"
            className={[
              "input-by-product text-center",
              checked && "bg-aditional-menium",
            ].join(" ")}
            onChange={handleChangeInput}
            value={checked ? checked.quantity : ""}
          />
        </div>
        <span>x{quantity}</span>
        <span className="flex-1">{name}</span>
        <span className="min-w-max">{numberWithCommas(total)} € </span>
      </div>
      <div className="flex flex-row space-x-4 ml-1">
        <div
          onClick={handleDecrement}
          className="greenAdd bg-aditional-menium "
        >
          <p>-</p>
        </div>
        <div
          onClick={handleIncrement}
          className="greenAdd bg-aditional-menium "
        >
          <p>+</p>
        </div>
      </div>
    </div>
  )
}
export default ProductListItem
