import { container } from "Global"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { InfoCommerce } from "components/global/ModalCommerce/InfoCommerce"
import { useGetDoc } from "hooks/useGetDoc"

const ViewCommerce = () => {
  const history = useHistory()
  const { id, idMenu } = useParams()
  const [loadingCommerce, dataCommerce] = useGetDoc(["Shops", id])

  const redirect = () => history.push(`/verify-mode/${id}/${idMenu}`)

  return (
    <div style={container} className="flex flex-col w-screen">
      {loadingCommerce === "success" && (
        <InfoCommerce commerceData={dataCommerce} />
      )}

      <div className="p-6">
        <input
          onClick={redirect}
          type="button"
          className="bg-gradient-menium w-full text-white p-2 rounded-full max-w-sm"
          value="Ir a pedir"
        />
      </div>
    </div>
  )
}

export default ViewCommerce
