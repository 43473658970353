import React from 'react';


export const ItemList = ({ icon, label, onClick, className }) => {
  return (
    <div className="pl-4" onClick={onClick}>
      <div className="flex items-center">
        <img src={icon} className={`pr-1 w-5 ${className}`} alt="Icon Item" />
        <h3 className="text-lg font-medium flex-1">{label}</h3>
      </div>
    </div>
  )
}

ItemList.defaultProps = {
  onClick: () => { },
}