import { Tree } from "Class/Tree"
import React from "react"
import { numberWithCommas } from "utils/Utils"

const Items = ({ product, defaultType }) => {
  const tree = new Tree()

  const total = tree.total(product)

  const description = product.subItems
    ? product.subItems
      .reduce((previus, current) => [...previus, current.name], [])
      .join(", ")
    : "pedido"

  return (
    <div className="flex border-b-2 pb-2 mt-2">
      <div className="ml-5 flex flex-col items-center justify-between space-y-1">
        <label className="h-5 font-bold text-base text-isNotEnable-secundary-color">
          x{product.quantity}
        </label>
      </div>
      <div className="flex-1 text-lg">
        <div className="flex">
          <div className="flex flex-col ml-2">
            <h3 className="text-base font-bold text-isNotEnable-secundary-color">
              {product.name}
            </h3>
            {
              !defaultType && (
                <p className="text-sm text-isNotEnable-secundary-color box-content">
                  {
                    description ? description : "Personalizado"
                  }
                </p>
              )
            }
          </div>
        </div>
      </div>
      <div className="mr-7 flex flex-col items-end justify-between space-y-1">
        <label className="h-5 text-base text-isNotEnable-secundary-color">
          {numberWithCommas(parseFloat(total).toFixed(2))} €
        </label>
      </div>
    </div>
  )
}

export default Items
