import Cookies from "js-cookie"
import * as t from "../types"

const Commerce = Cookies.get("commerce")
const BranchOffice = Cookies.get("branch_office")
const Mode = Cookies.get("mode")
const Language = Cookies.get("language")

const initialState = {
  ShoppingCart: {
    defaults: [],
    custom: []
  },
  User: {
    isLoad: true
  },
  paymentMode: "close",
  Commerce: Commerce ? JSON.parse(Commerce) : null,
  BranchOffice: BranchOffice ? JSON.parse(BranchOffice) : null,
  Mode: Mode ? Mode : null,
  payment: null,
  search: {
    isOpenSearch: false,
    search: "",
  },
  modifierProduct: null,
  refreshValidateModifier: false,
  Language: Language ?? 0
}

const main = (state = initialState, action) => {
  switch (action.type) {
    // SHOPPING CART
    case t.SET_SHOPPING_CART_DEFAULTS:
      return {
        ...state,
        ShoppingCart: {
          ...state.ShoppingCart,
          defaults: action.payload
        },
      }
    case t.ADD_TO_SHOPPING_CART_DEFAULTS:
      return {
        ...state,
        ShoppingCart: {
          ...state.ShoppingCart,
          defaults: [...state.ShoppingCart.defaults, action.payload]
        },
      }
    case t.SET_SHOPPING_CART_CUSTOM:
      return {
        ...state,
        ShoppingCart: {
          ...state.ShoppingCart,
          custom: action.payload
        },
      }
    case t.ADD_TO_SHOPPING_CART_CUSTOM:
      return {
        ...state,
        ShoppingCart: {
          ...state.ShoppingCart,
          custom: [...state.ShoppingCart.custom, action.payload]
        },
      }

    case t.RESET_SHOPPING_CART:
      return {
        ...state,
        ShoppingCart: {
          defaults: [],
          custom: []
        },
      }
    case t.SET_COMMERCE:
      return {
        ...state,
        Commerce: action.payload,
      }
    case t.SET_BRANCH_OFFICE:
      return {
        ...state,
        BranchOffice: action.payload,
      }
    case t.SET_MODE:
      return {
        ...state,
        Mode: action.payload,
      }
    // USER
    case t.SET_USER:
      return {
        ...state,
        User: {
          ...action.payload,
          isLoad: false
        },
      }

    case t.REMOVE_USER:
      return {
        ...state,
        User: {
          isLoad: false
        },
      }
    case t.SET_APP_MODE:
      return {
        ...state,
        paymentMode: action.payload,
      }
    case t.SET_PAYMENT_ORDER: {
      return {
        ...state,
        payment: action.payload,
        // payment: {
        //   subtotal: .subtotal,
        //   rest: action.payload.rest,
        //   selectionPayment: action.payload.selection,
        //   place: action.payload.place,
        //   hour: action.payload.hour,
        //   tip: action.payload.tip,

        // },
      }
    }
    case t.CHANGE_STATE_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          isOpenSearch: !state.search.isOpenSearch,
        },
      }
    }
    case t.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          search: action.payload,
        },
      }
    }
    // Modifier
    case t.SET_MODIFIER: {
      return {
        ...state,
        modifierProduct: action.payload
      }
    }
    case t.CHANGE_REFRESH_VALIDATE_MODIFIER: {
      return {
        ...state,
        refreshValidateModifier: !state.refreshValidateModifier
      }
    }
    case t.SET_LANGUAGE: {
      Cookies.set("language", action.payload);
      return {
        ...state,
        Language: action.payload
      }
    }
    default:
      return state
  }
}

export default main
