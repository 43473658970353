import { Menu } from "firebase/Menu";
import { useEffect, useState } from "react"
import { useFirestore } from "reactfire";

const useGetMenu = (rute, mode) => {
  const initState = {
    loading: true,
    data: {},
    error: null,
  }

  const [state, setState] = useState(initState);
  const fireStore = useFirestore()

  useEffect(() => {


    const getMenu = async () => {
      const menuClass = new Menu(rute, mode, fireStore);

      const menu = await menuClass.convertToFormatJSON();

      setState({
        loading: false,
        data: menu,
        error: null
      })
    }

    getMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rute, mode])

  return [state.loading, state.data, state.error]
}

export default useGetMenu
