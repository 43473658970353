import { getFirestore } from "@firebase/firestore"
import { FirestoreProvider, useFirebaseApp } from "reactfire"
import Router from "router/routes"

function App() {
  //init firestore instance
  const firestoreInstance = getFirestore(useFirebaseApp())

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <Router />
    </FirestoreProvider>
  )
}

export default App
