import React, { useState } from "react"
import Rating from "@mui/material/Rating"
import StarIcon from "@mui/icons-material/Star"
import RatingClass from "firebase/rate"
import ConnectToRedux from "components/global/ConnectToRedux"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"

const Rate = ({ Commerce }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [raiting, setRaiting] = useState({
    valueShop: 5,
    valueMenium: 5,
    commentShop: "",
    commentMenium: "",
  })
  const rateClass = new RatingClass()

  const handleChangeStateModal = () => setIsOpenModal(!isOpenModal)

  const handleChangeValue = ({ target: { value, name } }) =>
    setRaiting({ ...raiting, [name]: value })

  const senRate = async () => {
    rateClass.saveRate(
      raiting.valueShop,
      raiting.valueMenium,
      {
        restaurant: raiting.commentShop,
        app: raiting.commentMenium,
      },
      "Shops",
      Commerce.NO_ID_FIELD,
    )
    handleChangeStateModal()
  }

  return (
    <>
      <div
        onClick={handleChangeStateModal}
        className="flex justify-center items-center flex-col text-center bg-purple-900 border-white border-1 rounded-xl"
      >
        <p className="text-white font-bold">Valorar visita</p>
      </div>
      <SwipeableDrawer
        open={isOpenModal}
        onClose={handleChangeStateModal}
        anchor="bottom"
        PaperProps={{
          square: false,
          style: {
            borderTopRightRadius: "1rem",
            borderTopLeftRadius: "1rem",
          },
        }}
      >
        <>
          <div className="flex flex-col space-y-5 pb-8 bg-gradient-menium px-4 py-6">
            <div className="flex flex-row justify-between">
              <button className="text-white">Valorar la experiencia</button>
              <button
                onClick={handleChangeStateModal}
                className="text-isNotEnable-primary-color"
              >
                Cancelar
              </button>
            </div>

            <div className="flex flex-col items-center">
              <label className="text-white">
                Valora la experiencia en el local
              </label>
              <Rating
                name="valueShop"
                value={parseInt(raiting.valueShop)}
                precision={0.5}
                onChange={handleChangeValue}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </div>

            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <label className="text-purple-300">Déjanos tu comentario</label>
              <input
                className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                type="text"
                name="commentShop"
                value={raiting.commentShop}
                onChange={handleChangeValue}
                placeholder="Mensaje..."
              />
            </div>

            <div className="flex flex-col items-center">
              <label className="text-white">
                Valora la experiencia con Menium
              </label>
              <Rating
                name="valueMenium"
                value={parseInt(raiting.valueMenium)}
                precision={0.5}
                onChange={handleChangeValue}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </div>

            <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
              <label className="text-purple-300">Déjanos tu comentario</label>
              <input
                className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                type="text"
                name="commentMenium"
                value={raiting.commentMenium}
                onChange={handleChangeValue}
                placeholder="Mensaje..."
              />
            </div>

            <button
              onClick={senRate}
              className="rounded-lg bg-gradient-menium border-2 border-white py-3 text-white"
            >
              Enviar valoración
            </button>
          </div>
        </>
      </SwipeableDrawer>
      {/* <BottomDialog
        content={
          <>
            <div className="flex flex-col space-y-5 pb-8">
              <div className="flex flex-row justify-between">
                <button className="text-white">Valorar la experiencia</button>
                <button
                  onClick={handleChangeStateModal}
                  className="text-isNotEnable-primary-color"
                >
                  Cancelar
                </button>
              </div>

              <div className="flex flex-col items-center">
                <label className="text-white">
                  Valora la experiencia en el local
                </label>
                <Rating
                  name="valueShop"
                  value={parseInt(raiting.valueShop)}
                  precision={0.5}
                  onChange={handleChangeValue}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </div>

              <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
                <label className="text-purple-300">Déjanos tu comentario</label>
                <input
                  className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                  type="text"
                  name="commentShop"
                  value={raiting.commentShop}
                  onChange={handleChangeValue}
                  placeholder="Mensaje..."
                />
              </div>

              <div className="flex flex-col items-center">
                <label className="text-white">
                  Valora la experiencia con Menium
                </label>
                <Rating
                  name="valueMenium"
                  value={parseInt(raiting.valueMenium)}
                  precision={0.5}
                  onChange={handleChangeValue}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </div>

              <div className="border border-gray-200 rounded-md flex flex-col px-2 py-1">
                <label className="text-purple-300">Déjanos tu comentario</label>
                <input
                  className="border-0 bg-transparent text-white focus:border-0 focus:outline-none"
                  type="text"
                  name="commentMenium"
                  value={raiting.commentMenium}
                  onChange={handleChangeValue}
                  placeholder="Mensaje..."
                />
              </div>

              <button
                onClick={senRate}
                className="rounded-lg bg-gradient-menium border-2 border-white py-3 text-white"
              >
                Enviar valoración
              </button>
            </div>
          </>
        }
        open={isOpenModal}
        onHandle={handleChangeStateModal}
        backgroundColor="bg-gradient-menium"
        showClose={false}
      /> */}
    </>
  )
}

export default ConnectToRedux(Rate, "Commerce")
