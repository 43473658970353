import { setBranchOffice } from 'storage/Actions/main'

const mapStateToProps = state => ({
    BranchOffice: state.main.BranchOffice
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        setBranchOffice
    }
}

export default sendInfo
