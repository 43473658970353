import { useEffect, useState } from "react"
import { OnlyPaymentService } from "Service/OnlyPayment"
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie"

const useGetMerchantDetails = (branchOfficeId) => {
    const [state, setState] = useState({ isLoading: true, data: null, error: null });

    useEffect(() => {
        let isSubscribed = true;
        const getData = async () => {
            try {
                if (branchOfficeId) {
                    const resp = await OnlyPaymentService.getMerchantDetailsById(branchOfficeId);
                    setState({ isLoading: false, data: resp, error: null });
                }
                else {
                    setState({ isLoading: false, data: null, error: "No branchOfficeId found." });
                }
            }
            catch (error) {
                setState({ isLoading: false, data: null, error: error });
            }
        }
        getData();
        return () => isSubscribed = false;
    }, [branchOfficeId])

    return state;
}

export default useGetMerchantDetails