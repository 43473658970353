import ConnectToRedux from "components/global/ConnectToRedux";
import { Redirect, Route } from "react-router-dom";

const ProtectedRouter = ({ component: Component, verifyCrendentials, Commerce, Mode, BranchOffice, showInOnlyPayment, showInAllPayment, ...props }) => {

    const Android = navigator.userAgent.match(/Android/i);
    const webOS = navigator.userAgent.match(/webOS/i);
    const iPhone = navigator.userAgent.match(/iPhone/i);
    const iPad = navigator.userAgent.match(/iPad/i);
    const iPod = navigator.userAgent.match(/iPod/i);
    const BlackBerry = navigator.userAgent.match(/BlackBerry/i);
    const WindowsPhone = navigator.userAgent.match(/Windows Phone/i);

    if (Android || webOS || iPhone || iPad || iPod || BlackBerry || WindowsPhone) {

        if (verifyCrendentials) {
            if (Commerce && Mode) {
                return <Route {...props} component={Component} />
            }
            else {
                return <Redirect to="/qr-lector" />
            }
        }
        else {
            return <Route {...props} component={Component} />
        }
    }
    else {
        return <Redirect to="/no-phone" />
    }

}

ProtectedRouter.defaultProps = {
    verifyCrendentials: false
}

export default ConnectToRedux(ProtectedRouter, ["ShoppingCart", "Commerce", "Mode", "BranchOffice"])
