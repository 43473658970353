import React, { useEffect, useState } from "react"
import jsQR from "jsqr"

export const QRScan = ({ onFind, open }) => {
  const [lector, setLector] = useState({
    notEnabled: true,
    loading: true,
    video: null,
  })

  useEffect(() => {
    const video = document.createElement("video")
    const canvasElement = document.getElementById("qrCanvas")
    const canvas = canvasElement.getContext("2d")

    setLector((e) => ({ video }))
    let isNotRead = false

    const tick = async () => {
      if (lector.notEnabled)
        setLector({
          notEnabled: false,
        })

      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        if (lector.loading)
          setLector({
            loading: false,
          })

        canvasElement.height = video.videoHeight
        canvasElement.width = video.videoWidth
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)

        var imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height,
        )

        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        })

        if (code && code.data) {
          onFind(code.data)
        }
      }

      if (!isNotRead) {
        window.requestAnimationFrame(tick)
      }
    }

    if (navigator?.mediaDevices?.getUserMedia && !isNotRead) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment",
          },
        })
        .then(function (stream) {
          video.srcObject = stream
          video.setAttribute("playsinline", true)
          video.play()
          requestAnimationFrame(tick)
        })
    }

    return () => {
      let i = requestAnimationFrame(() => { })

      for (let index = i; index >= 0; index--) {
        cancelAnimationFrame(index)
      }

      video.srcObject = null
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  let message

  if (lector.notEnabled) {
    message = React.createElement(
      "div",
      null,
      React.createElement(
        "span",
        {
          role: "img",
          "aria-label": "camera",
        },
        "\uD83C\uDFA5",
      ),
      "No se puede acceder a la transmisión de video (asegúrese de tener una cámara web habilitada)",
    )
  } else if (lector.loading) {
    message = React.createElement(
      "div",
      null,
      React.createElement(
        "span",
        {
          role: "img",
          "aria-label": "time",
        },
        "\u231B",
      ),
      " Cargando video...",
    )
  }

  return React.createElement(
    "div",
    null,
    message,
    React.createElement("canvas", {
      id: "qrCanvas",
      className: "w-full h-full",
    }),
  )
}
