import {
    resetShoppingCart,
    addToShoppingCartCustom,
    addToShoppingCartDefaults,
    setShoppingCartCustom,
    setShoppingCartDefaults
} from 'storage/Actions/main'

const mapStateToProps = state => ({
    ShoppingCart: state.main.ShoppingCart
})

const sendInfo = {
    mapStateToProps,
    mapDispatchToProps: {
        addToShoppingCartDefaults,
        addToShoppingCartCustom,
        resetShoppingCart,
        setShoppingCartDefaults,
        setShoppingCartCustom
    }
}

export default sendInfo