import { FormControl, InputAdornment, OutlinedInput } from "@mui/material"
import { numberWithCommas } from "utils/Utils"
import Modal from "components/global/Modal"
import React, { useState } from "react"
import { useEffect } from "react"
import CurrencyInput from "react-currency-input-field"
import { useSelector } from 'react-redux'

const Tips = ({ value, paymentAmount, onGetValue, isEnable }) => {

  const spanishText = ["OK", "Bueno", "Excelente", "Otro", "Elige una propina", "Porcentaje", "Cantidad", "Guardar"];
  const englishText = ["OK", "Good", "Excellent", "Other", "Select a tip", "Percentage", "Amount", "Save"];

  const language = useSelector(state => state.main.Language);

  const [texts, setTexts] = useState(spanishText);

  useEffect(() => {
    if (language == 0) {
      setTexts(spanishText);
    } else if (language == 1) {
      setTexts(englishText);
    }
    var newTips = [
      {
        label: "OK",
        amount: 0.00
      },
      {
        label: "Bueno",
        amount: 0.00
      },
      {
        label: "Excelente",
        amount: 0.00
      }];
    newTips.forEach((t) => {
      t.amount = calculateTip(t.label);
    });
    setCalculatedTips(newTips);
  }, [language])

  useEffect(() => {
    onGetValue(zeroTip);
    var calc = calculatedTips;
    calc.forEach((t) => {
      t.amount = calculateTip(t.label);
    });
    setCalculatedTips(calc);
  }, [])

  const [tipOther, setTipOther] = useState({
    percentageInput: 0.00,
    moneyInput: 0.00,
    label: texts[3],
    amount: 0.00
  });

  const [openModal, setOpenModal] = useState(false)
  const [percentageActive, setPercentageActive] = useState(true);
  const [tipInput, setTipInput] = useState(0.00);
  const [moneyActive, setMoneyActive] = useState(false);
  const [tipsCustom, setTipsCustom] = useState({
    percent: {
      value: "",
      active: true,
    },
    money: {
      value: "",
      active: false,
    },
  })

  const [calculatedTips, setCalculatedTips] = useState([
    {
      label: "OK",
      amount: 0.00
    },
    {
      label: "Bueno",
      amount: 0.00
    },
    {
      label: "Excelente",
      amount: 0.00
    }])

  const calculateTip = (label) => {
    if (paymentAmount <= 10) {
      switch (label) {
        case texts[0]:
          return 1.00;
        case texts[1]:
          return 2.00;
        case texts[2]:
          return 2.50;
      }
    }
    else if (paymentAmount > 10 && paymentAmount <= 15) {
      switch (label) {
        case texts[0]:
          return 1.50;
        case texts[1]:
          return 2.00;
        case texts[2]:
          return 3.00;
      }
    }
    else if (paymentAmount > 15 && paymentAmount <= 25) {
      switch (label) {
        case texts[0]:
          return 2.00;
        case texts[1]:
          return 3.50;
        case texts[2]:
          return 4.00;
      }
    }
    else if (paymentAmount > 25 && paymentAmount <= 35) {
      switch (label) {
        case texts[0]:
          return 2.50;
        case texts[1]:
          return 4.50;
        case texts[2]:
          return 6.50;
      }
    }
    else if (paymentAmount > 35 && paymentAmount <= 50) {
      switch (label) {
        case texts[0]:
          return 4.0;
        case texts[1]:
          return 5.0;
        case texts[2]:
          return 6.0;
      }
    }
    else if (paymentAmount > 50) {
      switch (label) {
        case texts[0]:
          return paymentAmount * 0.05;
        case texts[1]:
          return paymentAmount * 0.10;
        case texts[2]:
          return paymentAmount * 0.15;
      }
    }
  }

  const zeroTip = {
    label: "NONE",
    value: 0.0
  }

  const handleClickItems = (element) => {
    if (isEnable) {
      if (value === element.label) {
        onGetValue(zeroTip);
      } else {
        onGetValue(element)
      }
    }
  }
  const handleClickOther = () => {
    if (isEnable) {
      setOpenModal(true)
    }
  }
  const handleSaveCustomTip = () => {
    var newTip = tipOther;
    if (percentageActive) {
      newTip.moneyInput = 0.00;
      newTip.percentageInput = parseFloat(tipInput);
      newTip.amount = paymentAmount * (parseFloat(tipInput) / 100);
    } else {
      newTip.moneyInput = parseFloat(tipInput);
      newTip.percentageInput = 0.00;
      newTip.amount = parseFloat(tipInput);
    }

    setTipOther(newTip);
    var finalTip = {
      label: texts[3],
      amount: tipOther.amount
    }
    onGetValue(finalTip);
    setOpenModal(false)
  }

  const setMoneyMode = () => {
    setPercentageActive(false);
    setMoneyActive(true);
  }

  const setPercentageMode = () => {
    setPercentageActive(true);
    setMoneyActive(false);
  }

  const handleOtherChangeInput = (value, name) => {
    if (value == undefined) {
      setTipInput(0);
      return;
    }
    var inputFloat = value.replace(',', '.');
    const inputVal = parseFloat(inputFloat);

    setTipInput(inputVal);
  }

  return (
    <div className="flex mt-4 justify-between overflow-x-scroll space-x-3 pb-1">
      {calculatedTips.map((element, i) => (
        <div
          onClick={() => handleClickItems(element)}
          className={[
            "flex justify-center tips-rounded items-center px-3 py-0.5 text-xs text-center tips-line-spacing",
            value === element.label
              ? "bg-purple-800 text-white"
              : "text-black border-2",
          ].join(" ")}
          key={`tips_item_${i}`}
        >
          {texts[i]} <br /> {numberWithCommas(element.amount.toFixed(2))}€
        </div>
      ))}
      <div
        onClick={handleClickOther}
        className={[
          "flex justify-center tips-rounded items-center px-3 py-0.5 text-xs",
          value === texts[3] ? "bg-purple-800 text-white text-center tips-line-spacing" : "text-black border-2",
        ].join(" ")}
      >
        {
          value === texts[3] ? <div>{texts[3]} <br /> {numberWithCommas(tipOther.amount.toFixed(2))}€</div> : <div>{texts[3]}</div>
        }
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="space-y-8 flex flex-col py-8 px-8">
          <span className="text-xl font-bold color-title color-title">{texts[4]}</span>
          <div className="flex justify-center space-x-4">
            <button className={["p-2 rounded-full side-by-side-buttons", percentageActive ? "bg-gradient-menium text-white" : "text-black border-2"].join(" ")} onClick={setPercentageMode}>{texts[5]}</button>
            <button className={["p-2 rounded-full side-by-side-buttons", moneyActive ? "bg-gradient-menium text-white" : "text-black border-2"].join(" ")} onClick={setMoneyMode}>{texts[6]}</button>
          </div>
          <div className="flex justify-center">
            <CurrencyInput
              className={tipInput > 0 ? `tipInputCustomActive` : `tipInputCustom`}
              id="tip-custom-input"
              name="tip-custom-input"
              placeholder={percentageActive ? "0%" : "0,00€"}
              decimalsLimit={2}
              decimalSeparator={","}
              groupSeparator={"."}
              disableGroupSeparators={true}
              disableAbbreviations={true}
              maxLength={6}
              minLength={0}
              suffix={percentageActive ? "%" : "€"}
              onValueChange={(value, name) => handleOtherChangeInput(value, name)}
            />
          </div>

          <button
            className="bg-gradient-menium mt-5 text-white p-2 rounded-full max-w-sm w-full"
            onClick={handleSaveCustomTip}
          >
            {texts[7]}
          </button>
        </div>
      </Modal>
    </div>
  )
}
Tips.defaultProps = {
  tips: [],
  value: {
    label: "NONE",
    value: 0.00
  },
  onGetValue: () => { },
  isEnable: true,
}
export default Tips
